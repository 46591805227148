import { useEffect, useContext } from 'react';
import { saveLog } from '../../utils';

export default function ErrorFallback({ error, resetErrorBoundary }) {
    useEffect(() => {
        saveLog('Fatal error', error.message + '; ' + error.stack, 'error');
    }, [error]);
    window.location.reload();
    return '';
}
