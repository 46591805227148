import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CustomDatePicker } from '../CustomComponents';
import { capitalize, getDateTimeLocale, dateInputFormat, thisYear } from '../../utils';
import i18next from 'i18next';
import axiosInstance from '../../axiosApi';
import { toast } from 'react-toastify';
import useUniverse from '../../hooks/useUniverse';

export default function EditSolarSystem(props) {
    const { getUniverse, universeState } = useUniverse();

    const [solarSystem, setSolarSystem] = useState(null);

    useEffect(() => {
        if (props.editSolarSystem) {
            let localSolarSystem = findSolarSystem();
            setSolarSystem(localSolarSystem);
        }
    }, [props.editSolarSystem]);

    function findSolarSystem() {
        const activeUniverse = universeState?.universe;

        for (let i = 0; i < activeUniverse.galaxies.length; i++) {
            for (let j = 0; j < activeUniverse.galaxies[i].solar_systems.length; j++) {
                if (activeUniverse.galaxies[i].solar_systems[j].id === props.editSolarSystem.id) {
                    return activeUniverse.galaxies[i].solar_systems[j];
                }
            }
        }
        return null;
    }

    function valueField(planet) {
        let value = solarSystem.planets.find((x) => x.id === planet.id).value;

        if (['text', 'number'].includes(planet.data_type))
            return (
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <TextField
                            required={planet.is_dimension}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                let copy = { ...solarSystem };
                                const index = copy.planets.findIndex((x) => x.id === planet.id);
                                let copyPlanet = { ...copy.planets[index] };
                                copyPlanet.value = e.target.value;
                                copy.planets[index] = copyPlanet;
                                setSolarSystem(copy);
                            }}
                            label={capitalize(planet.name)}
                            type={planet.data_type}
                            value={value}
                        />
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'date')
            return (
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <CustomDatePicker
                            propRenderInput={(props) => (
                                <TextField
                                    {...props}
                                    onChange={(e) => {
                                        if (e.target.value.length === 5) {
                                            const monthDay = e.target.value.split('/');
                                            value = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                        } else value = e.target.value;
                                    }}
                                />
                            )}
                            required={planet.is_dimension}
                            propValue={value}
                            dateFieldLabel={planet.name}
                            onChange={(e) => {
                                if (e == 'Invalid Date') {
                                    e = value;
                                }
                                let copy = { ...solarSystem };
                                const index = copy.planets.findIndex((x) => x.id === planet.id);
                                let copyPlanet = { ...copy.planets[index] };
                                e.setMilliseconds(0);
                                e.setSeconds(0);
                                copyPlanet.value = e;
                                copy.planets[index] = copyPlanet;
                                setSolarSystem(copy);
                            }}
                        />
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'datetime')
            return (
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={getDateTimeLocale(i18next.language.toUpperCase())}>
                            <DateTimePicker
                                required={planet.is_dimension}
                                inputFormat={dateInputFormat(true)}
                                renderInput={(props) => <TextField fullWidth={true} {...props} InputLabelProps={{ shrink: true }} />}
                                value={value}
                                label={capitalize(planet.name)}
                                onChange={(e) => {
                                    let copy = { ...solarSystem };
                                    const index = copy.planets.findIndex((x) => x.id === planet.id);
                                    let copyPlanet = { ...copy.planets[index] };
                                    e.setMilliseconds(0);
                                    e.setSeconds(0);
                                    copyPlanet.value = e;
                                    copy.planets[index] = copyPlanet;
                                    setSolarSystem(copy);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'status') {
            return (
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="statusLabel">{i18next.t('status')}</InputLabel>
                            <Select
                                required={planet.is_dimension}
                                value={value ?? ''}
                                onChange={(e) => {
                                    let copy = { ...solarSystem };
                                    const index = copy.planets.findIndex((x) => x.id === planet.id);
                                    let copyPlanet = { ...copy.planets[index] };
                                    copyPlanet.value = e.target.value;
                                    copy.planets[index] = copyPlanet;
                                    setSolarSystem(copy);
                                }}
                                label={i18next.t('status')}
                            >
                                <MenuItem value={'to-do'}>{i18next.t('todo')}</MenuItem>
                                <MenuItem value={'in progress'}>{i18next.t('in_progress')}</MenuItem>
                                <MenuItem value={'done'}>{i18next.t('done')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }
    }

    function connectionField(index) {
        //let key = String.fromCharCode(97 + index);
        let copy = structuredClone(solarSystem);
        const galaxy = universeState?.universe?.galaxies[index];

        let solarID = Object.values(solarSystem.connections[0]).find((x) => x.galaxy_name === galaxy.name)?.id;
        let key = Object.keys(copy.connections[0]).find((key) => copy.connections[0][key].id === solarID);

        let localSolarSystem = galaxy.solar_systems.find((x) => x.id === solarSystem.connections[0][key].id);
        return (
            <Grid container>
                <FormControl fullWidth required>
                    <InputLabel id="connection_a">{capitalize(galaxy.name)}</InputLabel>{' '}
                    <Select
                        label={capitalize(galaxy.name)}
                        onChange={(e) => {
                            // const index = copy.connections.findIndex((x) => x.id === connection.id);
                            // let copyConnection = { ...copy.connections[index] };
                            // copyConnection.a = e.target.value;
                            // copy.connections[index] = copyConnection;
                            //a == 97

                            copy.connections[0][key] = e.target.value;
                            setSolarSystem(copy);
                        }}
                        value={localSolarSystem ?? ''}
                    >
                        {galaxy.solar_systems.map((option) => (
                            <MenuItem value={option} key={option.id}>
                                {option.planets[0].value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        );
    }

    function saveSolarSystem(e) {
        e.preventDefault();
        props.setEditSolarSystem(null);
        axiosInstance
            .post(`/api/modify-solar-system/${universeState?.universe.id}`, {
                solarSystem: solarSystem,
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log(solarSystem);
                    if (solarSystem?.connections?.length) {
                        axiosInstance
                            .post('/api/modify-planet-relatives/' + universeState?.universe.id, {
                                relatives: [
                                    solarSystem.connections[0].a.id,
                                    solarSystem.connections[0].b.id,
                                    solarSystem.connections[0].c.id,
                                ],
                                allocationId: solarSystem.id,
                            })
                            //.then((response) => {
                            //    if (response.status === 200) universeState?.refreshCallback.current = i18next.t('save_successful');
                            //})
                            .catch((error) => {
                                toast.error('error');
                            });
                    } else {
                        //universeState?.refreshCallback.current = capitalize(i18next.t('save_successful'));
                    }
                    getUniverse();
                }
            })
            .catch((error) => {
                toast.error(capitalize(i18next.t('error')));
            });
    }

    if (!props.editSolarSystem) return '';
    if (!solarSystem) return '';

    return (
        <Dialog open={props?.editSolarSystem?.id}>
            <DialogTitle>
                <Grid container item xs={12} md={12} justifyContent="center">
                    <b>Edit {solarSystem?.planets?.[0]?.value ?? 'allocation'}</b>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => saveSolarSystem(e)} style={{ width: '100%' }}>
                    <Grid container item xs={12} md={12} paddingBottom={'20px'}>
                        {solarSystem.planets.map((planet, index) => (
                            <Grid item xs={12} md={12} paddingY={'10px'} key={'planet_' + index}>
                                {valueField(planet, index)}
                            </Grid>
                        ))}
                    </Grid>
                    {/* {solarAllocations && (
                        <Grid
                            container
                            item
                            xs={12}
                            md={12}
                            paddingBottom={'20px'}
                            style={{ maxHeight: '30vh', overflowY: 'auto' }}
                        >
                            <Typography>{capitalize('allocations')}</Typography>
                            {solarAllocations.map((allocation, idx) =>
                                allocation.planets.map((planet, index) => (
                                    <Grid item xs={12} md={12} paddingY={'10px'} key={'planet_' + index}>
                                        {valueField2(planet, idx)}
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )} */}

                    <Grid container item xs={12} md={12} paddingBottom={'20px'}>
                        {Boolean(solarSystem?.connections?.length) && (
                            <Grid container>
                                <Grid container paddingBottom={'20px'} justifyContent={'center'}>
                                    <Typography align={'center'} variant={'h6'}>
                                        {capitalize('connections')}
                                    </Typography>
                                </Grid>

                                <Grid container paddingBottom={'20px'}>
                                    {connectionField(0)}
                                </Grid>
                                <Grid container paddingBottom={'20px'}>
                                    {connectionField(1)}
                                </Grid>
                                <Grid container paddingBottom={'20px'}>
                                    {connectionField(2)}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container justifyContent={'flex-end'} spacing={2} paddingTop={'20px'}>
                        <Grid item>
                            <Button variant={'contained'} color={'primary'} onClick={() => props.setEditSolarSystem(null)}>
                                {capitalize(i18next.t('close'))}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'contained'} color={'success'} type={'submit'}>
                                {capitalize(i18next.t('save_changes'))}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
}
