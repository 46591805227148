import React, { useEffect, useState, useRef } from 'react';

import { Button, Grid, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import axiosInstance from '../../axiosApi';
import CloseIcon from '@mui/icons-material/Close';
import { CustomToastContainer, CustomTooltip } from '../CustomComponents';

import { formatDateTime } from '../../utils';

import useAuthentication from '../../hooks/useAuthentication';

export default function Admin(props) {
    const { logOut } = useAuthentication();

    const navigate = useNavigate();

    const dialogContentRef = useRef('No content');

    const [logs, setLogs] = useState([]);
    const [historyUsers, setHistoryUsers] = useState([]);
    const [descriptionDialogEnabled, setDescriptionDialogEnabled] = useState(false);

    const columns = [
        {
            align: 'left',
            cellClassName: 'unselectable',
            field: 'id',
            headerAlign: 'left',
            headerName: 'ID',
            type: 'number',
            width: 75,
            valueGetter: (params) => params.row.id,
        },
        {
            align: 'center',
            cellClassName: 'unselectable',
            field: 'time',
            headerAlign: 'center',
            headerName: 'Date',
            width: 200,
            valueGetter: (params) => {
                return formatDateTime(params.row.time);
            },
        },
        {
            align: 'center',
            field: 'email',
            headerAlign: 'center',
            headerName: 'User ID',
            valueGetter: (params) => {
                return params.row.user;
            },
            width: 250,
        },
        {
            align: 'center',
            cellClassName: 'unselectable',
            field: 'title',
            headerAlign: 'center',
            headerName: 'Title',
            valueGetter: (params) => {
                return params.row.title;
            },
            width: 250,
        },
        {
            align: 'left',
            cellClassName: 'unselectable',
            field: 'description',
            headerAlign: 'center',
            headerName: 'Description',
            valueGetter: (params) => {
                return params.row.description;
            },
            width: 300,
        },
        {
            align: 'center',
            cellClassName: 'unselectable',
            headerAlign: 'center',
            headerName: 'Log type',
            field: 'log_type',
            valueGetter: (params) => {
                return params.row.log_type;
            },
            width: 100,
        },
    ];

    useEffect(() => {
        getLogs();
    }, []);

    function getLogs() {
        axiosInstance.post('/api/get-logs').then((response) => {
            setLogs(response.data);
        });
    }

    return (
        <React.Fragment>
            <CustomToastContainer />

            <Dialog open={descriptionDialogEnabled}>
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>Log details</Grid>
                        <Grid item>
                            <IconButton edge={'end'} onClick={() => setDescriptionDialogEnabled(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>{dialogContentRef.current}</DialogContent>
            </Dialog>

            <Grid container item xs={12} md={12} paddingY={'5px'}>
                <div style={{ height: '85vh', width: '100%' }}>
                    <DataGrid
                        columns={columns}
                        onCellDoubleClick={(params) => {
                            if (params.field !== 'description') return;
                            dialogContentRef.current = params.value;
                            setDescriptionDialogEnabled(true);
                        }}
                        onSelectionModelChange={(selection) => {}}
                        rows={logs}
                        rowsPerPageOptions={[10, 50, 100]}
                    />
                </div>
            </Grid>

            <Grid container justifyContent={'center'} paddingY={'5px'}>
                <Button onClick={() => navigate('/select-universe')} variant="contained" color="info">
                    Back to templates
                </Button>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Button onClick={logOut}>Logout</Button>
            </Grid>
        </React.Fragment>
    );
}
