import huLocale from 'date-fns/locale/hu';
import enLocale from 'date-fns/locale/en-GB';
import { format } from 'date-fns';
import axiosInstance from './axiosApi';
import * as XLSX from 'xlsx';

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function clog(valueToDisplay) {
    //const displayValue = JSON.parse(JSON.stringify(valueToDisplay));
    //return displayValue;
    return structuredClone(valueToDisplay);
}

export function formatDateTime(date, isDateTime = false) {
    if (!date) return '';

    let myDate = date;
    if (typeof myDate === 'string') {
        myDate = new Date(myDate);
    }

    if (isNaN(myDate.getTime())) return 'Invalid Date';
    if (isDateTime) return format(myDate, 'dd/MM/yyyy HH:mm');
    return format(myDate, 'dd/MM/yyyy');
}

export function getDateTimeLocale(lang) {
    if (lang === undefined || lang === null) return enLocale;
    const localeMap = {
        EN: enLocale,
        HU: huLocale,
    };
    return localeMap[lang];
}

export function dateInputFormat(isDateTime = false) {
    if (isDateTime) return 'dd/MM/yyyy HH:mm';
    return 'dd/MM/yyyy';
}

export function thisYear() {
    return new Date().getFullYear().toString();
}

export function excelExport(header, body, filename = 'allooker_export') {
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_aoa(ws, body, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsb', type: 'array' });
    const finalData = new Blob([excelBuffer]);
    XLSX.writeFile(wb, filename + '.xlsx');
}

export function excelDimensionExport(header, filename) {
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    let wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, filename + '.xlsx');
}

export function saveLog(title, description, log_type) {
    axiosInstance.post('/api/save-log', {
        title: title,
        description: description,
        log_type: log_type,
    });
}
