import React from 'react';
import { useState } from 'react';
import i18next from 'i18next';
import { Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, DialogContent, Divider } from '@mui/material';
import { capitalize } from '../../../utils';

export default function CustomDeleteDialog(props) {
    const [checkBoxState, setCheckBoxState] = useState(false);
    return (
        <Dialog open={props.openState}>
            <DialogTitle>{props.question}</DialogTitle>

            <DialogContent>
                <Grid container>
                    {props.optionalDescription && (
                        <Grid item xs={12} md={12} paddingBottom={'20px'}>
                            {props.optionalDescription}
                        </Grid>
                    )}
                    {props.withCheckbox && (
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                label={capitalize(i18next.t('delete_checkbox_description'))}
                                control={
                                    <Checkbox
                                        checked={checkBoxState}
                                        onChange={() => {
                                            setCheckBoxState(!checkBoxState);
                                        }}
                                    />
                                }
                            ></FormControlLabel>
                        </Grid>
                    )}

                    <Grid item xs={12} md={12}>
                        <Divider />
                    </Grid>

                    <Grid container justifyContent={'flex-end'} spacing={2} paddingTop={'20px'}>
                        <Grid item>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    props.setOpenState(false);
                                    setCheckBoxState(false);
                                }}
                            >
                                {capitalize(i18next.t('no'))}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'contained'}
                                color={'error'}
                                disabled={props.withCheckbox && !checkBoxState}
                                onClick={() => {
                                    props.setOpenState(false);
                                    setCheckBoxState(false);
                                    props.deleteFunction();
                                }}
                            >
                                {capitalize(i18next.t('yes'))}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
