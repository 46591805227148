import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';

let interval = null;
export default function Loading(props) {
    const [dots, setDots] = useState('');
    useEffect(() => {
        function handleDots() {
            if (dots.length === 3) {
                setDots('');
            } else {
                setDots('.'.repeat(dots.length + 1));
            }
        }
        if (props.loading) {
            interval = setInterval(() => {
                handleDots();
            }, 500);
        } else clearInterval(interval);

        return () => {
            clearInterval(interval);
        };
    });

    if (props.loading)
        return (
            <Box sx={{ width: '100%' }} style={{ position: 'absolute', left: 0, top: 0, zIndex: 999 }}>
                <LinearProgress />
                <Grid container justifyContent={'center'}>
                    <Typography variant="body2" color="text.secondary">
                        {props.text + dots}
                    </Typography>
                </Grid>
            </Box>
        );
    return '';
}
