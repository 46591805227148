import { useEffect } from 'react';
import { saveLog } from '../../utils';

import { Grid, Button } from '@mui/material';

export default function ErrorFallback({ error, resetErrorBoundary }) {
    useEffect(() => {
        saveLog('Fatal error', error.message + '; ' + error.stack, 'error');
    }, [error]);

    return (
        <Grid container>
            <Grid item padding={'5px'}>
                <h1>Something went wrong</h1>
                <p>{error.message}</p>
                <Button variant="contained" color="info" onClick={resetErrorBoundary}>
                    Reload
                </Button>
            </Grid>
        </Grid>
    );
}
