import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import {
    Autocomplete,
    Button,
    Dialog,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
    DialogTitle,
    DialogContent,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    DialogActions,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import usePreferences from '../../hooks/usePreferences';
import useTable from '../../hooks/useTable';
import axiosInstance from '../../axiosApi';
import { CustomDatePicker } from '../CustomComponents';
import { universeActions } from '../../context/UniverseContext';
import { getDateTimeLocale, capitalize, dateInputFormat, thisYear } from '../../utils';
import useUniverse from '../../hooks/useUniverse';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const icon = <CheckBoxOutlineBlankIcon fontSize={'small'} />;
const checkedIcon = <CheckBoxIcon fontSize={'small'} />;

export default function CreateSolarSystemPopup(props) {
    const { getUniverse, universeState, universeDispatch } = useUniverse();
    const { preferencesState } = usePreferences();
    const { tableState, getTable } = useTable();
    useEffect(() => {
        if (props.overlay && props.newSolarType !== '') {
            if (props.newSolarType < 3) {
                //regular dimension
                solarSystemForRegularGalaxy();
            } else {
                // 3 or 4 is for allocation, 3 with no default connections, 4 with some default connections (from table cell plus icon)
                solarSystemForAllocation();
            }
        }
    }, [props.newSolarType]);

    const [solarSystem, setSolarSystem] = useState({}); //allocation
    const [solarSystem2, setSolarSystem2] = useState({}); //third dimension
    const [remainingIndex, setRemainingIndex] = useState(); // used for allocation creation from dashboard
    const [newAllocationType, setNewAllocationType] = useState('one_third');
    const [checkedSolarSystems, setCheckedSolarSystems] = useState([]);
    const [selectedSolars, setSelectedSolars] = useState([]);

    function solarSystemForRegularGalaxy() {
        let galaxy_structure = structuredClone(universeState?.universe?.galaxies[props.newSolarType]).structure;

        for (let i = 0; i < galaxy_structure.planets.length; i++) {
            let addedValue =
                galaxy_structure.planets[i].data_type === 'date' || galaxy_structure.planets[i].data_type === 'datetime' ? new Date() : '';
            galaxy_structure.planets[i].value = addedValue;
        }
        galaxy_structure.connections = [];
        setSolarSystem(galaxy_structure);
    }

    function solarSystemForAllocation() {
        // allocation galaxy's index is supposed to be always 3
        let index = 3;
        let galaxy_structure = structuredClone(universeState?.universe?.galaxies[index]).structure;

        for (let i = 0; i < galaxy_structure.planets.length; i++) {
            let addedValue =
                galaxy_structure.planets[i].data_type === 'date' || galaxy_structure.planets[i].data_type === 'datetime' ? new Date() : '';
            galaxy_structure.planets[i].value = addedValue;
        }
        // from SolarSystemCard or dashboard top left
        if (props.newSolarType === 3) {
            if (props.copiedSystem && props.copiedSystem !== '') {
                let connections = props.copiedSystem.connections[0];
                let index0 = universeState?.universe?.galaxies[0].solar_systems.findIndex(
                    (x) => x.id === connections.a.id || x.id === connections.b.id || x.id === connections.c.id
                );
                let index1 = universeState?.universe?.galaxies[1].solar_systems.findIndex(
                    (x) => x.id === connections.a.id || x.id === connections.b.id || x.id === connections.c.id
                );
                let index2 = universeState?.universe?.galaxies[2].solar_systems.findIndex(
                    (x) => x.id === connections.a.id || x.id === connections.b.id || x.id === connections.c.id
                );
                galaxy_structure.connections = [
                    {
                        a: universeState?.universe?.galaxies[0].solar_systems[index0],
                        b: universeState?.universe?.galaxies[1].solar_systems[index1],
                        c: universeState?.universe?.galaxies[2].solar_systems[index2],
                    },
                ];
            } else {
                galaxy_structure.connections = [
                    {
                        a: universeState?.universe?.galaxies[0].solar_systems[0],
                        b: universeState?.universe?.galaxies[1].solar_systems[0],
                        c: universeState?.universe?.galaxies[2].solar_systems[0],
                    },
                ];
            }
        }
        /// from Dashboard
        if (props.newSolarType === 4) {
            // works only if allocation is not on axis
            let xGalaxyIndex = universeState?.universe?.galaxies.findIndex((x) => x.name === preferencesState.xAxis.galaxy);
            let yGalaxyIndex = universeState?.universe?.galaxies.findIndex((x) => x.name === preferencesState.yAxis.galaxy);
            let localRemainingIndex = [0, 1, 2].find((x) => x !== xGalaxyIndex && x !== yGalaxyIndex);
            setRemainingIndex(localRemainingIndex);

            galaxy_structure.connections = [
                {
                    a: tableState.table[0][props.xAxisIndex],
                    b: tableState.table[props.yAxisIndex][0],
                    c: universeState?.universe?.galaxies?.[localRemainingIndex]?.solar_systems?.[0],
                },
            ];
            setSolarSystem2(structuredClone(universeState?.universe?.galaxies[localRemainingIndex].structure));
        }
        setSolarSystem(galaxy_structure);
    }

    function connectionField(connection, index) {
        let key = String.fromCharCode(97 + index);
        let solarSystems = universeState?.universe?.galaxies[index].solar_systems;

        return (
            <Grid container>
                <FormControl fullWidth required>
                    <InputLabel id="connection_a">{capitalize(solarSystems[0].galaxy_name)}</InputLabel>{' '}
                    <Select
                        label={capitalize(solarSystems[0].galaxy_name)}
                        onChange={(e) => {
                            let copy = { ...solarSystem };
                            // const index = copy.connections.findIndex((x) => x.id === connection.id);
                            // let copyConnection = { ...copy.connections[index] };
                            // copyConnection.a = e.target.value;
                            // copy.connections[index] = copyConnection;
                            //a == 97

                            copy.connections[0][key] = e.target.value;
                            setSolarSystem(copy);
                        }}
                        value={solarSystem.connections[0][key] ?? ''}
                    >
                        {solarSystems.map((option) => (
                            <MenuItem value={option} key={option.id}>
                                {option.planets[0].value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        );
    }

    function handleToggleChange(event, value) {
        setNewAllocationType(value);
    }

    function valueField(planet, state, setState) {
        if (!planet) return <span>{'-'}</span>;
        let value = planet.value;
        if (['text', 'number'].includes(planet.data_type))
            return (
                <TextField
                    required={planet.is_dimension}
                    onChange={(e) => {
                        let copy = { ...state };
                        const index = copy.planets.findIndex((x) => x.id === planet.id);
                        let copyPlanet = { ...copy.planets[index] };
                        copyPlanet.value = e.target.value;
                        copy.planets[index] = copyPlanet;
                        setState(copy);
                    }}
                    label={capitalize(planet.name)}
                    type={planet.data_type}
                    value={value}
                />
            );
        else if (planet.data_type === 'date')
            return (
                <CustomDatePicker
                    propRenderInput={(props) => (
                        <TextField
                            required={planet.is_dimension}
                            {...props}
                            onChange={(e) => {
                                if (e.target.value.length === 5) {
                                    const monthDay = e.target.value.split('/');
                                    value = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                } else {
                                    value = e.target.value;
                                }
                            }}
                        />
                    )}
                    propValue={value}
                    dateFieldLabel={capitalize(planet.name)}
                    onChange={(e) => {
                        if (e == 'Invalid Date') {
                            e = value;
                        }
                        let copy = { ...state };
                        const index = copy.planets.findIndex((x) => x.id === planet.id);
                        let copyPlanet = { ...copy.planets[index] };
                        copyPlanet.value = e;
                        copy.planets[index] = copyPlanet;
                        setState(copy);
                    }}
                />
            );
        else if (planet.data_type === 'datetime')
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={getDateTimeLocale(i18next.language.toUpperCase())}>
                    <DateTimePicker
                        inputFormat={dateInputFormat(true)}
                        renderInput={(props) => <TextField required={planet.is_dimension} {...props} />}
                        value={value}
                        label={capitalize(planet.name)}
                        onChange={(e) => {
                            let copy = { ...state };
                            const index = copy.planets.findIndex((x) => x.id === planet.id);
                            let copyPlanet = { ...copy.planets[index] };
                            copyPlanet.value = e;
                            copy.planets[index] = copyPlanet;
                            setState(copy);
                        }}
                    />
                </LocalizationProvider>
            );
        else if (planet.data_type === 'status')
            return (
                <FormControl fullWidth required>
                    <InputLabel id="statusLabel">{i18next.t('status')}</InputLabel>
                    <Select
                        id="statusLabel"
                        fullWidth
                        value={value ?? ''}
                        onChange={(e) => {
                            let copy = { ...state };
                            const index = copy.planets.findIndex((x) => x.id === planet.id);
                            let copyPlanet = { ...copy.planets[index] };
                            copyPlanet.value = e.target.value;
                            copy.planets[index] = copyPlanet;
                            setState(copy);
                        }}
                        label={i18next.t('status')}
                    >
                        <MenuItem value={'to-do'}>{i18next.t('todo')}</MenuItem>
                        <MenuItem value={'in progress'}>{i18next.t('in_progress')}</MenuItem>
                        <MenuItem value={'done'}>{i18next.t('done')}</MenuItem>
                    </Select>
                </FormControl>
            );
    }

    function title() {
        // from dashboard and from last solar system card - both allocation
        let index = props.newSolarType === 4 ? 3 : props.newSolarType;
        if (index === undefined || index === '') return;
        let galaxy_name = universeState?.universe?.galaxies[index].name;

        return (
            <React.Fragment>
                {capitalize(i18next.t('add_new'))} {galaxy_name}
            </React.Fragment>
        );
    }

    function thirdDimensionField() {
        let solarSystems = universeState?.universe?.galaxies[remainingIndex].solar_systems;
        <Autocomplete
            autoHighlight
            options={solarSystems}
            multiple
            fullWidth
            getOptionLabel={(option) => option.planets[0].value}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.planets[0].value}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={capitalize(universeState?.universe?.galaxies[remainingIndex]?.name ?? '')}
                    inputProps={{ ...params.inputProps }}
                />
            )}
            onChange={(e, newValue) => {
                setSelectedSolars(newValue);
            }}
            // isOptionEqualToValue={(option, value) => {
            //     const reduced = getSimple(value);
            //     return JSON.stringify(option) === JSON.stringify(reduced);
            // }}
            size={'small'}
            value={selectedSolars}
        />;
        return (
            <Grid container>
                <Grid container justifyContent={'center'} paddingY={'10px'}>
                    <ToggleButtonGroup
                        color="primary"
                        value={newAllocationType}
                        exclusive
                        onChange={handleToggleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value="new_third">
                            {i18next.t('new') + ' ' + universeState?.universe?.galaxies[remainingIndex].name}
                        </ToggleButton>
                        <ToggleButton value="one_third">
                            {i18next.t('one') + ' ' + universeState?.universe?.galaxies[remainingIndex].name}
                        </ToggleButton>
                        <ToggleButton value="many_third">
                            {i18next.t('multiple') + ' ' + universeState?.universe?.galaxies[remainingIndex].name}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                {newAllocationType === 'new_third' && (
                    <Grid container>
                        {universeState?.universe?.galaxies[remainingIndex].structure.planets.map((planet, index) => (
                            <Grid item xs={12} md={12} paddingY={'10px'} key={'planet_' + index}>
                                {valueField(planet, solarSystem2, setSolarSystem2)}
                            </Grid>
                        ))}
                    </Grid>
                )}

                {newAllocationType === 'one_third' && (
                    <FormControl fullWidth required>
                        <InputLabel id="connection_a">{capitalize(solarSystems?.[0]?.galaxy_name ?? '')}</InputLabel>{' '}
                        <Select
                            label={capitalize(solarSystems?.[0]?.galaxy_name ?? '')}
                            onChange={(e) => {
                                let copy = { ...solarSystem };
                                copy.connections[0]['c'] = e.target.value;
                                setSolarSystem(copy);
                            }}
                            value={solarSystem.connections[0]['c'] ?? ''}
                        >
                            {solarSystems.map((option) => (
                                <MenuItem value={option} key={option.id}>
                                    {option.planets[0].value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                {newAllocationType === 'many_third' && (
                    <Grid container>
                        {/* {solarSystems.map((system, index) => (
                            <Grid container alignItems={'center'} key={index}>
                                <Grid item xs={10}>
                                    {system.planets[0].value}
                                </Grid>
                                <Grid container item xs={2} justifyContent={'flex-end'}>
                                    <Checkbox checked={checkedSolarSystems[index]} onChange={() => handleSolarSystemCheckbox(index)} />
                                </Grid>
                            </Grid>
                        ))} */}
                        <Autocomplete
                            autoHighlight
                            disableCloseOnSelect
                            options={solarSystems}
                            multiple
                            fullWidth
                            getOptionLabel={(option) => option?.planets?.[0]?.value}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.planets[0].value}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={capitalize(universeState?.universe?.galaxies[remainingIndex]?.name ?? '')}
                                    inputProps={{ ...params.inputProps }}
                                />
                            )}
                            onChange={(e, newValue) => {
                                setCheckedSolarSystems(newValue);
                            }}
                            size={'small'}
                            value={checkedSolarSystems}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    function saveSolarSystem(e) {
        e.preventDefault();
        // for allocation
        let universeCopy = structuredClone(universeState.universe);

        let galaxy = universeCopy.galaxies.find((x) => x.name === solarSystem.galaxy_name);
        let created_name = solarSystem.planets[0].value;
        let solarSystemCopy = structuredClone(solarSystem);
        if (checkedSolarSystems.length && props.newSolarType === 4 && newAllocationType === 'many_third') {
            solarSystemCopy.connections = [];
            checkedSolarSystems.map((solar) => {
                solarSystemCopy.connections.push({
                    a: tableState.table[0][props?.xAxisIndex],
                    b: tableState.table[props?.yAxisIndex][0],
                    c: solar,
                });
                return null;
            });
        }
        // almost every creation except allocation with new third dimension on dashboard:
        // that is the only case where more than one solar system would be created
        if (newAllocationType !== 'new_third') {
            console.log(solarSystemCopy);
            //allocation that doesn't have appropriate connections
            if (props.newSolarType > 2) {
                if (!solarSystemCopy.connections[0].a || !solarSystemCopy.connections[0].b || !solarSystemCopy.connections[0].c) {
                    toast.warn('Not valid connection in the allocation to be created!');
                    return;
                }
            }

            axiosInstance
                .post('/api/create-solar-system/' + universeState?.universe?.id, {
                    galaxy_id: galaxy.id,
                    solar_system: solarSystemCopy,
                })
                .then((response) => {
                    if (response.status === 200) {
                        props.setNewSolarType('');
                        props.setOverlay(false);
                        setCheckedSolarSystems([]);
                        getTable(universeState?.universe?.id);
                        for (let i = 0; i < response?.data?.length; i++) {
                            galaxy.solar_systems.push(response.data[i]);
                        }
                        universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeCopy });
                        toast.success(capitalize(i18next.t('item_created', { item: capitalize(created_name) })));
                    } else if (response.status === 208) {
                        toast.warn(capitalize(galaxy.name + ' ' + i18next.t('name_already_used')));
                    } else {
                        toast.error('error');
                    }
                });
        }
        //first a solar system then an allocation is created
        else {
            const thirdGalaxy = universeCopy.galaxies.find((x) => x.name === solarSystem2.galaxy_name);
            let solarSystemCopy2 = structuredClone(solarSystem2);

            axiosInstance
                .post('/api/create-solar-system/' + universeState?.universe?.id, {
                    galaxy_id: thirdGalaxy.id,
                    solar_system: solarSystemCopy2,
                })
                .then((response) => {
                    if (response.status === 200) {
                        thirdGalaxy.solar_systems.push(response.data[0]);
                        let localCopy = structuredClone(solarSystemCopy);
                        let newConnectionKey;
                        console.log(localCopy);
                        if (localCopy.connections[0]?.a?.galaxy_name === response.data[0].galaxy_name || !localCopy.connections[0].a)
                            newConnectionKey = 'a';
                        else if (localCopy.connections[0]?.b?.galaxy_name === response.data[0].galaxy_name || !localCopy.connections[0].b)
                            newConnectionKey = 'b';
                        else if (localCopy.connections[0]?.c?.galaxy_name === response.data[0].galaxy_name || !localCopy.connections[0].c)
                            newConnectionKey = 'c';

                        localCopy.connections[0][newConnectionKey] = response.data[0];
                        axiosInstance
                            .post('/api/create-solar-system/' + universeState?.universe?.id, {
                                galaxy_id: galaxy.id,
                                solar_system: localCopy,
                            })
                            .then((response) => {
                                if (response.status === 200) {
                                    props.setNewSolarType('');
                                    props.setOverlay(false);
                                    setCheckedSolarSystems([]);
                                    //universe.refreshCallback.current = capitalize(
                                    //    i18next.t('item_created', { item: capitalize(created_name) })
                                    //);
                                    toast.success(i18next.t('item_created', { item: capitalize(created_name) }));
                                    galaxy.solar_systems.push(response.data[0]);
                                    universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeCopy });
                                    getTable(universeState?.universe?.id);
                                    //getUniverse(universeState?.universe?.id);
                                } else {
                                    toast.error('error');
                                }
                            });
                    } else if (response.status === 208) {
                        toast.warn(capitalize(galaxy.name + ' ' + i18next.t('name_already_used')));
                    } else {
                        toast.error('error');
                    }
                });
        }
    }

    return (
        <React.Fragment>
            <Dialog fullWidth open={Boolean(props.overlay)}>
                <form onSubmit={saveSolarSystem}>
                    <DialogTitle variant="h5" align={'center'} style={{ fontWeight: 'bold' }}>
                        {title()}
                    </DialogTitle>
                    <DialogContent>
                        {solarSystem.planets && solarSystem.planets.length ? (
                            <React.Fragment>
                                {solarSystem.planets.map((planet, index) => (
                                    <Grid item xs={12} md={12} paddingY={'10px'} key={'planet_' + index}>
                                        {valueField(planet, solarSystem, setSolarSystem)}
                                    </Grid>
                                ))}
                            </React.Fragment>
                        ) : (
                            <Grid item xs={12} md={12} paddingY={'10px'}>
                                <Typography>
                                    {capitalize(i18next.t('no_allocation_field')) +
                                        '. ' +
                                        capitalize(i18next.t('edit_datastructure_offer'))}
                                </Typography>
                                <Typography>{}</Typography>
                            </Grid>
                        )}

                        {Boolean(solarSystem?.connections?.length) && props.newSolarType === 3 && (
                            <React.Fragment>
                                {Object.keys(solarSystem.connections[0]).map((connection, index) => (
                                    <Grid item xs={12} md={12} paddingY={'10px'} key={'connection_' + index}>
                                        {connectionField(connection, index)}
                                    </Grid>
                                ))}
                            </React.Fragment>
                        )}
                        {Boolean(solarSystem?.connections?.length) && props.newSolarType === 4 && (
                            <React.Fragment>{thirdDimensionField()}</React.Fragment>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} justifyContent={'center'} padding={'10px'}>
                            <Grid item md={6} xs={6}>
                                <Button
                                    variant="contained"
                                    color="info"
                                    fullWidth
                                    onClick={() => {
                                        props.setOverlay(false);
                                        props.setNewSolarType('');
                                        setSolarSystem({});
                                        setSolarSystem2({});
                                        setNewAllocationType('one_third');
                                        setCheckedSolarSystems([]);
                                    }}
                                >
                                    {i18next.t('close')}
                                </Button>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Button variant="contained" color="success" fullWidth type={'submit'}>
                                    {i18next.t('save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
