import React from 'react';
import { Grid, Typography } from '@mui/material';

export default function Footer() {
    return (
        <Grid
            alignItems={'center'}
            item
            container
            justifyContent={'center'}
            style={{ position: 'fixed', bottom: '0', backgroundColor: 'white', height: '50px' }}
            xs={12}
            md={12}
        >
            <Grid item>
                <Typography>
                    <b>© 2022 Aspectoo Ltd.</b> A szoftvert fejlesztette: Smart Project Consulting Informatikai Kft.
                </Typography>
            </Grid>
        </Grid>
    );
}
