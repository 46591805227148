import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import i18next from 'i18next';
import { dateInputFormat, getDateTimeLocale } from '../../../utils';

function CustomDatePicker({
    onChange = () => {},
    disabled = false,
    propValue = null,
    required = false,
    dateFieldLabel = null,
    propRenderInput = () => {},
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateTimeLocale(i18next.language.toUpperCase())}>
            <DatePicker
                disabled={disabled}
                inputFormat={dateInputFormat()}
                required={required}
                renderInput={propRenderInput}
                label={dateFieldLabel}
                value={propValue}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
