import { createContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import axiosInstance from '../axiosApi';

const UniverseContext = createContext({});

export const universeActions = {
    SET_UNIVERSE: 'SET_UNIVERSE',
    SET_UNIVERSE_USERS: 'SET_UNIVERSE_USERS',
    SET_LOADING: 'SET_LOADING',
};

const defaultValues = {
    dimensionMembers: [],
    universe: {},
    universeId: null,
    universeUsers: [],
    loading: { state: false, text: '' },
};

function reducer(state, action) {
    switch (action.type) {
        case universeActions.RESET:
            return defaultValues;
        case universeActions.SET_UNIVERSE:
            return { ...state, universe: action.payload };
        case universeActions.SET_UNIVERSE_USERS:
            return { ...state, universeUsers: action.payload };
        case universeActions.SET_DIMENSION_MEMBERS:
            return { ...state, universeUsers: action.payload };
        case universeActions.SET_LOADING:
            return { ...state, loading: action.payload };
        default:
            console.error('BAD ACTION TYPE');
    }
}

export function UniverseProvider({ children }) {
    const navigate = useNavigate();

    const [universeState, universeDispatch] = useReducer(reducer, defaultValues);

    async function getUniverse(universeId = universeState.universe.id, redirect = false) {
        if (!universeId) return;
        universeDispatch({ type: universeActions.SET_LOADING, payload: { state: true, text: 'Loading' } });
        try {
            const [promiseUniverse, promiseUniverseUsers] = await Promise.all([
                getUniversePromise(universeId),
                getUniverseUsersPromise(universeId),
            ]);

            universeDispatch({ type: universeActions.SET_UNIVERSE, payload: promiseUniverse?.data });

            universeDispatch({ type: universeActions.SET_UNIVERSE_USERS, payload: promiseUniverseUsers?.data });

            if (redirect) navigate('/dashboard/' + universeId);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                navigate('/404');
                return;
            }
        } finally {
            universeDispatch({ type: universeActions.SET_LOADING, payload: { state: false, text: '' } });
        }
    }

    function getUniversePromise(universeId) {
        return axiosInstance.get(`/api/get-universe/${universeId}`);
    }

    function getUniverseUsersPromise(universeId) {
        return axiosInstance.post('/api/get-usernames/' + universeId);
    }

    return <UniverseContext.Provider value={{ getUniverse, universeState, universeDispatch }}>{children}</UniverseContext.Provider>;
}

export default UniverseContext;
