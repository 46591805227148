import React, { useRef } from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, TextField, Typography } from '@mui/material';

import { CustomToastContainer, LanguageSelect } from '../CustomComponents';
import axiosInstance from '../../axiosApi';
import { capitalize } from '../../utils';

export default function ForgottenPassword() {
    const navigate = useNavigate();

    const emailRef = useRef();

    function handlePasswordChange() {
        if (!emailRef.current.length) {
            toast.error(`${capitalize(i18next.t('no_address_given'))}!`);
            return;
        }

        axiosInstance
            .post('/api/reset-password/', {
                emailRef: emailRef.current,
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.msg) {
                        toast.error(`${capitalize(i18next.t('email_not_registered'))}!`);
                    } else {
                        toast.success(`${capitalize(i18next.t('email_sent'))}!`);
                    }
                }
            })
            .catch((error) => {
                toast.error(`${capitalize(i18next.t('error'))}!`);
            });
    }

    return (
        <React.Fragment>
            <CustomToastContainer />
            <Grid container justifyContent={'center'} padding={'10px'} className="fade-in">
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12} md={12} paddingY={'20px'}>
                        <Typography align={'center'} variant={'h4'}>
                            ALLOOKER
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} paddingY={'10px'}>
                        <TextField
                            autoComplete="email"
                            label={capitalize(i18next.t('email'))}
                            onChange={(e) => (emailRef.current = e.target.value)}
                            type="email"
                            value={emailRef.current}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} paddingY={'10px'}>
                        <Button fullWidth variant={'contained'} onClick={handlePasswordChange}>
                            {capitalize(i18next.t('password_reset_btn'))}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} paddingY={'10px'}>
                        <Button fullWidth onClick={() => navigate('/')} variant={'contained'}>
                            {capitalize(i18next.t('goto_login_btn'))}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} paddingY={'10px'}>
                        <LanguageSelect />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
