import { React, useState, useEffect } from 'react';
import i18next from 'i18next';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';

import { capitalize, formatDateTime } from '../../utils';
import useUniverse from '../../hooks/useUniverse';
import { CustomTooltip } from '../CustomComponents';

export default function HistoryTable() {
    const [search, setSearch] = useState({ state: false, value: '' });
    const { universeState, getUniverse } = useUniverse();
    const { universeId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log('useEffect');
        console.log(universeState?.universe?.history);
        if (!Object.keys(universeState?.universe)?.length) {
            console.log('in');
            getUniverse(universeId);
        }
    });
    function historyCondition(history) {
        if (!search.value) return true;
        return (
            formatDateTime(history.time).toString().toLowerCase().includes(search.value.toLowerCase()) ||
            history.title.toLowerCase().includes(search.value.toLowerCase()) ||
            history.description.toLowerCase().includes(search.value.toLowerCase()) ||
            getUserName(history.user).toLowerCase().includes(search.value.toLowerCase())
        );
    }

    function getUserName(id) {
        if (!Boolean(universeState?.universeUsers.length) || !id) {
            return;
        }
        let tmp = universeState?.universeUsers.find((x) => x.user_id === id);
        if (tmp) {
            return tmp.profile.email;
        }
        return 'Unkown';
    }

    return (
        <Grid container>
            {!search.state && (
                <Grid container paddingY={'5px'} justifyContent={'center'}>
                    <CustomTooltip
                        children={
                            <SearchIcon
                                onClick={() => {
                                    setSearch({ state: true, value: '' });
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        }
                        title={'Search'}
                    />
                    <Typography>{capitalize(i18next.t('history'))}</Typography>
                    <CustomTooltip
                        children={
                            <DashboardIcon
                                onClick={() => {
                                    navigate('/dashboard/' + universeId);
                                }}
                                style={{ cursor: 'pointer', color: 'grey' }}
                            />
                        }
                        title={'Search'}
                    />
                </Grid>
            )}
            {search.state && (
                <Grid container alignItems={'flex-end'}>
                    <Grid container item xs={4} justifyContent={'flex-end'}>
                        <CustomTooltip
                            children={
                                <ArrowBackIcon
                                    onClick={() => {
                                        setSearch({ state: false, value: '' });
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            title={capitalize('back')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={'Search'}
                            onChange={(e) => setSearch({ ...search, value: e.target.value })}
                            sx={{ marginTop: 0 }}
                            value={search.value}
                            variant={'standard'}
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <CustomTooltip
                            children={
                                <DashboardIcon
                                    onClick={() => {
                                        navigate('/dashboard/' + universeId);
                                    }}
                                    style={{ cursor: 'pointer', color: 'grey' }}
                                />
                            }
                            title={'Search'}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container padding={'10px'} justifyContent={'center'}>
                <TableContainer style={{ maxHeight: window.innerHeight - 100 }} component={Paper} elevation={3}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{capitalize(i18next.t('time'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('user'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('action'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('details'))}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {universeState?.universe?.history?.map(
                                (history, index) =>
                                    historyCondition(history) && (
                                        <TableRow key={history.id}>
                                            <TableCell>{formatDateTime(history.time)}</TableCell>
                                            <TableCell>{getUserName(history.user)}</TableCell>
                                            <TableCell>{history.title}</TableCell>
                                            <TableCell>{history.description}</TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
