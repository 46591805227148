import { createContext, useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

import axiosInstance from '../axiosApi';
import useUniverse from '../hooks/useUniverse';
import i18next from 'i18next';
import { capitalize } from '../utils';
import { universeActions } from './UniverseContext';

const PreferencesContext = createContext({});

export const preferencesActions = {
    RESET: 'RESET',
    SET_ACTIVE_VIEW_ID: 'SET_ACTIVE_VIEW_ID',
    SET_CROSS_SECTION: 'SET_CROSS_SECTION',
    SET_DIMENSION_ATTRIBUTES: 'SET_DIMENSION_ATTRIBUTES',
    SET_FILTER_PREFERENCE: 'SET_FILTER_PREFERENCE',
    SET_FONT_SIZE: 'SET_FONT_SIZE',
    SET_SOLAR_SYSTEM_CARD_FONT_SIZE: 'SET_SOLAR_SYSTEM_CARD_FONT_SIZE',
    SET_SORT_PREFERENCE: 'SET_SORT_PREFERENCE',
    SET_TABLE_CELL_WIDTH: 'SET_TABLE_CELL_WIDTH',
    SET_UNSAFE_TABLE_UPDATE: 'SET_UNSAFE_TABLE_UPDATE',
    SET_VIEWS: 'SET_VIEWS',
    SET_X_AXIS: 'SET_X_AXIS',
    SET_Y_AXIS: 'SET_Y_AXIS',
    SAVE_CONTEXT: 'SAVE_CONTEXT',
};

const defaultValues = {
    activeViewId: null,
    crossSection: [],
    dimensionAttributes: [],
    filterPreference: [],
    fontSize: 10,
    solarSystemCardFontSize: 10,
    sortPreference: [],
    tableCellWidth: 300,
    unsafeTableUpdate: 0,
    unsafePreferenceUpdate: 0,
    views: [],
    xAxis: null,
    yAxis: null,
};

function reducer(state, action) {
    switch (action.type) {
        case preferencesActions.RESET:
            return defaultValues;
        case preferencesActions.SET_ACTIVE_VIEW_ID:
            return { ...state, activeViewId: action.payload };
        case preferencesActions.SET_CROSS_SECTION:
            return { ...state, crossSection: action.payload };
        case preferencesActions.SET_DIMENSION_ATTRIBUTES:
            return { ...state, dimensionAttributes: action.payload };
        case preferencesActions.SET_FILTER_PREFERENCE:
            return { ...state, filterPreference: action.payload };
        case preferencesActions.SET_FONT_SIZE:
            return { ...state, fontSize: action.payload };
        case preferencesActions.SET_SOLAR_SYSTEM_CARD_FONT_SIZE:
            return { ...state, solarSystemCardFontSize: action.payload };
        case preferencesActions.SET_SORT_PREFERENCE:
            return { ...state, sortPreference: action.payload };
        case preferencesActions.SET_TABLE_CELL_WIDTH:
            return { ...state, tableCellWidth: action.payload };
        case preferencesActions.SET_UNSAFE_TABLE_UPDATE:
            return { ...state, unsafeTableUpdate: state.unsafeTableUpdate + 1 };
        case preferencesActions.SET_VIEWS:
            return { ...state, views: action.payload };
        case preferencesActions.SET_X_AXIS:
            return { ...state, xAxis: action.payload };
        case preferencesActions.SET_Y_AXIS:
            return { ...state, yAxis: action.payload };
        case preferencesActions.SAVE_CONTEXT:
            return {
                ...action.payload,
                unsafeTableUpdate: state.unsafeTableUpdate,
                unsafePreferenceUpdate: state.unsafePreferenceUpdate + 1,
            };
    }
}

export function PreferencesProvider({ children }) {
    const { universeState, getUniverse } = useUniverse();

    const [preferencesState, preferencesDispatch] = useReducer(reducer, defaultValues);

    useEffect(() => {
        if (Boolean(preferencesState.unsafePreferenceUpdate)) saveDefaultAxis();
    }, [preferencesState.unsafePreferenceUpdate]);

    async function saveAxisId(viewId) {
        if (viewId === preferencesState?.activeViewId) return;

        preferencesDispatch({ type: preferencesActions.SET_ACTIVE_VIEW_ID, payload: viewId });

        const viewsTemp = preferencesState.views.map((view) =>
            view.id === viewId ? { ...view, active: true } : { ...view, active: false }
        );

        const activeView = viewsTemp.find((x) => x.id === viewId);
        preferencesDispatch({
            type: preferencesActions.SET_CROSS_SECTION,
            payload: activeView.cross_section ? JSON.parse(activeView.cross_section) : [],
        });
        preferencesDispatch({ type: preferencesActions.SET_X_AXIS, payload: activeView.x_axis ? JSON.parse(activeView.x_axis) : null });
        preferencesDispatch({ type: preferencesActions.SET_Y_AXIS, payload: activeView.y_axis ? JSON.parse(activeView.y_axis) : null });
        preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: activeView.sort_preferences ?? [] });
        preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: activeView.filter_preferences ?? [] });
        preferencesDispatch({ type: preferencesActions.SET_TABLE_CELL_WIDTH, payload: activeView.table_cell_width ?? 300 });
        preferencesDispatch({ type: preferencesActions.SET_FONT_SIZE, payload: activeView.font_size });
        preferencesDispatch({
            type: preferencesActions.SET_SOLAR_SYSTEM_CARD_FONT_SIZE,
            payload: activeView.details_font_size ?? 10,
        });
        preferencesDispatch({ type: preferencesActions.SET_VIEWS, payload: viewsTemp });

        if (activeView.x_axis && activeView.y_axis && activeView?.cross_section) {
            let requestData = {
                axis_id: viewId,
                cross_section: JSON.parse(activeView.cross_section),
                details_font_size: activeView.details_font_size ?? 10,
                filter_preferences: activeView.filter_preferences ?? [],
                font_size: activeView.font_size,
                sort_preferences: activeView.sort_preferences ?? [],
                table_cell_width: activeView.table_cell_width ?? 300,
                x_axis: JSON.parse(activeView.x_axis),
                y_axis: JSON.parse(activeView.y_axis),
                user_permission: universeState.universe.user_permission,
            };

            try {
                const response = await axiosInstance.post(`/api/save-default-universe-axis/${universeState?.universe?.id}`, requestData);
                if (response?.status === 200) {
                    //toast.success(capitalize(i18next.t('save_successful')));
                    preferencesDispatch({ type: preferencesActions.SET_UNSAFE_TABLE_UPDATE, payload: null });
                    getUniverse();
                }
            } catch (error) {
                toast.error("Couldn't save axis");
            }
        }
    }

    async function saveDefaultAxis() {
        // if (!Object.keys(params).length) return;

        // const activeView = preferencesState?.views?.map((view) => (view?.active ? { ...view, ...params } : view));
        // preferencesDispatch({ type: preferencesActions.SET_ACTIVE_VIEW_ID, payload: activeView?.id });
        console.log(preferencesState);
        let requestData = {
            axis_id: preferencesState?.activeViewId,
            cross_section: preferencesState?.crossSection,
            details_font_size: preferencesState?.solarSystemCardFontSize,
            filter_preferences: preferencesState?.filterPreference,
            font_size: preferencesState?.fontSize,
            sort_preferences: preferencesState?.sortPreference,
            table_cell_width: preferencesState?.tableCellWidth,
            x_axis: preferencesState?.xAxis,
            y_axis: preferencesState?.yAxis,
            user_permission: universeState.universe.user_permission,
        };

        try {
            const response = await axiosInstance.post(`/api/save-default-universe-axis/${universeState?.universe?.id}`, requestData);
            if (response?.status === 200) {
                //toast.success(capitalize(i18next.t('save_successful')));
                preferencesDispatch({ type: preferencesActions.SET_UNSAFE_TABLE_UPDATE, payload: null });
                getUniverse();
            }
        } catch (error) {
            toast.error("Couldn't save axis");
        }
    }

    return (
        <PreferencesContext.Provider value={{ preferencesState, preferencesDispatch, saveAxisId, saveDefaultAxis }}>
            {children}
        </PreferencesContext.Provider>
    );
}

export default PreferencesContext;
