import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://pre.allooker.spci.hu/',
    timeout: 300000,
    headers: {
        Authorization: localStorage.getItem('access_token') ? 'JWT ' + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // test for token presence, no point in sending a request if token isn't present
        if (
            localStorage.getItem('refresh_token') &&
            error?.response?.status === 401 &&
            error?.response?.statusText === 'Unauthorized'
        ) {
            //Stop interceptor if the requested API call is refresh token - prevents infinite loop without using more interceptors
            if (error?.response?.config.url.toString().toUpperCase().includes('/api/token-refresh/'.toUpperCase())) {
                //Remove tokens from cookies
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location.reload();
            } else {
                const refresh_token = localStorage.getItem('refresh_token');
                try {
                    const response = await axiosInstance.post('/api/token-refresh/', { refresh: refresh_token });
                    localStorage.setItem('access_token', response.data.access);
                    localStorage.setItem('refresh_token', response.data.refresh);

                    axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                    originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;
                    return await axiosInstance(originalRequest);
                } catch (error_1) {
                    console.error(error_1);
                }
            }
        }
        // specific error handling done elsewhere
        return Promise.reject({ ...error });
    }
);

export default axiosInstance;
