import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { GoogleLogin } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, TextField, Typography } from '@mui/material';

import axiosInstance from '../../axiosApi';
import { CustomToastContainer } from '../CustomComponents';
import { capitalize } from '../../utils';
import useAuthentication from '../../hooks/useAuthentication';

export default function Login() {
    const { loggedIn, setLoggedIn } = useAuthentication();

    const navigate = useNavigate();

    const emailRef = useRef('');
    const passwordRef = useRef('');
    const [google_cid, setGoogle_cid] = useState('');

    useEffect(() => {
        if (loggedIn) navigate('/select-universe');
        getGoogleCID();
    }, []);

    function handleLogin(e) {
        e.preventDefault();

        const data = { username: emailRef.current, password: passwordRef.current };

        axiosInstance.post('/api/token-auth/', JSON.stringify(data)).then((response) => {
            if (response === 'not_activated') return toast.error(`${capitalize(i18next.t('not_activated'))}!`);

            if (response?.data?.access && response?.data?.refresh) {
                localStorage.setItem('access_token', response?.data?.access);
                localStorage.setItem('refresh_token', response?.data?.refresh);
                axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response?.data?.access;
                setLoggedIn(true);
                navigate('/select-universe');
            } else {
                toast.error(`${capitalize(i18next.t('incorrect_credentials'))}!`);
            }
        });
    }

    function getGoogleCID() {
        axiosInstance.post('/api/get-google-cid/', {}).then((response) => {
            if (response?.status === 200) setGoogle_cid(response?.data?.google_cid);
        });
    }

    function googleLoginSuccess(response) {
        axiosInstance
            .post('/api/google/', {
                response: response,
            })
            .then((res) => {
                var json = res.data;
                if (json.access && json.refresh) {
                    localStorage.setItem('access_token', json.access);
                    localStorage.setItem('refresh_token', json.refresh);
                    axiosInstance.defaults.headers['Authorization'] = 'JWT ' + json.access;
                    setLoggedIn(true);
                    navigate('/select-universe');
                } else {
                    toast.error(`${capitalize(i18next.t('incorrect_credentials'))}!`);
                }
            })
            .catch((error) => {
                console.warn(error);
                toast.error(i18next.t('error'));
            });
    }

    function googleLoginError(response) {
        console.error(response);
    }

    return (
        <React.Fragment>
            <CustomToastContainer />
            <Grid className="fade-in" container justifyContent={'center'} padding={'10px'}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12} md={12} paddingY={'20px'}>
                        <Typography align={'center'} variant={'h4'}>
                            ALLOOKER
                        </Typography>
                    </Grid>

                    <form onSubmit={(e) => handleLogin(e)}>
                        <Grid item xs={12} md={12} paddingY={'10px'}>
                            <TextField
                                autoComplete="email"
                                label={capitalize(i18next.t('email'))}
                                ref={emailRef}
                                required
                                onChange={(e) => (emailRef.current = e.target.value)}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} paddingY={'10px'}>
                            <TextField
                                required
                                label={capitalize(i18next.t('password'))}
                                onChange={(e) => (passwordRef.current = e.target.value)}
                                ref={passwordRef}
                                type="password"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid container item xs={12} md={12} paddingY={'10px'} justifyContent={'center'}>
                            <Button fullWidth variant="contained" type={'submit'}>
                                {capitalize(i18next.t('login'))}
                            </Button>
                        </Grid>
                        <Grid container item xs={12} md={12} paddingY={'10px'} justifyContent={'center'}>
                            {google_cid && (
                                <GoogleLogin
                                    buttonText={capitalize(i18next.t('google_login'))}
                                    clientId={google_cid}
                                    onSuccess={googleLoginSuccess}
                                    onFailure={googleLoginError}
                                />
                            )}
                        </Grid>
                        <Grid container item xs={12} md={12} paddingTop={'10px'} justifyContent={'center'}>
                            {capitalize(i18next.t('no_account'))}
                        </Grid>
                        <Grid container item xs={12} md={12} paddingBottom={'10px'} justifyContent={'center'}>
                            <Typography>
                                <span className={'pressable'} onClick={() => navigate('/registration')}>
                                    {`${capitalize(i18next.t('click_to_register'))}!`}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'center'}>
                            <Typography>
                                <span className={'pressable'} onClick={() => navigate('/forgotten-password')}>
                                    {capitalize(i18next.t('forgotten_password'))}
                                </span>
                            </Typography>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
