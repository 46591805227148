import { useState } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography, getTableBodyUtilityClass } from '@mui/material';
import axiosInstance from '../../axiosApi';

import { capitalize } from '../../utils';
import useUniverse from '../../hooks/useUniverse';
import useTable from '../../hooks/useTable';

export default function FileUploadDialog(props) {
    const [file, setFile] = useState();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const { getUniverse, universeState } = useUniverse();
    const { getTable } = useTable();

    function saveFile() {
        let data = new FormData();
        data.append('file', file);
        data.append('galaxyId', props.galaxyId);
        const id = toast.loading(i18next.t('upload_in_progress'), { autoClose: false, closeButton: true });
        axiosInstance.post('/api/excel-import/', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {
            if (response.status === 200) {
                setButtonsDisabled(false);
                toast.update(id, {
                    render: i18next.t('import_successful'),
                    type: 'success',
                    autoClose: 3000,
                    closeButton: true,
                    isLoading: false,
                    closeOnClick: true,
                });

                setFile(undefined);
                props.setOpen(false);
                getUniverse();
                getTable();

                if (response.data?.ignored?.length) {
                    let text = '';
                    text += '\nIgnored column(s):\n';
                    response.data.ignored.map((x, index) => {
                        text += x;
                        text += '\n';
                        if (index !== response.data.ignored.length - 1) {
                            text += ', ';
                        }
                        return null;
                    });
                    toast.info(text, { autoClose: false });
                }
                if (response.data?.incorrect_lines?.length) {
                    toast.info('Incorrect lines found. Check dimension history for details.', { autoClose: false });
                }
            } else if (response.status === 206) {
                setButtonsDisabled(false);
                let text = 'Missing column(s): ';
                response.data.map((x, index) => {
                    text += x;
                    if (index !== response.data.length - 1) {
                        text += ', ';
                    }
                    return null;
                });
                toast.update(id, {
                    render: text,
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    closeOnClick: true,
                });
            } else {
                setButtonsDisabled(false);
                toast.update(id, {
                    render: i18next.t('error'),
                    type: 'error',
                    autoClose: 3000,
                    closeButton: true,
                    isLoading: false,
                    closeOnClick: true,
                });
            }
        });
    }

    function getGalaxyName() {
        if (props.galaxyId) return universeState?.universe.galaxies.find((x) => x.id === props.galaxyId).name;
        return '';
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>
                <Typography align={'center'} variant={'h5'}>
                    {capitalize(i18next.t('import')) + ' ' + getGalaxyName() + ' data'}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container paddingY={'10px'} justifyContent={'center'}>
                    <Typography variant={'body1'}>Floating point numbers are accepted in such format: 5.3</Typography>
                    <Typography variant={'body1'}>Dates and datetimes are accepted in such format: 23/11/2022 19:42</Typography>
                    <Button component={'label'} style={{ marginTop: '20px' }}>
                        <input
                            type="file"
                            hidden
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => setFile(e.target?.files[0])}
                        />
                        {file?.name ?? 'Browse'}
                    </Button>
                </Grid>
                <Grid container spacing={2} justifyContent={'center'}>
                    <Grid item>
                        <Button
                            color="error"
                            variant="contained"
                            disabled={buttonsDisabled}
                            onClick={() => {
                                setFile(undefined);
                                props.setOpen(false);
                            }}
                        >
                            {i18next.t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setButtonsDisabled(true);
                                saveFile();
                            }}
                            disabled={!file || buttonsDisabled}
                        >
                            {capitalize(i18next.t('import'))}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
