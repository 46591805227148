import { lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { AuthProvider } from './context/AuthContext';
import { PreferencesProvider } from './context/PreferencesContext';
import { TableProvider } from './context/TableContext';
import { UniverseProvider } from './context/UniverseContext';

import axiosInstance from './axiosApi';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import ErrorFallbackDebug from './components/ErrorFallback/ErrorFallbackDebug';
import Loading from './components/Loading';
import theme from './theme';

import Admin from './components/Admin/Admin';
import Dashboard from './components/Dashboard/Dashboard';
import EditUniverse from './components/EditUniverse/EditUniverse';
import ForgottenPassword from './components/Identity/ForgottenPassword';
import HistoryTable from './components/HistoryTable/HistoryTable';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import Login from './components/Identity/Login';
import Registration from './components/Identity/Registration';
import SelectUniverse from './components/Selection/SelectUniverse';

export default function App() {
    const navigate = useNavigate();

    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        getDebugMode();
    }, []);

    async function getDebugMode() {
        try {
            const response = await axiosInstance.get('/api/get-debug-mode');
            setDebugMode(response?.data === 'True' || response?.data === 'true' || response?.data === true);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {/* FallbackComponent={debugMode ? ErrorFallbackDebug : ErrorFallback}*/}
            <ErrorBoundary
                FallbackComponent={debugMode ? ErrorFallbackDebug : ErrorFallback}
                onReset={() => navigate(window.location.pathname)}
            >
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <UniverseProvider>
                            <PreferencesProvider>
                                <TableProvider>
                                    <Suspense fallback={<Loading />}>
                                        <Routes>
                                            <Route path={'/'} element={<Login />} />
                                            <Route path={'/forgotten-password'} element={<ForgottenPassword />} />
                                            <Route path={'/registration'} element={<Registration />} />
                                            <Route path={'/admin'} element={<Admin />} />
                                            <Route path={'/select-universe'} element={<SelectUniverse />} />
                                            <Route path={'/edit/project/:universeId'} element={<EditUniverse />} />
                                            <Route path={'/dashboard/:universeId'} element={<Dashboard />} />
                                            <Route path={'/history/:universeId'} element={<HistoryTable />} />
                                            <Route path={'*'} element={<NotFoundPage />} />
                                        </Routes>
                                    </Suspense>
                                </TableProvider>
                            </PreferencesProvider>
                        </UniverseProvider>
                    </AuthProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </>
    );
}
