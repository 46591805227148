import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Switch,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditSolarSystem from '../EditSolarSystem/EditSolarSystem';
import CreateSolarSystemDialog from '../CreateSolarSystemDialog/CreateSolarSystemDialog';

import { CustomTooltip } from '../CustomComponents';
import { capitalize, formatDateTime, clog } from '../../utils';
import * as XLSX from 'xlsx';
import axiosInstance from '../../axiosApi';
import { CustomDeleteDialog, CustomToastContainer } from '../CustomComponents';
import { toast } from 'react-toastify';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { DataGrid } from '@mui/x-data-grid';
import FileUploadDialog from '../FileUploadDialog/FileUploadDialog';
import useUniverse from '../../hooks/useUniverse';
import Loading from '../Loading/Loading';

let preservePlanetNames = false;
export default function EditUniverse(props) {
    const { getUniverse, universeState } = useUniverse();

    const { universeId } = useParams();
    const navigate = useNavigate();

    const dialogContentRef = useRef('No content');

    const [editProjectName, setEditProjectName] = useState(false);
    const [editGalaxy, setEditGalaxy] = useState(false);
    const [editSolarSystem, setEditSolarSystem] = useState(null);
    const [tab, setTab] = useState('data_structure');
    const [descriptionDialogEnabled, setDescriptionDialogEnabled] = useState(false);
    const [deletePlanetState, setDeletePlanetState] = useState(false);
    const [deleteObjectState, setDeleteObjectState] = useState(false);
    const [galaxyLog, setGalaxyLog] = useState([]);
    const planetToDeleteRef = useRef(undefined);
    const objectToDeleteRef = useRef(undefined);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [galaxyIDForUpload, setGalaxyIDForUpload] = useState();
    const [galaxyMembersTabState, setGalaxyMembersTabState] = useState();
    const [universeCopy, setUniverseCopy] = useState(null);
    const [somethingInProgress, setSomethingInProgress] = useState(false);
    const [newSolarType, setNewSolarType] = useState();
    const [createOpen, setCreateOpen] = useState(false);

    useEffect(() => {
        getUniverse(universeState?.universe?.id ?? universeId);
    }, []);

    useEffect(() => {
        if (universeState?.universe) {
            if (preservePlanetNames) {
                //Preserve planet names
                const result = universeState?.universe.galaxies.find((x) => x.id === editGalaxy);
                if (result) {
                    let galaxyStructureCopy = clog(result.structure);
                    for (let i = 0; i < galaxyStructureCopy.planets.length; i++) {
                        const copyPlanet = universeCopy.galaxies
                            .find((x) => x.id === editGalaxy)
                            .structure.planets.find((x) => x.id === galaxyStructureCopy.planets[i].id);
                        if (copyPlanet) {
                            galaxyStructureCopy.planets[i].name = copyPlanet.name;
                            galaxyStructureCopy.planets[i].is_dimension = copyPlanet.is_dimension;
                            galaxyStructureCopy.planets[i].data_type = copyPlanet.data_type;
                        }
                    }
                    let stateCopy = clog(universeCopy);
                    stateCopy.galaxies.find((x) => x.id === editGalaxy).structure = galaxyStructureCopy;
                    setUniverseCopy(stateCopy);
                } else {
                    //Preserve allocation names
                    let galaxyAllocationsStructureCopy = clog(universeState?.universe.galaxies.find((x) => x.allocation_galaxy)?.structure);
                    for (let i = 0; i < galaxyAllocationsStructureCopy.planets.length; i++) {
                        const copyPlanet = universeCopy.galaxies[3].structure.planets.find(
                            (x) => x.id === galaxyAllocationsStructureCopy.planets[i].id
                        );
                        if (copyPlanet) {
                            galaxyAllocationsStructureCopy.planets[i].name = copyPlanet.name;
                            galaxyAllocationsStructureCopy.planets[i].is_dimension = copyPlanet.is_dimension;
                            galaxyAllocationsStructureCopy.planets[i].data_type = copyPlanet.data_type;
                        }
                    }
                    let stateCopy = clog(universeCopy);
                    stateCopy.galaxies[3].structure = galaxyAllocationsStructureCopy;
                    setUniverseCopy(stateCopy);
                }

                preservePlanetNames = false;
            } else setUniverseCopy(universeState?.universe);
        }
    }, [JSON.stringify(universeState?.universe)]);

    function editIconButton(galaxyId) {
        const editGalaxyValue = editGalaxy === galaxyId ? false : galaxyId;
        return (
            <React.Fragment>
                {editGalaxy === galaxyId ? (
                    <CustomTooltip title={capitalize(i18next.t('back'))}>
                        <IconButton
                            onClick={() => {
                                setTab('data_structure');
                                setEditGalaxy(false);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip title={capitalize(i18next.t('edit'))}>
                        <IconButton
                            onClick={() => {
                                setTab('data_structure');
                                setEditGalaxy(galaxyId);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </CustomTooltip>
                )}

                <CustomTooltip title={capitalize(i18next.t('history'))}>
                    <IconButton
                        onClick={() => {
                            setTab('history');
                            setEditGalaxy(false);
                            getGalaxyLog(galaxyId);
                        }}
                    >
                        <HistoryIcon />
                    </IconButton>
                </CustomTooltip>
            </React.Fragment>
        );
    }

    function editProjectNameEditIcon(value) {
        return (
            <CustomTooltip title={capitalize(i18next.t('edit'))}>
                <IconButton
                    onClick={() => {
                        setEditProjectName(value);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function getGalaxyLog(id) {
        axiosInstance.get('/api/get-galaxy-logs/' + id, {}).then((response) => {
            if (response.status === 200) {
                setGalaxyLog(response.data);
            }
        });
    }

    function saveProjectNameEditIcon() {
        return (
            <CustomTooltip title={capitalize(i18next.t('save'))}>
                <IconButton
                    onClick={() => {
                        if (universeCopy.name !== '') {
                            axiosInstance
                                .post('/api/modify-universe/' + universeCopy.id, {
                                    name: universeCopy.name,
                                })
                                .then((response) => {
                                    toast.success(capitalize(i18next.t('save_successful')));
                                    setEditProjectName(false);
                                });
                        } else {
                            toast.error(capitalize(i18next.t('fulfillment_reqiured')) + '!');
                        }
                    }}
                >
                    <SaveIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function dashboardIconButton() {
        return (
            <CustomTooltip title={capitalize(i18next.t('dashboard'))}>
                <IconButton onClick={() => getUniverse(universeCopy.id, true)}>
                    <DashboardIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function arrowForwardIconButton() {
        return (
            <CustomTooltip title={tab === 'data_structure' ? capitalize(i18next.t('data')) : capitalize(i18next.t('data_structure'))}>
                <IconButton
                    onClick={() => {
                        setTab(tab === 'data_structure' ? 'galaxymembers' : 'data_structure');
                        if (tab === 'data_structure') setGalaxyMembersTabState(editGalaxy);
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function addIconButton() {
        return (
            <CustomTooltip title={capitalize(i18next.t('add_field'))}>
                <IconButton
                    onClick={() => {
                        saveGalaxyStructure(true);
                    }}
                >
                    <AddIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function homePageButton() {
        return (
            <CustomTooltip title={capitalize(i18next.t('home_page'))}>
                <IconButton
                    onClick={() => {
                        navigate('/select-universe');
                    }}
                >
                    <HomeIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function dimensionExport(galaxy) {
        const workbook = XLSX.utils.book_new();
        let ws_data = [[...galaxy.structure.planets.map((x) => x.name)], [...galaxy.structure.planets.map((x) => '')]];
        console.log(ws_data);
        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, worksheet, galaxy.name);
        XLSX.writeFile(workbook, galaxy.name + '.xlsx');
    }

    function exportToExcel() {
        if (!universeCopy) return toast.warn(i18next.t('no_data_to_export'));
        if (!universeCopy.galaxies) return toast.warn(i18next.t('no_data_to_export'));
        if (!universeCopy.galaxies.length) return toast.warn(i18next.t('no_data_to_export'));
        if (!universeCopy.galaxies[0].solar_systems) return toast.warn(i18next.t('no_data_to_export'));
        if (!universeCopy.galaxies[0].solar_systems.length) return toast.warn(i18next.t('no_data_to_export'));
        if (!universeCopy.galaxies[0].solar_systems[0].planets) return toast.warn(i18next.t('no_data_to_export'));
        const workbook = XLSX.utils.book_new();

        //Create a worksheet containing the list of galaxies
        let ws_data = [
            ['ID', 'Project name'],
            [universeCopy.id, universeCopy.name],
            ['ID', 'Dimension name', 'Number of objects'],
        ];
        for (let i = 0; i < universeCopy.galaxies.length; i++) {
            const galaxy = universeCopy.galaxies[i];
            let row = [galaxy.id, galaxy.name, galaxy.solar_systems.length];
            ws_data.push(row);
        }
        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Project details');

        //Create a worksheet for each galaxy
        for (let i = 0; i < universeCopy.galaxies.length; i++) {
            const galaxy = universeCopy.galaxies[i];

            if (!galaxy.allocation_galaxy) {
                let ws_data = [
                    [
                        'ID',
                        ...galaxy.solar_systems[0].planets.map((x) => x.name),
                        // 'Connections a', 'Connections b'
                    ],
                ];
                for (let j = 0; j < galaxy.solar_systems.length; j++) {
                    let row = [galaxy.solar_systems[j].id];

                    for (let k = 0; k < galaxy.solar_systems[j].planets.length; k++) {
                        row.push(galaxy.solar_systems[j].planets[k].value);
                    }

                    ws_data.push(row);
                }
                const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
                XLSX.utils.book_append_sheet(workbook, worksheet, galaxy.name);
            } else {
                if (galaxy.solar_systems.length) {
                    let ws_data = [
                        ['ID', ...galaxy.solar_systems[0].planets.map((x) => x.name), 'Connection a', 'Connection b', 'Connection c'],
                    ];
                    for (let j = 0; j < galaxy.solar_systems.length; j++) {
                        let row = [galaxy.solar_systems[j].id];
                        for (let k = 0; k < galaxy.solar_systems[j].planets.length; k++) {
                            row.push(galaxy.solar_systems[j].planets[k].value);
                        }
                        row.push(
                            galaxy.solar_systems[j].connections[0].a.id,
                            galaxy.solar_systems[j].connections[0].b.id,
                            galaxy.solar_systems[j].connections[0].c.id
                        );
                        ws_data.push(row);
                    }
                    const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'allocations');
                }
            }
        }

        //Create a worksheet for the history
        if (universeCopy.history) {
            if (universeCopy.history.length) {
                let ws_data = [['ID', 'Date', 'Title', 'Description']];
                for (let j = 0; j < universeCopy.history.length; j++) {
                    let row = [
                        universeCopy.history[j].id,
                        universeCopy.history[j].time,
                        universeCopy.history[j].title,
                        universeCopy.history[j].description,
                    ];
                    ws_data.push(row);
                }
                const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'history');
            }
        }

        XLSX.writeFile(workbook, universeCopy.name + ' - Export.xlsx');
    }

    function exportIconButton() {
        return (
            <CustomTooltip
                children={
                    <IconButton onClick={() => exportToExcel()}>
                        <FileDownloadIcon />
                    </IconButton>
                }
                title={capitalize(i18next.t('export_to_excel'))}
            />
        );
    }

    function deleteIcon(planet, index, regularGalaxy = true) {
        if (planet.data_type === 'status' && !regularGalaxy) return '';
        if (!regularGalaxy || index !== 0) {
            return (
                <CustomTooltip title={capitalize(i18next.t('delete'))}>
                    <IconButton
                        onClick={() => {
                            planetToDeleteRef.current = planet;
                            setDeletePlanetState(true);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </CustomTooltip>
            );
        }
        return '';
    }

    function requiredSwitch(planet, index, regularGalaxy = true) {
        return (
            <CustomTooltip
                title={planet.is_dimension ? capitalize(i18next.t('switch_to_optional')) : capitalize(i18next.t('switch_to_required'))}
                children={
                    <Switch
                        checked={planet.is_dimension}
                        fontSize={'small'}
                        disabled={(index === 0 && regularGalaxy) || (planet.data_type === 'status' && !regularGalaxy)}
                        onChange={(e) => {
                            if (regularGalaxy) {
                                let copy = { ...universeCopy };
                                let planetCopy = copy.galaxies
                                    .find((x) => x.id === editGalaxy)
                                    .structure.planets.find((y) => y.id === planet.id);
                                planetCopy.is_dimension = e.target.checked;
                                setUniverseCopy(copy);
                            } else {
                                let copy = { ...universeCopy };
                                let planetCopy = copy.allocation_galaxy.structure.planets.find((y) => y.id === planet.id);
                                planetCopy.is_dimension = e.target.checked;
                                setUniverseCopy(copy);
                            }
                        }}
                    />
                }
            ></CustomTooltip>
        );
    }

    function dataTypeSelect(planet, index, regularGalaxy = true) {
        return (
            <FormControl fullWidth>
                <InputLabel id={'selectLabel_' + planet.id}>{capitalize(i18next.t('field_type'))}</InputLabel>
                <Select
                    labelId={'selectLabel_' + planet.id}
                    value={planet.data_type ?? ''}
                    disabled={(index === 0 && regularGalaxy) || (planet.data_type === 'status' && !regularGalaxy)}
                    onChange={(e) => {
                        let copy = { ...universeCopy };
                        let planetCopy = copy.galaxies.find((x) => x.id === editGalaxy).structure.planets.find((y) => y.id === planet.id);
                        planetCopy.data_type = e.target.value;
                        setUniverseCopy(copy);
                    }}
                    label={capitalize(i18next.t('field_type'))}
                >
                    <MenuItem value={'text'}>{capitalize(i18next.t('text'))}</MenuItem>
                    <MenuItem value={'number'}>{capitalize(i18next.t('number'))}</MenuItem>
                    <MenuItem value={'date'}>{capitalize(i18next.t('date'))}</MenuItem>
                    <MenuItem value={'datetime'}>{capitalize(i18next.t('dateplustime'))}</MenuItem>
                    {planet.data_type === 'status' && (
                        <MenuItem disabled value={'status'}>
                            {i18next.t('status')}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    }

    function planetNameTextField(planet, index, regularGalaxy = true) {
        return (
            <TextField
                label={regularGalaxy && index === 0 ? capitalize(i18next.t('display_name')) : ''}
                required={index === 0 && regularGalaxy}
                onChange={(e) => {
                    if (regularGalaxy) {
                        let copy = { ...universeCopy };
                        let planetCopy = copy.galaxies.find((x) => x.id === editGalaxy).structure.planets.find((y) => y.id === planet.id);
                        planetCopy.name = e.target.value;
                        setUniverseCopy(copy);
                    } else {
                        let copy = { ...universeCopy };
                        let planetCopy = copy.allocation_galaxy.structure.planets.find((y) => y.id === planet.id);
                        planetCopy.name = e.target.value;
                        setUniverseCopy(copy);
                    }
                }}
                value={planet.name ?? ''}
            />
        );
    }

    function saveGalaxyStructure(newPlanet = false, deletePlanet = false) {
        const galaxy = universeCopy.galaxies.find((x) => x.id === editGalaxy);
        const id = toast.loading('Update in progress', { autoClose: false, closeButton: true });
        try {
            axiosInstance
                .post('/api/save-galaxy-structure/' + universeCopy.id, {
                    galaxy_id: galaxy.id,
                    galaxy_structure: galaxy.structure,
                    galaxy_name: galaxy.name,
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (newPlanet) {
                            axiosInstance
                                .post('/api/add-galaxy-structure-planet/' + universeCopy.id, {
                                    galaxy_id: galaxy.id,
                                })
                                .then((response) => {
                                    if (response.status === 200) {
                                        toast.update(id, {
                                            render: i18next.t('field_added'),
                                            type: 'success',
                                            closeButton: true,
                                            isLoading: false,
                                            closeOnClick: true,
                                            autoClose: 2000,
                                        });
                                        preservePlanetNames = true;
                                        getUniverse(universeCopy.id);
                                    }
                                });
                        }
                        if (deletePlanet) {
                            const planet = planetToDeleteRef.current;
                            //const id = toast.loading('Delete in progress. It may take a few minutes.', { autoClose: false, closeButton: true });
                            axiosInstance
                                .post('/api/delete-galaxy-structure-planet/' + universeCopy.id, {
                                    galaxy_id: galaxy.id,
                                    planet_id: planet.id,
                                    allocation: false,
                                })
                                .then((response) => {
                                    if (response.status === 200) {
                                        toast.update(id, {
                                            render: i18next.t('field_deleted'),
                                            type: 'success',
                                            closeButton: true,
                                            isLoading: false,
                                            closeOnClick: true,
                                            autoClose: 2000,
                                        });
                                        getUniverse(universeCopy.id);
                                        setSomethingInProgress(false);
                                    } else {
                                        toast.update(id, {
                                            render: i18next.t('Something went wrong'),
                                            type: 'error',
                                            closeButton: true,
                                            isLoading: false,
                                            closeOnClick: true,
                                            autoClose: 2000,
                                        });
                                        setSomethingInProgress(false);
                                    }
                                });
                        }

                        if (!newPlanet && !deletePlanet) {
                            toast.update(id, {
                                render: i18next.t('galaxy_structure_saved'),
                                type: 'success',
                                closeButton: true,
                                isLoading: false,
                                closeOnClick: true,
                                autoClose: 2000,
                            });
                            setEditGalaxy(false);
                            getUniverse(universeCopy.id);
                        }

                        setSomethingInProgress(false);
                    } else if (response.status === 226) {
                        toast.update(id, {
                            render: i18next.t('galaxy_name_exists'),
                            type: 'warning',
                            closeButton: true,
                            isLoading: false,
                            closeOnClick: true,
                            autoClose: 2000,
                        });
                        setSomethingInProgress(false);
                    } else if (response.status === 204) {
                        toast.update(id, {
                            render: i18next.t('galaxy_name_empty'),
                            type: 'warning',
                            closeButton: true,
                            isLoading: false,
                            closeOnClick: true,
                            autoClose: 2000,
                        });
                        setSomethingInProgress(false);
                    } else {
                        toast.update(id, {
                            render: i18next.t('Something went wrong'),
                            type: 'error',
                            closeButton: true,
                            isLoading: false,
                            closeOnClick: true,
                            autoClose: 2000,
                        });
                        setSomethingInProgress(false);
                    }
                });
        } catch (error) {
            toast.update(id, {
                render: i18next.t('Something went wrong'),
                type: 'error',
                closeButton: true,
                isLoading: false,
                closeOnClick: true,
                autoClose: 2000,
            });
            setSomethingInProgress(false);
        }
    }

    function dataGridColumns() {
        let columns = [];
        columns.push({
            field: 'id',
            headerName: 'ID',
        });

        const galaxy = universeCopy.galaxies.find((x) => x.id === editGalaxy);

        for (let i = 0; i < galaxy.structure.planets.length; i++) {
            columns.push({
                field: galaxy.structure.planets[i].name,
                headerName: galaxy.structure.planets[i].name,
                flex: 1,
            });
        }
        return columns;
    }

    function dataGridRows() {
        let rows = [];
        const galaxy = universeCopy.galaxies.find((x) => x.id === editGalaxy);

        for (let i = 0; i < galaxy.solar_systems.length; i++) {
            let planets = { id: galaxy.solar_systems[i].id };
            for (let j = 0; j < galaxy.solar_systems[i].planets.length; j++) {
                planets[galaxy.solar_systems[i].planets[j].name] = galaxy.solar_systems[i].planets[j].value;
            }
            rows.push(planets);
        }
        return rows;
    }

    function dataGridDescription() {
        return (
            <Dialog open={descriptionDialogEnabled}>
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>{i18next.t('details')}</Grid>
                        <Grid item>
                            <IconButton edge={'end'} onClick={() => setDescriptionDialogEnabled(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>{dialogContentRef.current}</DialogContent>
            </Dialog>
        );
    }

    function getDataString(cell) {
        let object = JSON.parse(cell);
        console.log(JSON.parse(cell));
        let string = '';
        for (let j = 0; j < Object.keys(object).length; j++) {
            let key = Object.keys(object)[j];
            if (key === 'is_dimension') key = 'required';
            if (key === 'data_type') key = 'data type';
            string += key;
            console.log(string);
            string += ': ';
            string += Object.values(object)[j];
            console.log(string);
            if (j !== Object.keys(object).length - 1) string += ', ';
            console.log(string);
        }
        cell = string;
        return cell;
    }

    function historyView() {
        let history = structuredClone(galaxyLog);

        for (let i = 0; i < history.length; i++) {
            try {
                history[i].old_data = getDataString(history[i].old_data);
            } catch {}
            try {
                history[i].new_data = getDataString(history[i].new_data);
            } catch {}
        }

        if (history === null) return;
        return (
            <React.Fragment>
                <Grid container alignItems={'center'} justifyContent={'flex-end'}>
                    <Grid container item md={8} xs={8} justifyContent={'center'}>
                        <Typography fontWeight={'bold'}>{capitalize(i18next.t('history'))}</Typography>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{capitalize(i18next.t('user'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('date'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('type'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('old_data'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('new_data'))}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{history.map((row, index) => historyRow(row))}</TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }

    function getUserName(id) {
        if (!Boolean(universeState?.universeUsers.length) || !id) {
            return;
        }
        let tmp = universeState?.universeUsers.find((x) => x.user_id === id);
        if (tmp) {
            return tmp.profile.email;
        }
        return 'Unkown';
    }

    function historyRow(row) {
        return (
            <TableRow key={'row_' + row.id}>
                <TableCell>{getUserName(row.user)}</TableCell>
                <TableCell>{formatDateTime(row.time, true) ?? '-'}</TableCell>
                <TableCell>{row.change_type}</TableCell>
                <TableCell>{row.old_data}</TableCell>
                <TableCell>{row.new_data}</TableCell>
            </TableRow>
        );
    }

    function showGalaxyMembersView() {
        let tempState = galaxyMembersTabState;

        let rows = [];
        let columns = [];
        const galaxy = universeState?.universe.galaxies.find((x) => x.id === tempState);
        for (let i = 0; i < galaxy.structure.planets.length; i++) {
            const planet = galaxy.structure.planets[i];
            columns.push({ field: planet.name, headerName: planet.name, flex: 1 });
        }
        if (galaxy.allocation_galaxy) {
            universeState.universe.galaxies.map((x) => {
                if (x.allocation_galaxy) return null;
                columns.push({ field: x.name, headerName: x.name, flex: 1 });
                return null;
            });
        }
        columns.push({
            field: 'actions',
            headerName: 'Actions',

            renderCell: (params) => renderActions(params),
        });

        for (let i = 0; i < galaxy.solar_systems.length; i++) {
            const solarsystem = galaxy.solar_systems[i];
            let tmp = { id: solarsystem.id };
            for (let j = 0; j < solarsystem.planets.length; j++) {
                if (solarsystem.planets[j].data_type === 'date') {
                    tmp[solarsystem.planets[j].name] = formatDateTime(solarsystem.planets[j].value);
                } else if (solarsystem.planets[j].data_type === 'datetime') {
                    tmp[solarsystem.planets[j].name] = formatDateTime(solarsystem.planets[j].value, true);
                } else {
                    tmp[solarsystem.planets[j].name] = solarsystem.planets[j].value;
                }
            }
            if (galaxy.allocation_galaxy) {
                universeState.universe.galaxies.map((x) => {
                    if (x.allocation_galaxy) return null;
                    let value = Object?.values(galaxy.solar_systems[i]?.connections?.[0])?.find((y) => y.galaxy_name === x.name)?.displayed;
                    tmp[x?.name] = value;
                    return null;
                });
            }
            rows.push(tmp);
        }

        let galaxyName = universeState?.universe?.galaxies?.find((x) => x.id === galaxyMembersTabState)?.name ?? '';

        return (
            <Grid container>
                <Grid container item md={12} xs={12} justifyContent={'center'} alignItems={'center'} paddingY={'10px'}>
                    <Typography variant="h6">{capitalize(galaxyName + ': ' + 'Data')}</Typography>
                    <CustomTooltip
                        children={
                            <IconButton
                                onClick={() => {
                                    let galaxyIndex = universeCopy.galaxyOrder.findIndex((x) => x === galaxyMembersTabState);
                                    setCreateOpen(true);
                                    setNewSolarType(galaxyIndex);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        }
                        title={capitalize(i18next.t('new') + ' ' + galaxyName)}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid fullWidth rows={rows} columns={columns} />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    function renderActions(params) {
        const id = params.row.id;
        return (
            <Grid container>
                <Grid item xs={6} md={6}>
                    <CustomTooltip
                        children={
                            <IconButton
                                onClick={() => {
                                    setDeleteObjectState(true);
                                    objectToDeleteRef.current = id;
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                        title={capitalize(i18next.t('delete'))}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <CustomTooltip
                        children={<IconButton onClick={() => setEditSolarSystem(params.row)}>{<EditIcon />}</IconButton>}
                        title={capitalize(i18next.t('edit'))}
                    />
                </Grid>
            </Grid>
        );
    }

    function deleteSolarSystem() {
        const id = objectToDeleteRef.current;
        axiosInstance
            .post('/api/delete-solar-system/' + universeState?.universe.id, {
                solar_system_id: id,
            })
            .then((response) => {
                if (response.status === 200) {
                    //universe.refreshCallback.current = capitalize(i18next.t('item_deleted_successfully'));
                    getUniverse();
                }
            });
    }

    function showGalaxyMembersButton(galaxyId) {
        return (
            <CustomTooltip title={capitalize(i18next.t('data'))}>
                <IconButton
                    onClick={() => {
                        setTab('galaxymembers');
                        setGalaxyMembersTabState(galaxyId);
                        setEditGalaxy(false);
                    }}
                >
                    <TableChartOutlinedIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    if (!universeCopy) return '';

    return (
        <div className="full-screen fade-in" style={{ overflowY: 'auto' }}>
            <Grid container padding={'10px'}>
                <CustomToastContainer />
                <CustomDeleteDialog
                    openState={deletePlanetState}
                    setOpenState={setDeletePlanetState}
                    deleteFunction={() => {
                        setSomethingInProgress(true);
                        saveGalaxyStructure(false, true);
                    }}
                    question={i18next.t('delete_planet_question')}
                />
                <CustomDeleteDialog
                    openState={deleteObjectState}
                    setOpenState={setDeleteObjectState}
                    deleteFunction={deleteSolarSystem}
                    question={i18next.t('delete_solar_system_question')}
                />
                <CreateSolarSystemDialog
                    newSolarType={newSolarType}
                    setNewSolarType={setNewSolarType}
                    overlay={createOpen}
                    setOverlay={setCreateOpen}
                />
                <EditSolarSystem editSolarSystem={editSolarSystem} setEditSolarSystem={setEditSolarSystem} />
                <FileUploadDialog open={dialogOpen} galaxyId={galaxyIDForUpload} setOpen={setDialogOpen} />
                <Loading loading={universeState?.loading?.state} text={universeState?.loading?.text} />
                <Grid container item xs={12} md={12} justifyContent="center">
                    <Typography variant="h5">PROJECT DATA STRUCTURE</Typography>
                </Grid>
                <Grid container item xs={12} md={12} justifyContent="center">
                    {homePageButton()}
                    {dashboardIconButton()}
                    {exportIconButton()}
                </Grid>
                <Grid container item xs={12} md={12} justifyContent="center">
                    {editProjectName ? (
                        <React.Fragment>
                            <TextField
                                variant={'standard'}
                                value={universeCopy.name}
                                onChange={(e) => {
                                    let copy = { ...universeCopy };
                                    copy.name = e.target.value;
                                    setUniverseCopy(copy);
                                }}
                                style={{ width: '45%' }}
                            />

                            {saveProjectNameEditIcon()}
                        </React.Fragment>
                    ) : (
                        <Typography variant="h5">
                            {universeCopy.name ?? ''} {editProjectNameEditIcon(true)}
                        </Typography>
                    )}
                </Grid>

                {universeCopy.galaxies && (
                    // {universeCopy.galaxies && universeCopy.allocation_galaxy && (
                    <Grid container item xs={12} md={12} spacing={2} justifyContent="center" paddingY={'5px'} alignItems={'flex-start'}>
                        {universeCopy.galaxies.map((galaxy, index) => (
                            <Grid container item xs={3} key={index} justifyContent={'center'}>
                                {editGalaxy !== galaxy.id ? (
                                    <Grid container>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h6">
                                                {galaxy.name} {editIconButton(galaxy.id)}
                                                {showGalaxyMembersButton(galaxy.id)}
                                                {!galaxy.allocation_galaxy && (
                                                    <>
                                                        <CustomTooltip title={capitalize(i18next.t('import'))}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setDialogOpen(true);
                                                                    setGalaxyIDForUpload(galaxy.id);
                                                                }}
                                                            >
                                                                <FileUploadIcon />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                        <CustomTooltip title={capitalize(i18next.t('export dimension structure'))}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    dimensionExport(galaxy);
                                                                }}
                                                            >
                                                                <FileDownloadIcon />
                                                            </IconButton>
                                                        </CustomTooltip>
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="body2">
                                                {galaxy.allocation_galaxy ? '(Allocation)' : '(Dimension)'}
                                            </Typography>
                                        </Grid>
                                        {!editGalaxy && tab !== 'history' && (
                                            <>
                                                <Grid item xs={12} md={12} borderTop={'1px solid grey'}></Grid>
                                                {galaxy.structure.planets.map((planet, index) => (
                                                    <Grid item xs={12} md={12} key={planet?.id}>
                                                        {planet.name} ({planet.data_type}
                                                        {planet.is_dimension ? ', required' : ''})
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid container item xs={12} md={12}>
                                        <TextField
                                            variant={'standard'}
                                            placeholder={i18next.t('dimension_name')}
                                            value={galaxy.name ?? ''}
                                            onChange={(e) => {
                                                let copy = { ...universeCopy };
                                                let galaxyCopy = copy.galaxies.find((x) => x.id === editGalaxy);
                                                galaxyCopy.name = e.target.value;
                                                setUniverseCopy(copy);
                                            }}
                                            style={{ width: '50%' }}
                                        />
                                        {editIconButton(galaxy.id)}
                                        {showGalaxyMembersButton(galaxy.id)}
                                        {!galaxy.allocation_galaxy && (
                                            <>
                                                <CustomTooltip title={capitalize(i18next.t('import'))}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setDialogOpen(true);
                                                            setGalaxyIDForUpload(galaxy.id);
                                                        }}
                                                    >
                                                        <FileUploadIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                                <CustomTooltip title={capitalize(i18next.t('export dimension structure'))}>
                                                    <IconButton
                                                        onClick={() => {
                                                            dimensionExport(galaxy);
                                                        }}
                                                    >
                                                        <FileDownloadIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                )}
                {editGalaxy && (
                    <Grid container item xs={12} md={12} justifyContent="center" borderTop={'1px solid grey'}>
                        {tab === 'data_structure' && (
                            <React.Fragment>
                                <Grid container item xs={12} md={12} justifyContent="center" paddingTop={'10px'}>
                                    <Typography variant="h6">
                                        {capitalize(universeCopy.galaxies.find((x) => x.id === editGalaxy).name) + ': '}
                                        {tab === 'data_structure' ? i18next.t('data_structure') : i18next.t('data')}
                                        {/* {arrowForwardIconButton()} */}
                                        <CustomTooltip
                                            children={
                                                <IconButton
                                                    onClick={() => {
                                                        let galaxyIndex = universeCopy.galaxyOrder.findIndex((x) => x === editGalaxy);
                                                        setCreateOpen(true);
                                                        setNewSolarType(galaxyIndex);
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                            title={capitalize(
                                                i18next.t('new') +
                                                    ' ' +
                                                    universeState?.universe?.galaxies?.find((x) => x.id === editGalaxy)?.name ?? ''
                                            )}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid container paddingY="5px">
                                    <Grid container item xs={4} md={4} alignItems="center">
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                            {i18next.t('field_name')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                            {i18next.t('field_type')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }} align={'center'}>
                                            {i18next.t('required')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} justifyContent={'center'}>
                                        <Typography variant="h6" style={{ fontWeight: 'bold' }} align={'center'}>
                                            {i18next.t(capitalize('delete'))}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    {universeCopy.galaxies
                                        .find((x) => x.id === editGalaxy)
                                        .structure.planets.map((planet, inner_index) => (
                                            <Grid container spacing={1} paddingY={'5px'} key={inner_index + planet.id}>
                                                <Grid container item xs={4} md={4} alignItems="center">
                                                    {planetNameTextField(planet, inner_index)}
                                                </Grid>
                                                <Grid item xs={4} md={4}>
                                                    {dataTypeSelect(planet, inner_index)}
                                                </Grid>
                                                <Grid container item xs={2} md={2} alignItems="center" justifyContent={'center'}>
                                                    {requiredSwitch(planet, inner_index)}
                                                </Grid>
                                                <Grid container item xs={2} md={2} alignItems="center" justifyContent={'center'}>
                                                    {deleteIcon(planet, inner_index)}
                                                </Grid>
                                            </Grid>
                                        ))}
                                </Grid>

                                <Grid container paddingY="5px" justifyContent={'center'}>
                                    {addIconButton()}
                                </Grid>

                                <Grid container paddingY="5px" justifyContent={'center'}>
                                    <Grid container item xs={6} md={3} alignItems="center">
                                        <Button
                                            variant="contained"
                                            color="info"
                                            disabled={somethingInProgress}
                                            fullWidth
                                            onClick={() => {
                                                setSomethingInProgress(true);
                                                saveGalaxyStructure();
                                            }}
                                        >
                                            {i18next.t('save_form_data')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                )}
                {tab === 'history' && historyView()}
                {tab === 'galaxymembers' && showGalaxyMembersView()}
            </Grid>
        </div>
    );
}
