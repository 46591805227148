import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';

import { CustomToastContainer, CustomTooltip } from '../CustomComponents';
import CustomDeleteDialog from '../CustomComponents/DeleteDialog/CustomDeleteDialog';
import axiosInstance from '../../axiosApi';
import { capitalize, saveLog } from '../../utils';
import Loading from '../Loading/Loading';

import styles from './SelectUniverse.module.css';
import Footer from '../Footer/Footer';
import { universeActions } from '../../context/UniverseContext';
import useAuthentication from '../../hooks/useAuthentication';
import useUniverse from '../../hooks/useUniverse';

export default function SelectUniverse() {
    const { logOut, user } = useAuthentication();
    const { universeState, universeDispatch } = useUniverse();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [universeToCreate, setUniverseToCreate] = useState('empty');
    const [deleteMultiverseState, setDeleteMultiverseState] = useState(false);
    const [samples, setSamples] = useState([
        { name: 'No template', value: 'empty', dimensions: ['dim1', 'dim2', 'dim3'] },
        {
            name: 'Companies, Employees, Tasks',
            value: 'companies_employees_tasks',
            dimensions: ['employee', 'company', 'task'],
        },
        { name: 'Construction', value: 'construction', dimensions: ['client', 'subcontractor', 'operation'] },
        { name: 'Transport', value: 'transport', dimensions: ['driver', 'vehicle', 'order'] },
        { name: 'Production', value: 'production', dimensions: ['job', 'machine', 'operation'] },
    ]);
    const [loading, setLoading] = useState({ state: false, text: '' });

    const [projectSelectAnchorState, setProjectSelectAnchorState] = useState(null);
    const [templateSelectAnchorState, setTemplateSelectAnchorState] = useState(null);
    const [deleteUniverseState, setDeleteUniverseState] = useState(false);
    const [selectedUniverse, setSelectedUniverse] = useState(undefined);

    const [universes, setUniverses] = useState(null);

    useEffect(() => {
        getUniverses();
        const interval = setInterval(getUniverses, 10000);
        return () => clearInterval(interval);
    }, []);

    function copyUniverse(universeId) {
        axiosInstance
            .get('/api/get-universe/' + universeId)
            .then((response) => {
                if (response.status === 200) {
                    universeDispatch({ type: universeActions.SET_UNIVERSE, payload: response?.data });
                    getUniverses();
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    navigate('/404');
                    return;
                }
            });

        axiosInstance.post('/api/copy-universe/' + universeId, { universeToCopy: universeState?.universe }).then((response) => {
            if (response.status === 200) {
                toast.success(t('project_copy_without_data'));
            } else {
                toast.error(capitalize(t('error')));
            }
        });
    }

    function deleteUniverse() {
        if (selectedUniverse.user_permission === 'creator') {
            axiosInstance
                .post('/api/delete-universe/' + selectedUniverse.id, {})
                .then((response) => {
                    if (response.status === 200) {
                        toast.success(capitalize(t('universe_deleted')));
                        getUniverses();
                    }
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        toast.error(capitalize(t('no_permission')));
                    }
                });
        } else {
            axiosInstance.post('/api/delete-shared-universe/' + selectedUniverse.id).then((response) => {
                if (response.status === 200) {
                    toast.success(capitalize(t('universe_deleted')));
                    getUniverses();
                }
            });
        }
    }

    function deleteUniverses() {
        axiosInstance.post('/api/delete-universes', { universe_ids: [...universes?.map((x) => x.id)] }).then((response) => {
            if (response.status === 200) {
                toast.success(capitalize(t('universes_deleted')));
                setUniverses([]);
            }
            if (response.status === 204) {
                toast.warn(capitalize(t('no_deletable_universe')));
            }
        });
    }

    function getCardStyle(universe) {
        if (!universeState?.universe.is_shared) {
            return styles.ownCard;
        } else {
            if (universeState?.universe.user_permission === 'admin') {
                return styles.sharedAdminCard;
            }
            if (universeState?.universe.user_permission === 'write') {
                return styles.sharedWriteCard;
            }
            return styles.sharedReadCard;
        }
    }

    async function getUniverses() {
        try {
            const response = await axiosInstance.get('/api/get-multiverse');
            const universesTemp = response?.data?.map((universe) => {
                return {
                    id: universe?.id,
                    name: universe?.name,
                    galaxies_name: universe?.galaxies?.map((galaxy) => galaxy?.name),
                    is_shared: universe?.user_permission !== 'creator',
                    user_permission: universe.user_permission,
                };
            });
            setUniverses(universesTemp);
        } catch (error) {
            console.log(error);
        }
    }

    function handleCopyClick(universeId) {
        if (!universeId) return;
        copyUniverse(universeId);
    }

    function handleNextClick(universeId) {
        universeDispatch({ type: universeActions.SET_UNIVERSE, payload: null });
        navigate('/dashboard/' + universeId);
    }

    function handleTemplateGeneration(withData) {
        setLoading({ state: true, text: 'Generating template' });
        if (withData) {
            axiosInstance
                .post('/api/create-sample-universe/' + universeToCreate)
                .then((response) => {
                    if (response.status === 200) {
                        getUniverses();
                        toast.success(t('project_generated'));
                        setLoading({ state: false, text: '' });
                    }
                })
                .catch((err) => {
                    saveLog('Error generating project', err.message, 'error');
                    setLoading({ state: false, text: '' });
                    toast.info(t('project_not_generated'));
                });
        } else {
            axiosInstance
                .post('/api/create-sample-universe/' + universeToCreate, { empty: 'empty' })
                .then((response) => {
                    if (response.status === 200) {
                        getUniverses();
                        toast.success(t('project_generated'));
                        setLoading({ state: false, text: '' });
                    }
                })
                .catch((err) => {
                    saveLog('Error generating empty project', err.message, 'error');
                    setLoading({ state: false, text: '' });
                    toast.info(t('project_not_generated'));
                });
        }
    }

    function shareTooltipTitle(universe) {
        let expression = t('shared');
        expression += ' - ';
        expression += t(universe.user_permission);
        return expression;
    }

    if (user === null) return '';

    return (
        <Fragment>
            <CustomToastContainer />
            <Loading loading={loading.state} text={loading.text} />
            <CustomDeleteDialog
                openState={deleteMultiverseState}
                setOpenState={setDeleteMultiverseState}
                deleteFunction={deleteUniverses}
                question={t('delete_multiverse_question')}
            />
            <CustomDeleteDialog
                openState={deleteUniverseState}
                setOpenState={setDeleteUniverseState}
                question={t('delete_universe_question')}
                deleteFunction={deleteUniverse}
                withCheckbox={true}
            />
            <div className="full-screen fade-in" style={{ overflowY: 'auto' }}>
                <Grid container justifyContent={'center'}>
                    <Grid container item xs={12} md={6}>
                        <Grid
                            item
                            style={{
                                position: 'absolute',
                                top: '0px',
                                right: '10px',
                                height: 'auto',
                                width: 'auto',
                            }}
                        >
                            <CustomTooltip
                                children={
                                    <IconButton onClick={logOut}>
                                        <LogoutIcon />
                                    </IconButton>
                                }
                                title={capitalize(t('log_out'))}
                            />
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'center'} paddingTop={'20px'} spacing={2}>
                            <Grid item>
                                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item>
                                        <Typography align={'center'} variant={'h6'}>
                                            {capitalize(t('select_project'))}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {user?.is_superuser && (
                                            <Grid item>
                                                <CustomTooltip
                                                    children={
                                                        <IconButton onClick={() => navigate('/admin')}>
                                                            <AdminPanelSettingsIcon />
                                                        </IconButton>
                                                    }
                                                    title={'Admin'}
                                                />
                                                <CustomTooltip title={capitalize(t('delete_universe'))}>
                                                    <IconButton
                                                        onClick={() => {
                                                            setDeleteMultiverseState(true);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} paddingY={'5px'}>
                            {universes?.length === 0 ? (
                                <Grid container justifyContent={'center'} paddingY="10px">
                                    <Grid item>
                                        <Grid container justifyContent={'space-between'} spacing={2}>
                                            <Grid item>
                                                <InfoIcon />
                                            </Grid>
                                            <Grid item>
                                                <Typography>{capitalize(t('no_projects_created_yet'))}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container paddingY={'10px'} spacing={2}>
                                    {universes?.map((universe, index) => (
                                        <Grid item xs={3} md={3} key={index}>
                                            <Menu
                                                open={Boolean(projectSelectAnchorState)}
                                                anchorEl={projectSelectAnchorState}
                                                onClose={() => setProjectSelectAnchorState(null)}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleCopyClick(selectedUniverse.id);
                                                        setProjectSelectAnchorState(null);
                                                    }}
                                                >
                                                    {capitalize(t('copy_without_data'))}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        handleNextClick(selectedUniverse.id);
                                                        setProjectSelectAnchorState(null);
                                                    }}
                                                >
                                                    {capitalize(t('open'))}
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setProjectSelectAnchorState(null);
                                                        setDeleteUniverseState(true);
                                                    }}
                                                >
                                                    {capitalize(t('delete'))}
                                                </MenuItem>
                                            </Menu>
                                            <Card>
                                                <Stack className={getCardStyle(universe)}>
                                                    <Grid container paddingY={'10px'}>
                                                        <Grid
                                                            alignItems={'center'}
                                                            container
                                                            item
                                                            paddingLeft={'10px'}
                                                            onClick={() => handleNextClick(universe.id)}
                                                            style={{ cursor: 'pointer' }}
                                                            xs={10}
                                                            md={10}
                                                        >
                                                            <Typography noWrap style={{ fontSize: '10pt' }}>
                                                                {universe?.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid alignItems={'center'} container item xs={2} md={2} justifyContent={'center'}>
                                                            <MoreVertIcon
                                                                onClick={(event) => {
                                                                    setSelectedUniverse(universe);
                                                                    setProjectSelectAnchorState(event.currentTarget);
                                                                }}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        paddingLeft={'15px'}
                                                        paddingY={'3px'}
                                                        onClick={() => handleNextClick(universe.id)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {universe?.galaxies_name?.map((galaxy) => (
                                                            <Typography key={galaxy} style={{ fontSize: '8pt' }}>
                                                                {galaxy}
                                                            </Typography>
                                                        ))}
                                                    </Grid>
                                                    {universe?.is_shared && (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            justifyContent={'flex-end'}
                                                            paddingX={'15px'}
                                                            onClick={() => handleNextClick(universe.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <CustomTooltip
                                                                children={<ScreenShareOutlinedIcon />}
                                                                title={shareTooltipTitle(universe)}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Stack>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                        <Typography align={'center'} paddingTop={'20px'}>
                            {capitalize(t('templates'))}
                        </Typography>
                        <Grid container>
                            {samples.map((sample) => (
                                <Grid item xs={3} md={3} key={sample.name} paddingX="5px" paddingTop={'5px'}>
                                    <Menu
                                        open={Boolean(templateSelectAnchorState)}
                                        anchorEl={templateSelectAnchorState}
                                        onClose={() => setTemplateSelectAnchorState(null)}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setTemplateSelectAnchorState(null);
                                                handleTemplateGeneration(false);
                                            }}
                                        >
                                            {capitalize(t('generate_project_empty'))}
                                        </MenuItem>
                                        <MenuItem
                                            disabled={universeToCreate === 'empty'}
                                            onClick={() => {
                                                setTemplateSelectAnchorState(null);
                                                handleTemplateGeneration(true);
                                            }}
                                        >
                                            {capitalize(t('generate_project_with_data'))}
                                        </MenuItem>
                                    </Menu>
                                    <Card>
                                        <Grid container className={styles.ownCard}>
                                            <Grid container>
                                                <Grid alignItems={'center'} container item paddingLeft={'5px'} xs={10} md={10}>
                                                    <Typography noWrap style={{ fontSize: '10pt' }}>
                                                        {sample.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid alignItems={'center'} container item xs={2} md={2} justifyContent={'center'}>
                                                    <MoreVertIcon
                                                        onClick={(event) => {
                                                            setUniverseToCreate(sample.value);
                                                            setTemplateSelectAnchorState(event.currentTarget);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12} paddingLeft={'15px'}>
                                                {sample.dimensions.map((dim) => (
                                                    <Typography key={dim} style={{ fontSize: '8pt' }}>
                                                        {dim}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        </Fragment>
    );
}
