import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { clog, capitalize, getDateTimeLocale, dateInputFormat, thisYear } from '../../utils';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axiosInstance from '../../axiosApi';
import { toast } from 'react-toastify';
import CustomDatePicker from '../CustomComponents/DatePicker/CustomDatePicker';
import useTable from '../../hooks/useTable';
import useUniverse from '../../hooks/useUniverse';

export default function MultipleEditPopup(props) {
    const { tableState } = useTable();
    const { getUniverse, universeState } = useUniverse();

    const [sampleSolarSystem, setSampleSolarSystem] = useState({});

    useEffect(() => {
        if (props.multipleEdit && tableState?.activeSelection.length) {
            let selectedObjects;

            const solarSystems = universeState?.universe?.galaxies?.find((x) => x.allocation_galaxy)?.solar_systems;
            selectedObjects = solarSystems.filter((x) => tableState.activeSelection.includes(x.id));

            let Sample = clog(selectedObjects[0]);

            for (let i = 0; i < Sample.planets.length; i++) {
                if (!everythingIsSame(Sample.planets[i].name, selectedObjects)) {
                    Sample.planets[i].data_type === 'date' ? (Sample.planets[i].value = null) : (Sample.planets[i].value = '');
                }
                Sample.planets[i]['edit'] = false;
            }
            if (!everyConnectionIsSame(Sample.connections[0].a, selectedObjects)) {
                Sample.connections[0].a.id = -1;
            }
            Sample.connections[0].a['edit'] = false;
            if (!everyConnectionIsSame(Sample.connections[0].b, selectedObjects)) {
                Sample.connections[0].b.id = -1;
            }
            Sample.connections[0].b['edit'] = false;
            if (!everyConnectionIsSame(Sample.connections[0].c, selectedObjects)) {
                Sample.connections[0].c.id = -1;
            }
            Sample.connections[0].c['edit'] = false;
            setSampleSolarSystem(Sample);
        }
    }, [props.multipleEdit]);

    function handleSelectItemChange(planet) {
        let copy = { ...sampleSolarSystem };
        const index = copy.planets.findIndex((x) => x.id === planet.id);
        copy.planets[index].edit = !copy.planets[index].edit;
        setSampleSolarSystem(copy);
    }

    function handleConnectionItemChebox(key) {
        let copy = { ...sampleSolarSystem };
        copy.connections[0][key].edit = !copy.connections[0][key].edit;
        setSampleSolarSystem(copy);
    }

    function valueField(planet) {
        if (!planet) return <span>{'-'}</span>;
        let value = sampleSolarSystem.planets.find((x) => x.id === planet.id).value;

        if (['text', 'number'].includes(planet.data_type))
            return (
                <Grid container>
                    <Grid item md={10} xs={10}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={!planet.edit}
                            onChange={(e) => {
                                let copy = { ...sampleSolarSystem };
                                const index = copy.planets.findIndex((x) => x.id === planet.id);
                                let copyPlanet = { ...copy.planets[index] };
                                copyPlanet.value = e.target.value;
                                copy.planets[index] = copyPlanet;
                                setSampleSolarSystem(copy);
                            }}
                            label={capitalize(planet.name)}
                            type={planet.data_type}
                            value={value}
                        />
                    </Grid>
                    <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                        <Checkbox checked={planet.edit} onChange={() => handleSelectItemChange(planet)}></Checkbox>
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'date')
            return (
                <Grid container>
                    <Grid item md={10} xs={10}>
                        <CustomDatePicker
                            disabled={!planet.edit}
                            propRenderInput={(props) => (
                                <TextField
                                    required={planet.is_dimension}
                                    {...props}
                                    onChange={(e) => {
                                        if (e.target.value.length === 5) {
                                            const monthDay = e.target.value.split('/');
                                            value = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                        } else value = e.target.value;
                                    }}
                                />
                            )}
                            propValue={value}
                            label={planet.name}
                            onChange={(e) => {
                                if (e == 'Invalid Date') {
                                    e = value;
                                }
                                let copy = { ...sampleSolarSystem };
                                const index = copy.planets.findIndex((x) => x.id === planet.id);
                                let copyPlanet = { ...copy.planets[index] };
                                e.setMilliseconds(0);
                                e.setSeconds(0);
                                copyPlanet.value = e;
                                copy.planets[index] = copyPlanet;
                                setSampleSolarSystem(copy);
                            }}
                        />
                    </Grid>
                    <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                        <Checkbox checked={planet.edit} onChange={() => handleSelectItemChange(planet)}></Checkbox>
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'datetime')
            return (
                <Grid container>
                    <Grid item md={10} xs={10}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                inputFormat={dateInputFormat(true)}
                                renderInput={(props) => <TextField {...props} InputLabelProps={{ shrink: true }} />}
                                disabled={!planet.edit}
                                value={value}
                                label={capitalize(planet.name)}
                                onChange={(e) => {
                                    let copy = { ...sampleSolarSystem };
                                    const index = copy.planets.findIndex((x) => x.id === planet.id);
                                    let copyPlanet = { ...copy.planets[index] };
                                    e.setMilliseconds(0);
                                    e.setSeconds(0);
                                    copyPlanet.value = e;
                                    copy.planets[index] = copyPlanet;
                                    setSampleSolarSystem(copy);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                        <Checkbox checked={planet.edit} onChange={() => handleSelectItemChange(planet)}></Checkbox>
                    </Grid>
                </Grid>
            );
        else if (planet.data_type === 'status') {
            return (
                <Grid container>
                    <Grid item md={10} xs={10}>
                        <FormControl fullWidth>
                            <InputLabel id="statusLabel">{i18next.t('status')}</InputLabel>
                            <Select
                                disabled={!planet.edit}
                                value={value ?? ''}
                                onChange={(e) => {
                                    let copy = { ...sampleSolarSystem };
                                    const index = copy.planets.findIndex((x) => x.id === planet.id);
                                    let copyPlanet = { ...copy.planets[index] };
                                    copyPlanet.value = e.target.value;
                                    copy.planets[index] = copyPlanet;
                                    setSampleSolarSystem(copy);
                                }}
                                label={i18next.t('status')}
                            >
                                <MenuItem value={'to-do'}>{i18next.t('todo')}</MenuItem>
                                <MenuItem value={'in progress'}>{i18next.t('in_progress')}</MenuItem>
                                <MenuItem value={'done'}>{i18next.t('done')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                        <Checkbox checked={planet.edit} onChange={() => handleSelectItemChange(planet)}></Checkbox>
                    </Grid>
                </Grid>
            );
        }
    }

    function connectionField(key) {
        if (!Object.values(sampleSolarSystem).length) return;
        let solarSystems = universeState?.universe.galaxies.find(
            (x) => x.name === sampleSolarSystem.connections[0][key].galaxy_name
        ).solar_systems;
        let connection = sampleSolarSystem.connections[0][key];
        return (
            <Grid container>
                <Grid item xs={10}>
                    <FormControl fullWidth required disabled={!connection.edit}>
                        <InputLabel id="connection_a">{capitalize(connection.galaxy_name)}</InputLabel>{' '}
                        <Select
                            label={capitalize(connection.galaxy_name)}
                            onChange={(e) => {
                                let copy = { ...sampleSolarSystem };

                                copy.connections[0][key] = {
                                    ...e.target.value,
                                    edit: connection.edit,
                                };
                                setSampleSolarSystem(copy);
                            }}
                            value={connection.id === -1 ? '' : solarSystems.find((x) => x.id === connection.id)}
                        >
                            {solarSystems.map((option) => (
                                <MenuItem value={option} key={option.id}>
                                    {solarSystems.find((x) => option.id === x.id).planets[0].value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                    <Checkbox checked={connection.edit} onChange={() => handleConnectionItemChebox(key)}></Checkbox>
                </Grid>
            </Grid>
        );
    }
    function everythingIsSame(planet, selectedObjects) {
        let count = 0;
        let planetRef = selectedObjects[0].planets.find((x) => x.name === planet);
        if (planetRef.data_type === 'date') {
            let date = new Date(planetRef.value);
            date.setMilliseconds(0);
            date.setSeconds(0);
            planetRef.value = date;
        }
        for (let i = 0; i < selectedObjects.length; i++) {
            let currentPlanet = selectedObjects[i].planets.find((x) => x.name === planet);
            if (currentPlanet.data_type === 'date') {
                let date = new Date(currentPlanet.value);
                date.setMilliseconds(0);
                date.setSeconds(0);
                currentPlanet.value = date;
                if (currentPlanet.value.getTime() === planetRef.value.getTime()) {
                    count++;
                }
            } else if (currentPlanet.value === planetRef.value) {
                count++;
            }
        }
        return selectedObjects.length === count;
    }

    function everyConnectionIsSame(paramConnection, selectedObjects) {
        for (let i = 1; i < selectedObjects.length; i++) {
            let connection = Object.values(selectedObjects[i].connections[0]).find((x) => x.galaxy_name === paramConnection.galaxy_name);
            if (connection.id !== paramConnection.id) return false;
        }
        return true;
    }

    function saveChanges() {
        axiosInstance
            .post('/api/modify-allocations/' + universeState?.universe.id, {
                sample_allocation: sampleSolarSystem,
                ids: tableState.activeSelection,
            })
            .then((response) => {
                if (response.status === 200) {
                    getUniverse();

                    props.setMultipleEdit(false);
                    //universe.refreshCallback.current = capitalize(i18next.t('save_successful'));
                    props.getTable();
                }
            })
            .catch((error) => {
                toast.error('error');
            });
    }

    return (
        <React.Fragment>
            <Dialog fullWidth open={props.multipleEdit}>
                <DialogTitle variant="h5" align={'center'} style={{ fontWeight: 'bold' }}>
                    {capitalize(i18next.t('editing_multiple_allocations'))}
                </DialogTitle>
                <DialogContent>
                    <form style={{ width: '100%' }}>
                        <Grid container item>
                            <Grid container item md={12} xs={12} paddingBottom={'5px'}>
                                <Grid item md={10} xs={10}>
                                    {/* placeholder so that edit will be exactly in the middle of the last grid */}
                                </Grid>
                                <Grid container item md={2} xs={2} alignItems={'center'} justifyContent={'center'}>
                                    <span>{capitalize(i18next.t('edit'))}</span>
                                </Grid>
                            </Grid>
                            {sampleSolarSystem.planets && (
                                <React.Fragment>
                                    {sampleSolarSystem.planets.map((planet, index) => (
                                        <Grid item xs={12} md={12} paddingY={'10px'} key={'planet_' + index}>
                                            {valueField(planet)}
                                        </Grid>
                                    ))}
                                </React.Fragment>
                            )}
                            <Grid item xs={12} md={12} paddingY={'10px'}>
                                {connectionField('a')}
                            </Grid>
                            <Grid item xs={12} md={12} paddingY={'10px'}>
                                {connectionField('b')}
                            </Grid>
                            <Grid item xs={12} md={12} paddingY={'10px'}>
                                {connectionField('c')}
                            </Grid>

                            {/*sampleSolarSystem.connections && (
                                <React.Fragment>
                                    {sampleSolarSystem.connections.map((connection, index) => (
                                        <Grid item xs={12} md={12} paddingY={'10px'} key={'connection_' + index}>
                                            {connectionField(connection, index)}
                                        </Grid>
                                    ))}
                                </React.Fragment>
                                    )*/}
                        </Grid>

                        <Grid container spacing={1} justifyContent={'center'} paddingTop={'10px'}>
                            <Grid item md={6} xs={6}>
                                <Button variant="contained" color="info" fullWidth onClick={() => props.setMultipleEdit(false)}>
                                    {i18next.t('close')}
                                </Button>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Button variant="contained" color="success" fullWidth onClick={() => saveChanges()}>
                                    {i18next.t('save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
