import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Slider,
    TextField,
    Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import { LocalizationProvider } from '@mui/x-date-pickers';

import ShareIcon from '@mui/icons-material/Share';
import StorageIcon from '@mui/icons-material/Storage';
import SwapVertIcon from '@mui/icons-material/SwapVert';

import axiosInstance from '../../axiosApi';
import { capitalize, dateInputFormat, thisYear } from '../../utils';
import { CustomTooltip } from '../CustomComponents';
import { preferencesActions } from '../../context/PreferencesContext';
import { tableActions } from '../../context/TableContext';
import { universeActions } from '../../context/UniverseContext';
import usePreferences from '../../hooks/usePreferences';
import useTable from '../../hooks/useTable';
import useUniverse from '../../hooks/useUniverse';

const icon = <CheckBoxOutlineBlankIcon fontSize={'small'} />;
const checkedIcon = <CheckBoxIcon fontSize={'small'} />;

const enterTime = 800;
const exitTime = 0;

export default function FilterSlide(props) {
    const { preferencesState, preferencesDispatch, saveAxisId } = usePreferences();
    const { tableDispatch } = useTable();
    const { universeState, universeDispatch } = useUniverse();

    const navigate = useNavigate();

    const controllerRef = useRef();

    const [columnArrowUp, setColumnArrowUp] = useState(true);
    const [modify, setModify] = useState(false);
    const [rowArrowUp, setRowArrowUp] = useState(true);

    const [sharePermission, setSharePermission] = useState('read');
    const [shareValue, setShareValue] = useState('');
    const [sliderValue, setSliderValue] = useState(10);
    const [tab, setTab] = useState('filters');
    const [widthSlider, setWidthSlider] = useState(300);

    const [localPreferences, setLocalPreferences] = useState({});

    useEffect(() => {
        setLocalPreferences({
            activeViewId: preferencesState?.activeViewId,
            crossSection: preferencesState?.crossSection,
            dimensionAttributes: preferencesState?.dimensionAttributes,
            filterPreference: preferencesState?.filterPreference,
            fontSize: preferencesState?.fontSize,
            solarSystemCardFontSize: preferencesState?.solarSystemCardFontSize,
            sortPreference: preferencesState?.sortPreference,
            tableCellWidth: preferencesState?.tableCellWidth,
            views: preferencesState?.views,
            xAxis: preferencesState?.xAxis,
            yAxis: preferencesState?.yAxis,
        });
    }, [JSON.stringify(preferencesState)]);

    useEffect(() => {
        setSliderValue(preferencesState?.fontSize);
    }, [preferencesState?.fontSize]);

    useEffect(() => {
        setWidthSlider(preferencesState?.tableCellWidth);
    }, [preferencesState?.tableCellWidth]);

    useEffect(() => {
        controllerRef.current = new AbortController();
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    const filteredOptions = useMemo(() => {
        const filteredOptionsTemp = localPreferences?.dimensionAttributes?.filter(
            (dimensionAttribute) =>
                axisCondition(dimensionAttribute, localPreferences?.xAxis) && axisCondition(dimensionAttribute, localPreferences?.yAxis)
        );

        const column = localPreferences?.sortPreference?.find((x) => x?.sort_property?.galaxy === localPreferences?.xAxis?.galaxy);
        if (column?.['sort_option'] === 'DESC') setColumnArrowUp(false);
        else setColumnArrowUp(true);

        const row = localPreferences?.sortPreference?.find((x) => x?.sort_property?.galaxy === localPreferences?.yAxis?.galaxy);
        if (row?.['sort_option'] === 'DESC') setRowArrowUp(false);
        else setRowArrowUp(true);

        return filteredOptionsTemp;
    }, [
        JSON.stringify(localPreferences?.dimensionAttributes),
        JSON.stringify(localPreferences?.xAxis),
        JSON.stringify(localPreferences?.yAxis),
    ]);

    useEffect(() => {
        let inAxisAndInCells = localPreferences?.crossSection?.find((x) => x.galaxy === localPreferences?.xAxis?.galaxy);
        if (inAxisAndInCells) setLocalPreferences({ ...localPreferences, crossSection: [] });
        //preferencesDispatch({ type: preferencesActions.SET_CROSS_SECTION, payload: [] });
    }, [localPreferences?.xAxis?.galaxy]);

    useEffect(() => {
        let inAxisAndInCells = localPreferences?.crossSection?.find((x) => x.galaxy === localPreferences?.yAxis?.galaxy);
        if (inAxisAndInCells) setLocalPreferences({ ...localPreferences, crossSection: [] });
        //preferencesDispatch({ type: preferencesActions.SET_CROSS_SECTION, payload: [] });
    }, [localPreferences?.yAxis?.galaxy]);

    // empty array is default context value that can't become empty later
    if (!localPreferences?.dimensionAttributes?.length) return null;

    function setContext() {
        if (!localPreferences.xAxis || !localPreferences.yAxis || !localPreferences?.crossSection?.length) {
            toast.warn('Please select rows, columns and cells first');
            return;
        }
        preferencesDispatch({ type: preferencesActions.SAVE_CONTEXT, payload: localPreferences });
        tableDispatch({ type: tableActions.SET_PAGE, payload: 0 });
        tableDispatch({ type: tableActions.SET_COLUMN_PAGE, payload: 0 });
    }

    function arrowBackButton() {
        return (
            <CustomTooltip title={capitalize(i18next.t('back'))}>
                <IconButton onClick={() => setTab('filters')}>
                    <ArrowBackIcon />
                </IconButton>
            </CustomTooltip>
        );
    }

    function axisCondition(ax, otherAxis) {
        if (ax === null || otherAxis === null) return true;
        let othergalaxy = null;
        if (Array.isArray(otherAxis)) {
            if (otherAxis[0]) othergalaxy = otherAxis[0].galaxy;
            else othergalaxy = otherAxis[0];
        } else othergalaxy = otherAxis?.galaxy;
        return ax.galaxy !== othergalaxy;
    }

    function editIconButton(profile) {
        return (
            <CustomTooltip title={capitalize(i18next.t('edit'))}>
                <IconButton
                    onClick={() => {
                        setShareValue(profile.profile.email);
                        setSharePermission(profile.permission);
                        setModify(true);
                    }}
                >
                    <EditIcon fontSize={'small'} />
                </IconButton>
            </CustomTooltip>
        );
    }

    function getSimple(obj) {
        if (!obj) return obj;
        const allowedKeys = ['galaxy', 'axis'];

        if (typeof obj !== 'object') {
            try {
                obj = JSON.parse(obj);
            } catch (e) {
                return obj;
            }
        }

        let newObj = {};
        for (const [key, value] of Object.entries(obj)) {
            if (allowedKeys.includes(key)) {
                newObj[key] = value;
            }
        }
        return newObj;
    }

    async function getUsersList() {
        try {
            const response = await axiosInstance.post('/api/get-usernames/' + universeState?.universe?.id, {
                signal: controllerRef.current.signal,
            });
            universeDispatch({ type: universeActions.SET_UNIVERSE_USERS, payload: response?.data });
        } catch (error) {
            console.log(error);
        }
    }

    async function shareUniverse() {
        try {
            const response = await axiosInstance.post(
                `/api/share-universe/${universeState?.universe?.id}`,
                { user_email: shareValue, permission: sharePermission, is_modification: modify },
                { signal: controllerRef.current.signal }
            );

            if (response?.status === 200) {
                toast.success(capitalize(i18next.t('universe_shared')));
                getUsersList();
            } else if (response?.status === 208) {
                toast.info(capitalize(i18next.t('template_already_shared')));
            }
        } catch (err) {
            if (err.request.status === 404) {
                toast.error(capitalize(i18next.t('email_doesnt_exist')));
            }
            if (err.request.status === 403) {
                toast.error(capitalize(i18next.t('no_permission')));
            }
            if (err.request.status === 406) {
                toast.error(capitalize(i18next.t('cant_share_with_creator')));
            }
        }
    }

    function filterAxis(otherAxis) {
        return localPreferences?.dimensionAttributes?.filter((dimensionAttribute) => axisCondition(dimensionAttribute, otherAxis));
    }

    function swapAxisValues() {
        let temp = localPreferences?.yAxis;
        //preferencesDispatch({ type: preferencesActions.SET_Y_AXIS, payload: localPreferences?.xAxis });
        //preferencesDispatch({ type: preferencesActions.SET_X_AXIS, payload: temp });
        setLocalPreferences({ ...localPreferences, yAxis: localPreferences.xAxis, xAxis: temp });
        let tempArrow = rowArrowUp;
        setRowArrowUp(columnArrowUp);
        setColumnArrowUp(tempArrow);
    }

    function axisSelectorView() {
        return (
            <Fragment>
                <Grid container item xs={12} md={12} paddingY={'5px'} justifyContent={'center'}>
                    <CustomTooltip
                        children={
                            <IconButton onClick={swapAxisValues} size="small">
                                <SwapVertIcon className={'pressable'} fontSize={'medium'} />
                            </IconButton>
                        }
                        title={capitalize(i18next.t('swap_axis'))}
                    />
                </Grid>
                <Grid item xs={12} paddingY={'5px'}>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="selectYLabel">{capitalize(i18next.t('row'))}</InputLabel>
                        <Select
                            labelId="selectYLabel"
                            value={JSON.stringify(getSimple(localPreferences?.yAxis)) ?? ''}
                            onChange={(e) => {
                                //preferencesDispatch({ type: preferencesActions.SET_Y_AXIS, payload: JSON.parse(e.target.value) });

                                let other = localPreferences?.sortPreference?.find(
                                    (x) => x.sort_property.galaxy === localPreferences?.xAxis?.galaxy
                                );
                                let localSortPreference = [];
                                localSortPreference.push({
                                    sort_property: JSON.parse(e.target.value),
                                    sort_option: 'ASC',
                                });
                                if (other) localSortPreference.push(other);

                                //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: localSortPreference });
                                setLocalPreferences({
                                    ...localPreferences,
                                    sortPreference: localSortPreference,
                                    yAxis: JSON.parse(e.target.value),
                                });
                            }}
                            label={capitalize(i18next.t('row'))}
                        >
                            {filterAxis(localPreferences?.xAxis).map((ax, index) => (
                                <MenuItem value={JSON.stringify(ax)} key={index}>
                                    {capitalize(ax.galaxy) + ', ' + ax.axis}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} paddingY={'5px'}>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="selectXLabel">{capitalize(i18next.t('column'))}</InputLabel>
                        <Select
                            labelId="selectXLabel"
                            value={JSON.stringify(getSimple(localPreferences?.xAxis)) ?? ''}
                            onChange={(e) => {
                                //preferencesDispatch({ type: preferencesActions.SET_X_AXIS, payload: JSON.parse(e.target.value) });
                                let other = localPreferences?.sortPreference?.find(
                                    (x) => x.sort_property.galaxy === localPreferences?.yAxis?.galaxy
                                );
                                let localSortPreference = [];
                                localSortPreference.push({
                                    sort_property: JSON.parse(e.target.value),
                                    sort_option: 'ASC',
                                });

                                if (other) localSortPreference.push(other);
                                //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: localSortPreference });
                                setLocalPreferences({
                                    ...localPreferences,
                                    sortPreference: localSortPreference,
                                    xAxis: JSON.parse(e.target.value),
                                });
                            }}
                            label={capitalize(i18next.t('column'))}
                        >
                            {filterAxis(localPreferences?.yAxis).map((ax, index) => (
                                <MenuItem value={JSON.stringify(ax)} key={index}>
                                    {capitalize(ax.galaxy) + ', ' + ax.axis}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} paddingY={'5px'}>
                    <Autocomplete
                        disableCloseOnSelect
                        getOptionLabel={(option) => capitalize(option.galaxy) + ', ' + option.axis}
                        multiple
                        onChange={(e, value) => {
                            //referencesDispatch({ type: preferencesActions.SET_CROSS_SECTION, payload: value });
                            setLocalPreferences({ ...localPreferences, crossSection: value });
                        }}
                        options={filteredOptions}
                        renderInput={(params) => <TextField {...params} label={capitalize(i18next.t('cells'))} />}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {capitalize(option.galaxy) + ', ' + option.axis}
                            </li>
                        )}
                        isOptionEqualToValue={(option, value) => {
                            const reduced = getSimple(value);
                            return JSON.stringify(option) === JSON.stringify(reduced);
                        }}
                        size={'small'}
                        value={localPreferences?.crossSection ?? ''}
                    />
                </Grid>
                {localPreferences?.xAxis &&
                    localPreferences?.yAxis &&
                    universeState?.universe &&
                    universeState?.universe?.galaxies &&
                    universeState?.universe?.galaxies.map((x) => x.name).includes(localPreferences?.xAxis.galaxy) &&
                    universeState?.universe?.galaxies.map((x) => x.name).includes(localPreferences?.yAxis.galaxy) && (
                        <Grid container paddingY={'20px'}>
                            <Grid item xs={12}>
                                {sortRow()}
                            </Grid>
                            <Grid item xs={12}>
                                {sortColumn()}
                            </Grid>
                            <Grid container paddingTop={'20px'}>
                                {universeState?.universe?.galaxies.map((galaxy, index) => (
                                    <Grid container key={index}>
                                        {galaxyFilter(index)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                <Grid container>
                    <Grid container justifyContent={'center'}>
                        <Typography>{capitalize(i18next.t('font_size')) + ' (' + localPreferences?.fontSize + ' pt)'}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={10} md={10}>
                            <Slider
                                max={30}
                                min={5}
                                onChange={(e, value) => setSliderValue(value)}
                                onChangeCommitted={(e, value) => {
                                    //preferencesDispatch({ type: preferencesActions.SET_FONT_SIZE, payload: value });
                                    setLocalPreferences({ ...localPreferences, fontSize: value });
                                }}
                                size={'small'}
                                value={sliderValue}
                                valueLabelDisplay={'auto'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container justifyContent={'center'}>
                        <Typography>{capitalize(i18next.t('column_width')) + ' (' + localPreferences?.tableCellWidth + ' px)'}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={10}>
                            <Slider
                                max={500}
                                min={100}
                                onChange={(e, value) => setWidthSlider(value)}
                                onChangeCommitted={(e, value) => {
                                    //preferencesDispatch({ type: preferencesActions.SET_TABLE_CELL_WIDTH, payload: value });
                                    setLocalPreferences({ ...localPreferences, tableCellWidth: value });
                                }}
                                size={'small'}
                                value={widthSlider}
                                valueLabelDisplay={'auto'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Button fullWidth variant={'contained'} onClick={() => setContext()}>
                        Apply
                    </Button>
                </Grid>
            </Fragment>
        );
    }

    function galaxyFilter(galaxyIndex) {
        if (!universeState?.universe) return;
        if (!universeState?.universe?.galaxies) return;

        const galaxyName = universeState?.universe?.galaxies[galaxyIndex].name;
        let universeCopy = structuredClone(universeState?.universe);
        const galaxy = universeCopy.galaxies.find((x) => x.name === galaxyName);
        if (!galaxy) return;
        let preferenceCopy = structuredClone(localPreferences?.filterPreference);
        let index = preferenceCopy.findIndex((x) => x.filter_property.galaxy === galaxyName);
        let filterPreferenceValue = '';
        if (index !== -1 && preferenceCopy[index].filter_property) {
            delete preferenceCopy[index].filter_property['id'];
            filterPreferenceValue = JSON.stringify(preferenceCopy[index].filter_property);
        }
        let dataType = 'text';
        if (filterPreferenceValue && filterPreferenceValue && galaxy.structure.planets.length) {
            dataType = galaxy.structure.planets.find((x) => x.name === JSON.parse(filterPreferenceValue).axis);
            if (dataType) {
                dataType = dataType.data_type;
            }
        }

        return (
            <Grid container alignItems={'center'} paddingY={'5px'}>
                <Grid item xs={10}>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="property-select-flt-x">{'Filter ' + galaxyName}</InputLabel>
                        <Select
                            labelId="property-select-flt-x"
                            value={filterPreferenceValue ?? ''}
                            onChange={(e) => {
                                let evl = JSON.parse(e.target.value);
                                if (index !== -1) {
                                    preferenceCopy[index].filter_property = {
                                        galaxy: evl.galaxy,
                                        axis: evl.axis,
                                    };
                                } else {
                                    preferenceCopy.push({
                                        filter_property: {
                                            galaxy: evl.galaxy,
                                            axis: evl.axis,
                                        },
                                        filter_option: null,
                                    });
                                }
                                setLocalPreferences({ ...localPreferences, filterPreference: preferenceCopy });
                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: preferenceCopy });
                            }}
                            label={'Filter' + galaxyName}
                        >
                            {localPreferences?.dimensionAttributes?.map((axis, index) => {
                                return (
                                    axis.galaxy === galaxy.name && (
                                        <MenuItem key={index} value={JSON.stringify(axis)}>
                                            {axis.axis}
                                        </MenuItem>
                                    )
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={2} justifyContent={'flex-end'}>
                    <CustomTooltip title="Clear filter preference">
                        <IconButton
                            onClick={() => {
                                if (index !== -1) {
                                    preferenceCopy.splice(index, 1);
                                    //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: preferenceCopy });
                                    setLocalPreferences({ ...localPreferences, filterPreference: preferenceCopy });
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </CustomTooltip>
                </Grid>

                {index !== -1 && (
                    <Grid container paddingY={'10px'}>
                        {dataTypeSelector(dataType, galaxyName, preferenceCopy, index)}
                    </Grid>
                )}
            </Grid>
        );
    }

    function sortColumn() {
        let sortCopy = structuredClone(localPreferences?.sortPreference);
        let xPreference = sortCopy.find((x) => x.sort_property.galaxy === localPreferences?.xAxis.galaxy);

        let disabled = null;
        let sort_option = null;
        let sort_property = null;
        let compareGalaxyName = null;
        disabled = !localPreferences?.xAxis;
        compareGalaxyName = localPreferences?.xAxis.galaxy;
        sort_option = xPreference ? xPreference.sort_option : null;
        if (xPreference && xPreference.sort_property) delete xPreference.sort_property['id'];
        sort_property = xPreference ? JSON.stringify(xPreference.sort_property) : null;

        let copy = structuredClone(localPreferences?.sortPreference);
        let index = copy.findIndex((x) => x.sort_property.galaxy === compareGalaxyName);

        return (
            <Grid container alignItems={'center'}>
                <Grid item paddingY={'5px'} xs={10}>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="property-select">{capitalize(i18next.t('sort column by'))}</InputLabel>
                        <Select
                            disabled={disabled}
                            labelId="property-select"
                            value={sort_property ?? ''}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].sort_property = JSON.parse(e.target.value);
                                    copy[index].sort_option = columnArrowUp ? 'ASC' : 'DESC';
                                } else {
                                    copy.push({
                                        sort_property: JSON.parse(e.target.value),
                                        sort_option: columnArrowUp ? 'ASC' : 'DESC',
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, sortPreference: copy });
                            }}
                            label={capitalize(i18next.t('sort column by'))}
                        >
                            {localPreferences?.dimensionAttributes.map(
                                (ax, index) =>
                                    ax.galaxy === compareGalaxyName && (
                                        <MenuItem value={JSON.stringify(ax)} key={index}>
                                            {capitalize(ax.galaxy) + ', ' + ax.axis}
                                        </MenuItem>
                                    )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                {columnArrowUp && (
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <CustomTooltip title={i18next.t('ascending')}>
                            <IconButton
                                className="pressable"
                                onClick={() => {
                                    setColumnArrowUp(false);

                                    if (copy[index]?.sort_property) {
                                        copy[index].sort_option = 'DESC';
                                        // preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                        setLocalPreferences({ ...localPreferences, sortPreference: copy });
                                    }
                                }}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                )}
                {!columnArrowUp && (
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <CustomTooltip title={i18next.t('descending')}>
                            <IconButton
                                className="pressable"
                                onClick={() => {
                                    setColumnArrowUp(true);
                                    if (copy[index]?.sort_property) {
                                        copy[index].sort_option = 'ASC';
                                        //referencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                        setLocalPreferences({ ...localPreferences, sortPreference: copy });
                                    }
                                }}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                )}
            </Grid>
        );
    }
    function sortRow() {
        let sortCopy = structuredClone(localPreferences?.sortPreference);
        let yPreference = sortCopy?.find((x) => x.sort_property.galaxy === localPreferences?.yAxis.galaxy);

        let disabled = null;
        let sort_option = null;
        let sort_property = null;
        let compareGalaxyName = null;

        disabled = !localPreferences?.yAxis;
        compareGalaxyName = localPreferences?.yAxis?.galaxy;
        sort_option = yPreference ? yPreference.sort_option : null;
        if (yPreference && yPreference.sort_property) delete yPreference.sort_property['id'];
        sort_property = yPreference ? JSON.stringify(yPreference.sort_property) : null;

        let copy = structuredClone(localPreferences?.sortPreference);
        let index = copy.findIndex((x) => x.sort_property.galaxy === compareGalaxyName);

        return (
            <Grid container alignItems={'center'}>
                <Grid item paddingY={'5px'} xs={10}>
                    <FormControl fullWidth size={'small'}>
                        <InputLabel id="property-select">{capitalize(i18next.t('sort row by'))}</InputLabel>
                        <Select
                            disabled={disabled}
                            labelId="property-select"
                            value={sort_property ?? ''}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].sort_property = JSON.parse(e.target.value);
                                    copy[index].sort_option = rowArrowUp ? 'ASC' : 'DESC';
                                } else {
                                    copy.push({
                                        sort_property: JSON.parse(e.target.value),
                                        sort_option: rowArrowUp ? 'ASC' : 'DESC',
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, sortPreference: copy });
                            }}
                            label={capitalize(i18next.t('sort row by'))}
                        >
                            {localPreferences?.dimensionAttributes?.map(
                                (ax, index) =>
                                    ax.galaxy === compareGalaxyName && (
                                        <MenuItem value={JSON.stringify(ax)} key={index}>
                                            {capitalize(ax.galaxy) + ', ' + ax.axis}
                                        </MenuItem>
                                    )
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                {rowArrowUp && (
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <CustomTooltip title={i18next.t('ascending')}>
                            <IconButton
                                className="pressable"
                                onClick={() => {
                                    setRowArrowUp(false);
                                    if (copy[index]?.sort_property) {
                                        copy[index].sort_option = 'DESC';
                                        //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                        setLocalPreferences({ ...localPreferences, sortPreference: copy });
                                    }
                                }}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                )}
                {!rowArrowUp && (
                    <Grid container item xs={2} justifyContent={'flex-end'}>
                        <CustomTooltip title={i18next.t('descending')}>
                            <IconButton
                                className="pressable"
                                onClick={() => {
                                    setRowArrowUp(true);
                                    if (copy[index]?.sort_property) {
                                        copy[index].sort_option = 'ASC';
                                        //preferencesDispatch({ type: preferencesActions.SET_SORT_PREFERENCE, payload: copy });
                                        setLocalPreferences({ ...localPreferences, sortPreference: copy });
                                    }
                                }}
                            >
                                <ArrowDownwardIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                )}
            </Grid>
        );
    }

    async function unshareUniverse() {
        let sharedId = universeState.universeUsers.find((x) => x?.profile?.email === shareValue)?.id;
        if (sharedId) {
            try {
                const response = await axiosInstance.post(
                    '/api/unshare-universe/' + universeState?.universe?.id,
                    { shared_universe_id: sharedId },
                    { signal: controllerRef.current.signal }
                );
                if (response?.status !== 200) return;

                toast.success(capitalize(i18next.t('universe_shared')));
                getUsersList();
                setModify(false);
                setShareValue('');
            } catch (error) {
                console.log(error);
            }
        }
    }

    function shareUniverseView() {
        console.log(universeState.universeUsers);
        return (
            <Grid container className="fade-in">
                {!(
                    ['read'].includes(universeState?.universe?.user_permission) ||
                    ['write'].includes(universeState?.universe?.user_permission)
                ) && (
                    <Grid container item xs={12} md={12} justifyContent={'center'}>
                        <Typography>{capitalize(i18next.t('share_universe'))}</Typography>
                    </Grid>
                )}
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        shareUniverse();
                    }}
                    style={{ width: '100%' }}
                >
                    {!(
                        ['read'].includes(universeState?.universe?.user_permission) ||
                        ['write'].includes(universeState?.universe?.user_permission)
                    ) && (
                        <Fragment>
                            <Grid container item xs={12} md={12} justifyContent={'center'}>
                                <TextField
                                    required
                                    placeholder={capitalize(i18next.t('email'))}
                                    type="email"
                                    value={shareValue}
                                    onChange={(e) => setShareValue(e.target.value)}
                                />
                            </Grid>
                            <Grid container item xs={12} md={12} justifyContent={'center'} paddingTop={'10px'}>
                                <FormControl fullWidth>
                                    <InputLabel id="permission1">{capitalize(i18next.t('permission'))}</InputLabel>
                                    <Select
                                        labelId="permission1"
                                        value={sharePermission ?? ''}
                                        onChange={(e) => setSharePermission(e.target.value)}
                                        label={capitalize(i18next.t('permission'))}
                                        disabled={['read'].includes(universeState?.universe?.user_permission)}
                                    >
                                        {['read', 'write', 'admin'].map((permissionName, index) => (
                                            <MenuItem value={permissionName} key={index}>
                                                {capitalize(i18next.t(permissionName))}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {!modify ? (
                                <Grid container justifyContent={'center'} paddingY={'5px'}>
                                    <Button
                                        color="info"
                                        variant={'contained'}
                                        fullWidth
                                        type="submit"
                                        disabled={['read'].includes(universeState?.universe?.user_permission)}
                                    >
                                        {capitalize(i18next.t('share'))}
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid container spacing={1} justifyContent={'center'} paddingY={'5px'}>
                                    {!['read', 'write'].includes(universeState?.universe?.user_permission) && (
                                        <Grid container item xs={4} md={4}>
                                            <Button color="info" variant={'contained'} fullWidth onClick={() => unshareUniverse()}>
                                                {capitalize(i18next.t('unshare'))}
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        item
                                        xs={!['read', 'write'].includes(universeState?.universe?.user_permission) ? 4 : 8}
                                        md={!['read', 'write'].includes(universeState?.universe?.user_permission) ? 4 : 8}
                                    >
                                        <Button color="info" variant={'contained'} fullWidth type="submit">
                                            {capitalize(i18next.t('modify'))}
                                        </Button>
                                    </Grid>
                                    <Grid container item xs={4} md={4}>
                                        <Button
                                            color="info"
                                            variant={'contained'}
                                            fullWidth
                                            onClick={() => {
                                                setModify(false);
                                                setShareValue('');
                                            }}
                                        >
                                            {capitalize(i18next.t('back'))}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Fragment>
                    )}

                    {universeState?.universeUsers?.length > 0 && (
                        <Grid container item xs={12} md={12} justifyContent={'center'} paddingTop={'10px'}>
                            <Typography>{capitalize(i18next.t('shared_users'))}</Typography>
                        </Grid>
                    )}
                    <Grid container item xs={12} md={12} justifyContent={'center'} paddingY={'5px'}>
                        {universeState?.universeUsers && (
                            <Grid container paddingY={'6px'} borderTop={'1px solid #e5e5e5'}>
                                <Grid item xs={10} md={10}>
                                    <Grid container>
                                        <Typography variant={'p'} style={{ fontWeight: 'bold' }}>
                                            {universeState?.universeUsers.find((x) => x.permission === 'creator') && 'Owner'}
                                        </Typography>
                                    </Grid>
                                    <Grid container>
                                        <Typography variant={'p'}>
                                            {universeState?.universeUsers.find((x) => x.permission === 'creator').profile.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {universeState?.universeUsers?.map(
                            (user, index) =>
                                user.permission !== 'creator' && (
                                    <Grid container key={index} paddingY={'2px'} borderTop={'1px solid #e5e5e5'}>
                                        <Grid item xs={10} md={10}>
                                            <Grid container>
                                                <Typography variant={'p'} style={{ fontWeight: 'bold' }}>
                                                    {capitalize(user.permission ?? '')}
                                                </Typography>
                                            </Grid>
                                            <Grid container>
                                                <Typography variant={'p'}>{user?.profile?.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            md={2}
                                            sx={
                                                !(
                                                    ['read'].includes(universeState?.universe?.user_permission) ||
                                                    ['write'].includes(universeState?.universe?.user_permission)
                                                )
                                                    ? {}
                                                    : { display: 'none' }
                                            }
                                        >
                                            {editIconButton(user)}
                                        </Grid>
                                    </Grid>
                                )
                        )}
                    </Grid>
                </form>
            </Grid>
        );
    }

    function tabView() {
        switch (tab) {
            case 'filters':
                return axisSelectorView();
            case 'share':
                return shareUniverseView();
        }
    }

    function dataTypeSelector(dataType, galaxyName, copy, index) {
        if (dataType === 'text' || dataType === 'status') {
            const galaxy = universeState?.universe?.galaxies.find((x) => x.name === galaxyName);

            // BARNI - galaxy.solar_systems: []

            const planetIndex = galaxy?.solar_systems?.[0]?.planets?.findIndex((x) => x.name === copy[index].filter_property.axis);
            let optionList = galaxy?.solar_systems?.map((x) => x.planets?.[planetIndex]?.value);
            //removing redundant elements
            optionList = [...new Set(optionList)];
            return (
                <Fragment>
                    <Grid container paddingY={'5px'}>
                        <Autocomplete
                            autoHighlight
                            options={optionList}
                            fullWidth
                            getOptionLabel={(option) => option}
                            renderOption={(props, option) => <li {...props}>{option}</li>}
                            renderInput={(params) => (
                                <TextField {...params} label={capitalize('Equal to:')} inputProps={{ ...params.inputProps }} />
                            )}
                            onChange={(e, newValue) => {
                                if (index !== -1) copy[index].filter_option = { ...copy?.[index]?.filter_option, equal_to_text: newValue };
                                else
                                    copy.push({
                                        filter_property: { galaxy: galaxyName, axis: null },
                                        filter_option: { equal_to_text: newValue },
                                    });

                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                            size={'small'}
                            value={localPreferences?.filterPreference?.[index]?.filter_option?.equal_to_text ?? ''}
                        />
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <TextField
                            required
                            placeholder={'Starts with: '}
                            size={'small'}
                            type="text"
                            value={safeValue(copy, index, 'starts_with_text')}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].filter_option = {
                                        ...copy[index].filter_option,
                                        starts_with_text: e.target.value,
                                    };
                                } else {
                                    copy.push({
                                        filter_property: {
                                            galaxy: galaxyName,
                                            axis: null,
                                        },
                                        filter_option: {
                                            starts_with_text: e.target.value,
                                        },
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                        />
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <TextField
                            required
                            placeholder={'Contains: '}
                            size={'small'}
                            type="text"
                            value={safeValue(copy, index, 'contains_text')}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].filter_option = {
                                        ...copy[index].filter_option,
                                        contains_text: e.target.value,
                                    };
                                } else {
                                    copy.push({
                                        filter_property: {
                                            galaxy: galaxyName,
                                            axis: null,
                                        },
                                        filter_option: {
                                            contains_text: e.target.value,
                                        },
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_FITLER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                        />
                    </Grid>
                </Fragment>
            );
        } else if (dataType === 'number') {
            return (
                <Fragment>
                    <Grid container paddingY={'5px'}>
                        <TextField
                            required
                            placeholder={'Equal to: '}
                            size={'small'}
                            type="number"
                            value={safeValue(copy, index, 'equal_to_number')}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].filter_option = {
                                        ...copy[index].filter_option,
                                        equal_to_number: e.target.value,
                                    };
                                } else {
                                    copy.push({
                                        filter_property: {
                                            galaxy: galaxyName,
                                            axis: null,
                                        },
                                        filter_option: {
                                            equal_to_number: e.target.value,
                                        },
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                        />
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <TextField
                            required
                            placeholder={'More than: '}
                            size={'small'}
                            type="number"
                            value={safeValue(copy, index, 'more_than_number')}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].filter_option = {
                                        ...copy[index].filter_option,
                                        more_than_number: e.target.value,
                                    };
                                } else {
                                    copy.push({
                                        filter_property: {
                                            galaxy: galaxyName,
                                            axis: null,
                                        },
                                        filter_option: {
                                            more_than_number: e.target.value,
                                        },
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                        />
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <TextField
                            required
                            placeholder={'Less than: '}
                            size={'small'}
                            type="number"
                            value={safeValue(copy, index, 'less_than_number')}
                            onChange={(e) => {
                                if (index !== -1) {
                                    copy[index].filter_option = {
                                        ...copy[index].filter_option,
                                        less_than_number: e.target.value,
                                    };
                                } else {
                                    copy.push({
                                        filter_property: {
                                            galaxy: galaxyName,
                                            axis: null,
                                        },
                                        filter_option: {
                                            less_than_number: e.target.value,
                                        },
                                    });
                                }
                                //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                setLocalPreferences({ ...localPreferences, filterPreference: copy });
                            }}
                        />
                    </Grid>
                </Fragment>
            );
        } else if (dataType === 'date') {
            let value_equal_to = safeValue(copy, index, 'equal_to_date');
            let value_less_than = safeValue(copy, index, 'less_than_date');
            let value_more_than = safeValue(copy, index, 'more_than_date');
            return (
                <Fragment>
                    <Grid container paddingY={'5px'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat={dateInputFormat()}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        onChange={(e) => {
                                            if (e.target.value.length === 5) {
                                                const monthDay = e.target.value.split('/');
                                                value_equal_to = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                            } else {
                                                value_equal_to = e.target.value;
                                            }
                                        }}
                                        size={'small'}
                                        label={capitalize('Equal to: ')}
                                    />
                                )}
                                onChange={(e) => {
                                    if (e == 'Invalid Date') e = value_equal_to;
                                    if (index !== -1) {
                                        copy[index].filter_option = {
                                            ...copy[index].filter_option,
                                            equal_to_date: e,
                                        };
                                    } else {
                                        copy.push({
                                            filter_property: {
                                                galaxy: galaxyName,
                                                axis: null,
                                            },
                                            filter_option: {
                                                equal_to_date: e,
                                            },
                                        });
                                    }
                                    //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                    setLocalPreferences({ ...localPreferences, filterPreference: copy });
                                }}
                                size={'small'}
                                value={value_equal_to}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat={dateInputFormat()}
                                renderInput={(props) => (
                                    <TextField
                                        size={'small'}
                                        {...props}
                                        onChange={(e) => {
                                            if (e.target.value.length === 5) {
                                                const monthDay = e.target.value.split('/');
                                                value_more_than = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                            } else {
                                                value_more_than = e.target.value;
                                            }
                                        }}
                                        label={capitalize('More than: ')}
                                    />
                                )}
                                value={value_more_than}
                                onChange={(e) => {
                                    if (e == 'Invalid Date') e = value_more_than;
                                    if (index !== -1) {
                                        copy[index].filter_option = {
                                            ...copy[index].filter_option,
                                            more_than_date: e,
                                        };
                                    } else {
                                        copy.push({
                                            filter_property: {
                                                galaxy: galaxyName,
                                                axis: null,
                                            },
                                            filter_option: {
                                                more_than_date: e,
                                            },
                                        });
                                    }
                                    //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                    setLocalPreferences({ ...localPreferences, filterPreference: copy });
                                }}
                                size={'small'}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid container paddingY={'5px'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat={dateInputFormat()}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size={'small'}
                                        onChange={(e) => {
                                            if (e.target.value.length === 5) {
                                                const monthDay = e.target.value.split('/');
                                                value_less_than = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                            } else value_less_than = e.target.value;
                                        }}
                                        label={capitalize('Less than: ')}
                                    />
                                )}
                                value={value_less_than}
                                onChange={(e) => {
                                    if (e == 'Invalid Date') e = value_less_than;
                                    if (index !== -1) {
                                        copy[index].filter_option = {
                                            ...copy[index].filter_option,
                                            less_than_date: e,
                                        };
                                    } else {
                                        copy.push({
                                            filter_property: {
                                                galaxy: galaxyName,
                                                axis: null,
                                            },
                                            filter_option: {
                                                less_than_date: e,
                                            },
                                        });
                                    }
                                    //preferencesDispatch({ type: preferencesActions.SET_FILTER_PREFERENCE, payload: copy });
                                    setLocalPreferences({ ...localPreferences, filterPreference: copy });
                                }}
                                size={'small'}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Fragment>
            );
        }
    }
    function safeValue(copy, index, par) {
        if (index !== -1 && copy[index].filter_option && copy[index].filter_option[par]) {
            return copy[index].filter_option[par];
        }
        return '';
    }

    if (!universeState?.universe) return '';

    return (
        <Grid container padding={'5px'}>
            {props.filterCollapsed && (
                <Grid container item xs={12} md={12} justifyContent={'center'}>
                    <CustomTooltip
                        children={
                            <IconButton onClick={() => props.setFilterCollapsed(false)}>
                                <DensitySmallIcon />
                            </IconButton>
                        }
                        title={capitalize(i18next.t('set_filters'))}
                    />
                </Grid>
            )}
            <Slide direction={'left'} in={!props.filterCollapsed} mountOnEnter unmountOnExit timeout={{ enter: enterTime, exit: exitTime }}>
                <Grid container>
                    <Grid container item justifyContent={'left'} xs={10} md={10}>
                        {['share', 'history'].includes(tab) ? arrowBackButton() : ''}
                        <CustomTooltip title={capitalize(i18next.t('home_page'))}>
                            <IconButton
                                onClick={() => {
                                    tableDispatch({ type: tableActions.RESET, payload: null });
                                    preferencesDispatch({ type: preferencesActions.RESET, payload: null });
                                    universeDispatch({ type: universeActions.RESET, payload: null });
                                    navigate('/select-universe');
                                }}
                            >
                                <HomeIcon />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={capitalize(i18next.t('history'))}>
                            <IconButton onClick={() => navigate('/history/' + universeState?.universe?.id)}>
                                <HistoryIcon />
                            </IconButton>
                        </CustomTooltip>
                        {!(
                            ['read'].includes(universeState?.universe?.user_permission) ||
                            ['write'].includes(universeState?.universe?.user_permission)
                        ) ? (
                            <CustomTooltip title={capitalize(i18next.t('data_structure'))}>
                                <IconButton
                                    className={'data_structure_btn'}
                                    onClick={() => navigate('/edit/project/' + universeState?.universe?.id)}
                                >
                                    <StorageIcon />
                                </IconButton>
                            </CustomTooltip>
                        ) : null}
                        <CustomTooltip title={capitalize(i18next.t('share_universe'))}>
                            <IconButton onClick={() => setTab('share')}>
                                <ShareIcon />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={'Take a tour'}>
                            <IconButton onClick={() => props.setStepsEnabled(!props.stepsEnabled)}>
                                <InfoIcon />
                            </IconButton>
                        </CustomTooltip>
                    </Grid>
                    <Grid item>
                        <CustomTooltip
                            children={
                                <IconButton onClick={() => props.setFilterCollapsed(true)}>
                                    <DensitySmallIcon />
                                </IconButton>
                            }
                            title={capitalize(i18next.t('close'))}
                        />
                    </Grid>

                    {universeState?.universe && localPreferences?.views && tab === 'filters' && (
                        <Grid container item xs={12} md={12} justifyContent={'center'}>
                            {localPreferences?.views?.map((axis, index) => (
                                <Grid item key={axis.id} padding={1}>
                                    <div className={'round-button'}>
                                        <div
                                            className={'round-button-circle pressable'}
                                            onClick={() => {
                                                saveAxisId(axis.id);
                                            }}
                                            style={{ backgroundColor: axis?.active ? 'var(--colorPrimary)' : '#cfdcec' }}
                                        >
                                            <a
                                                className={'round-button'}
                                                style={{ fontSize: '1em', color: axis.active ? 'white' : 'black' }}
                                            >
                                                {index + 1}
                                            </a>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        md={12}
                        xs={12}
                        style={
                            tab === 'history'
                                ? {
                                      height: props.filterHeight - 100 + 'px',
                                      overflowX: 'hidden',
                                      overflowY: 'hidden',
                                  }
                                : {
                                      height: props.filterHeight - 100 + 'px',
                                      overflowX: 'hidden',
                                      overflowY: 'auto',
                                  }
                        }
                    >
                        <div style={{ width: '100%' }}>{tabView()}</div>
                    </Grid>
                </Grid>
            </Slide>
        </Grid>
    );
}
