import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, TextField, Typography } from '@mui/material';
import PasswordChecklist from 'react-password-checklist';

import { CustomToastContainer } from '../CustomComponents';
import axiosInstance from '../../axiosApi';
import { capitalize } from '../../utils';

export default function Registration() {
    const navigate = useNavigate();

    const emailRef = useRef('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const validPwdRef = useRef(false);

    function handleRegistration(e) {
        e.preventDefault();
        if (!validPwdRef.current) {
            toast.error(`${capitalize(i18next.t('passwords_not_valid_yet'))}!`);
            return;
        }

        axiosInstance
            .post('/api/register/', {
                data: { emailRef: emailRef.current, passwordRef: password },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.msg) {
                        toast.warn(i18next.t('email_registered_already'));
                    } else {
                        toast.success(i18next.t('email_sent'));
                    }
                }
            })
            .catch((error) => {
                toast.error(i18next.t('error'));
            });
    }

    return (
        <div className="full-screen fade-in" style={{ overflowY: 'auto' }}>
            <CustomToastContainer />
            <Grid container justifyContent={'center'} padding={'10px'}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid item xs={12} md={12} paddingY={'20px'}>
                        <Typography align={'center'} variant={'h4'}>
                            ALLOOKER
                        </Typography>
                    </Grid>

                    <form onSubmit={(e) => handleRegistration(e)}>
                        <Grid item xs={12} md={12} paddingY={'10px'}>
                            <TextField
                                autoComplete="email"
                                label={'E-mail'}
                                ref={emailRef}
                                required
                                onChange={(e) => (emailRef.current = e.target.value)}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} paddingY={'10px'}>
                            <TextField
                                required
                                label={capitalize(i18next.t('password'))}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} paddingY={'10px'}>
                            <TextField
                                required
                                label={capitalize(i18next.t('password_again'))}
                                onChange={(e) => setPasswordAgain(e.target.value)}
                                type="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid container item justifyContent={'center'} alignItems="center" paddingY={'10px'}>
                            <Grid item md={12} xs={12}>
                                <PasswordChecklist
                                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    maxLength={30}
                                    messages={{
                                        minLength: `${capitalize(i18next.t('password_minLength'))} ${
                                            8 - password > 0 ? '(' + (8 - password) + ')' : '(8)'
                                        }`,
                                        specialChar: capitalize(i18next.t('password_specialChar')),
                                        number: capitalize(i18next.t('password_number')),
                                        capital: capitalize(i18next.t('password_capital')),
                                        lowercase: capitalize(i18next.t('password_lowercase')),
                                        match: capitalize(i18next.t('password_match')),
                                    }}
                                    onChange={(isValid) => (validPwdRef.current = isValid)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'center'} paddingY={'10px'}>
                            <Button fullWidth variant="contained" type="submit">
                                {i18next.t('registration')}
                            </Button>
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'center'} paddingTop={'10px'}>
                            {capitalize(i18next.t('already_have_account'))}
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'center'} paddingBottom={'10px'}>
                            <span className={'pressable'} onClick={() => navigate('/')}>
                                {`${capitalize(i18next.t('login_here'))}!`}
                            </span>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
}
