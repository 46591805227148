import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CustomToastContainer({ timeout = 1500 }) {
    return (
        <ToastContainer
            autoClose={timeout}
            draggable
            newestOnTop={true}
            position={'top-center'}
            theme={'colored'}
            transition={Zoom}
        />
    );
}
