import { createContext, useEffect, useReducer, useRef } from 'react';
import axiosInstance from '../axiosApi';

import { preferencesActions } from './PreferencesContext';
import { universeActions } from './UniverseContext';
import usePreferences from '../hooks/usePreferences';
import useUniverse from '../hooks/useUniverse';
const TableContext = createContext({});

export const tableActions = {
    RESET: 'RESET',
    SET_ACTIVE_SELECTION: 'SET_ACTIVE_SELECTION',
    SET_TABLE: 'SET_TABLE',
    SET_LOADING: 'SET_LOADING',
    SET_PAGE: 'SET_PAGE',
    SET_COLUMN_PAGE: 'SET_COLUMN_PAGE',
};

function reducer(state, action) {
    switch (action.type) {
        case tableActions.RESET:
            return { activeSelection: [], table: [], page: 0, columnPage: 0 };
        case tableActions.SET_ACTIVE_SELECTION:
            return { ...state, activeSelection: action.payload };
        case tableActions.SET_TABLE:
            return { ...state, table: action.payload };
        case tableActions.SET_VIEW_ID:
            return { ...state, viewId: action.payload };
        case tableActions.SET_LOADING:
            return { ...state, loading: action.payload };
        case tableActions.SET_PAGE:
            return { ...state, page: action.payload };
        case tableActions.SET_COLUMN_PAGE:
            return { ...state, columnPage: action.payload };
        default:
            console.error('BAD ACTION TYPE');
    }
}

export function TableProvider({ children }) {
    const { preferencesDispatch, preferencesState } = usePreferences();
    const { universeState, universeDispatch } = useUniverse();

    const [tableState, tableDispatch] = useReducer(reducer, {
        activeSelection: [],
        table: [],
        viewId: null,
        loading: { state: false, text: '' },
        page: 0,
        columnPage: 0,
    });

    const controllerRef = useRef();

    useEffect(() => {
        controllerRef.current = new AbortController();
        return () => controllerRef.current.abort();
    }, []);

    useEffect(() => {
        if (!universeState?.universe?.galaxies?.length) return;

        processData(universeState?.universe);
    }, [JSON.stringify(universeState?.universe)]);

    useEffect(() => {
        getTable();
    }, [preferencesState.unsafeTableUpdate]);

    async function getTable(universeId = universeState?.universe?.id, clearActiveSelection = true) {
        if (!universeId) return;
        tableDispatch({ type: tableActions.SET_LOADING, payload: { state: true, text: 'Loading' } });
        try {
            const response = await axiosInstance.get('/api/get-table/' + universeId);
            tableDispatch({ type: tableActions.SET_TABLE, payload: response.data.table });
            tableDispatch({ type: tableActions.SET_ACTIVE_VIEW_ID, payload: response.data.active });
            if (clearActiveSelection) {
                tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: [] });
            }
        } catch (error) {
            console.log(error);
        } finally {
            tableDispatch({ type: tableActions.SET_LOADING, payload: { state: false, text: '' } });
        }
    }

    function processData(universeData) {
        let possibleCrossSectionslocal = [];
        for (let i = 0; i < universeData.galaxies.length; i++) {
            const galaxy = universeData.galaxies[i];
            for (let j = 0; j < galaxy.structure.planets.length; j++) {
                const named_item = { galaxy: galaxy.name, axis: galaxy?.structure?.planets?.[j]?.name };
                possibleCrossSectionslocal.push(named_item);
                // if (!galaxy.allocation_galaxy)
            }
        }

        preferencesDispatch({ type: preferencesActions.SET_DIMENSION_ATTRIBUTES, payload: possibleCrossSectionslocal });

        let galaxyOrder = [];
        let allocationGalaxyId;
        for (let i = 0; i < universeData.galaxies.length; i++) {
            if (!universeData.galaxies[i].allocation_galaxy) {
                galaxyOrder.push(universeData.galaxies[i].id);
            } else {
                allocationGalaxyId = universeData.galaxies[i].id;
            }
        }
        galaxyOrder.push(allocationGalaxyId);
        let newGalaxies = [];
        for (let i = 0; i < universeData.galaxies.length; i++) {
            let tmpUniverse = universeData.galaxies.find((x) => x.id === galaxyOrder[i]);
            newGalaxies.push({ ...tmpUniverse });
        }

        universeData = { ...universeData, galaxyOrder: galaxyOrder, galaxies: newGalaxies };

        universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeData });

        if (universeData.default_axis) {
            const activeAxisIndex = universeData.default_axis.findIndex((axis) => axis.active);
            preferencesDispatch({
                type: preferencesActions.SET_VIEWS,
                payload: universeData.default_axis,
            });
            if (activeAxisIndex !== -1) {
                preferencesDispatch({
                    type: preferencesActions.SET_ACTIVE_VIEW_ID,
                    payload: universeData?.default_axis?.[activeAxisIndex]?.id,
                });

                const fontSize = universeData?.default_axis?.[activeAxisIndex]?.font_size ?? 10;
                const solarSystemCardFontSize = universeData?.default_axis?.[activeAxisIndex]?.details_font_size ?? 10;
                const tableCellWidth = universeData?.default_axis?.[activeAxisIndex]?.table_cell_width ?? 300;
                const xAxisValue = JSON.parse(universeData?.default_axis?.[activeAxisIndex]?.x_axis) ?? null;
                const yAxisValue = JSON.parse(universeData?.default_axis?.[activeAxisIndex]?.y_axis) ?? null;

                preferencesDispatch({
                    type: preferencesActions.SET_FILTER_PREFERENCE,
                    payload: universeData.default_axis[activeAxisIndex].filter_preferences,
                });
                preferencesDispatch({ type: preferencesActions.SET_FONT_SIZE, payload: fontSize });
                preferencesDispatch({ type: preferencesActions.SET_SOLAR_SYSTEM_CARD_FONT_SIZE, payload: solarSystemCardFontSize });
                preferencesDispatch({
                    type: preferencesActions.SET_SORT_PREFERENCE,
                    payload: universeData?.default_axis?.[activeAxisIndex]?.sort_preferences,
                });
                preferencesDispatch({ type: preferencesActions.SET_TABLE_CELL_WIDTH, payload: tableCellWidth });
                preferencesDispatch({ type: preferencesActions.SET_X_AXIS, payload: xAxisValue });
                preferencesDispatch({ type: preferencesActions.SET_Y_AXIS, payload: yAxisValue });

                let crossSectionStr = JSON.parse(universeData.default_axis[activeAxisIndex].cross_section);
                const crossSectionValue = crossSectionStr ? crossSectionStr : [];

                preferencesDispatch({ type: preferencesActions.SET_CROSS_SECTION, payload: crossSectionValue });
            } else {
                preferencesDispatch({
                    type: preferencesActions.SET_ACTIVE_VIEW_ID,
                    payload: universeData?.default_axis?.[0]?.id,
                });
            }
        }
    }

    return <TableContext.Provider value={{ tableState, tableDispatch, getTable }}>{children}</TableContext.Provider>;
}

export default TableContext;
