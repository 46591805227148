import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Box, Grid, IconButton, Menu, Slider, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import MultipleEditIcon from '@mui/icons-material/EditLocationAlt';
import StorageIcon from '@mui/icons-material/Storage';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

import axiosInstance from '../../axiosApi';
import CreateSolarSystemDialog from '../CreateSolarSystemDialog/CreateSolarSystemDialog';
import { capitalize } from '../../utils';
import { CustomDeleteDialog, CustomTable, CustomToastContainer, CustomTooltip } from '../CustomComponents';
import FiltersSlide from '../FiltersSlide/FiltersSlide';

import MultipleEditDialog from '../MultipleEditDialog/MultipleEditDialog';
import { preferencesActions } from '../../context/PreferencesContext';
import SolarSystemCard from '../SolarSystemCard/SolarSystemCard';
import usePreferences from '../../hooks/usePreferences';
import useTable from '../../hooks/useTable';
import useUniverse from '../../hooks/useUniverse';
import useAuthentication from '../../hooks/useAuthentication';
import { tableActions } from '../../context/TableContext';
import i18next from 'i18next';
import Loading from '../Loading/Loading';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { universeActions } from '../../context/UniverseContext';

const steps = [
    {
        element: '.getting-started-hint',
        intro: 'Welcome to the Dashboard! Take a tour to learn how to use the application.',
    },
    {
        element: '.data_structure_btn',
        intro: 'Click here to edit the data structure of your project.',
        position: 'left',
    },
    {
        element: '.add_new_element_x_btn',
        intro: 'Click here to add a new element to the columns.',
    },
    {
        element: '.add_new_element_y_btn',
        intro: 'Click here to add a new element to the rows.',
    },
];

export default function Dashboard() {
    const { preferencesState, preferencesDispatch, saveDefaultAxis } = usePreferences();
    const { tableState, tableDispatch, getTable } = useTable();
    const { getUniverse, universeState, universeDispatch } = useUniverse();
    const { user } = useAuthentication();
    const { t } = useTranslation();

    const controllerRef = useRef();

    const navigate = useNavigate();
    const { universeId } = useParams();
    const [copiedSystem, setCopiedSystem] = useState(false);
    const [deleteSolarSystemsState, setDeleteSolarSystemsState] = useState(false);
    const [footerCollapsed, setFooterCollapsed] = useState(true);
    const [filterCollapsed, setFilterCollapsed] = useState(false);

    const [multipleEdit, setMultipleEdit] = useState(false);
    const [newSolarType, setNewSolarType] = useState('');
    const [overlay, setOverlay] = useState(false);
    const [selectAnchorState, setSelectAnchorState] = useState(null);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [fontSizeSliderValue, setFontSizeSliderValue] = useState(10);

    const filterSize = filterCollapsed ? 5 : 20;
    const footerSize = footerCollapsed ? 40 : window.innerHeight * 0.25;

    useEffect(() => {
        getUniverse(universeId);
        getTable(universeId);
        controllerRef.current = new AbortController();
        return () => controllerRef.current.abort();
    }, []);

    useEffect(() => {
        setFontSizeSliderValue(preferencesState.solarSystemCardFontSize);
    }, [preferencesState.solarSystemCardFontSize]);

    // useEffect(() => {
    //     console.log(universeState);
    //     if (!universeState?.universe?.id) {
    //         getUniverse(universeId);
    //         getTable(universeId);
    //     }
    // }, [JSON.stringify(universeState?.universe), JSON.stringify(preferencesState)]);

    useEffect(() => {
        if (!universeState?.universe?.id) {
            getUniverse(universeId);
            getTable(universeId);
        } else {
            if (!preferencesState?.xAxis || !preferencesState?.yAxis) setFilterCollapsed(false);
        }
    }, [
        JSON.stringify(preferencesState?.crossSection),
        JSON.stringify(preferencesState?.xAxis),
        JSON.stringify(preferencesState?.yAxis),
        JSON.stringify(universeState?.universe),
    ]);

    useEffect(() => {
        if (!tableState?.activeSelection?.length) setFooterCollapsed(true);
    }, [JSON.stringify(tableState?.activeSelection)]);

    async function deleteAllocations() {
        try {
            const response = await axiosInstance.post(
                '/api/delete-allocations/' + universeState?.universe?.id,
                { ids: tableState.activeSelection },
                { signal: controllerRef.current?.signal }
            );
            if (response?.status === 200) {
                tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: [] });
                getTable(universeId);
                getUniverse(universeId);
                toast.success(capitalize(i18next.t('allocations_deleted_successfully')));
            }
        } catch (error) {
            console.log(error);
            toast.error('error');
        }
    }

    function deleteFromActiveSelection(id) {
        let copy = structuredClone(tableState?.activeSelection);
        copy = copy.filter((x) => x.id !== id);
        tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: copy });
    }

    function getGalaxyName(crossSection) {
        if (Array.isArray(crossSection)) {
            const nmb = crossSection.filter((x) => x.allocation_galaxy);

            //Aside from an allocation galaxy, there is a normal galaxy selected in the cross section
            //return the name of the normal galaxy in this case
            if (nmb.length < crossSection.length) return crossSection.find((x) => !x.allocation_galaxy).galaxy;
            if (crossSection[0]) return crossSection[0].galaxy;
            return null;
        }
        return crossSection.galaxy;
    }

    function shownArrow() {
        const arrowProps = {
            className: 'pressable',
            onClick: () => setFooterCollapsed((current) => !current),
            sx: { fontSize: 'xx-large' },
        };

        return footerCollapsed ? <KeyboardArrowUp {...arrowProps} /> : <KeyboardArrowDown {...arrowProps} />;
    }

    function solarSystemCard(index) {
        if (!universeState?.universe) return '';

        return (
            <SolarSystemCard
                addFunction={() => {
                    setOverlay(true);
                    setNewSolarType(index);
                }}
                deleteFromActiveSelection={deleteFromActiveSelection}
                footerCollapsed={footerCollapsed}
                getGalaxyName={getGalaxyName}
                index={index}
                setCopiedSystem={setCopiedSystem}
                setNewSolarType={setNewSolarType}
                setOverlay={setOverlay}
            />
        );
    }

    function allocationSomewhere() {
        const allocationGalaxy = universeState?.universe?.galaxies.find((x) => x.allocation_galaxy);
        if (preferencesState?.xAxis?.galaxy === allocationGalaxy.name) return true;
        if (preferencesState?.yAxis?.galaxy === allocationGalaxy.name) return true;
        for (let i = 0; i < preferencesState?.crossSection.length; i++) {
            if (preferencesState?.crossSection[i].galaxy === allocationGalaxy.name) return true;
        }
        return false;
    }

    async function crateAllocations(universeId = universeState.universe.id) {
        if (!universeId) return;
        universeDispatch({ type: universeActions.SET_LOADING, payload: { state: true, text: 'Loading' } });
        try {
            const response = await axiosInstance.post('/api/create-test-allocations/' + universeId);
            if (response.status === 201) {
                getTable();
                getUniverse();
            }
        } catch (error) {
            console.log(error);
        } finally {
            universeDispatch({ type: universeActions.SET_LOADING, payload: { state: false, text: '' } });
        }
    }

    return (
        <Fragment>
            <CustomToastContainer />
            <CustomDeleteDialog
                openState={deleteSolarSystemsState}
                setOpenState={setDeleteSolarSystemsState}
                question={t('delete_allocations_question')}
                deleteFunction={deleteAllocations}
            />
            {!tableState?.loading?.state && <Loading loading={universeState?.loading?.state} text={universeState?.loading?.text} />}
            <Loading loading={tableState?.loading?.state} text={tableState?.loading?.text} />
            <Steps enabled={stepsEnabled} steps={steps} initialStep={0} onExit={() => setStepsEnabled(false)} />

            {universeState?.universe && (
                <Fragment>
                    <CreateSolarSystemDialog
                        newSolarType={newSolarType}
                        setNewSolarType={setNewSolarType}
                        overlay={overlay ? true : false}
                        setOverlay={setOverlay}
                        xAxisIndex={typeof overlay === 'string' ? Number(overlay.split('-')[1]) : null}
                        yAxisIndex={typeof overlay === 'string' ? Number(overlay.split('-')[0]) : null}
                        copiedSystem={copiedSystem}
                    />
                    <MultipleEditDialog
                        multipleEdit={multipleEdit}
                        setMultipleEdit={setMultipleEdit}
                        copiedSystem={copiedSystem}
                        getTable={getTable}
                    />
                </Fragment>
            )}

            <Grid container spacing={0}>
                <Grid container sx={{ height: `${window.innerHeight - footerSize}px` }}>
                    <Box sx={{ height: '100%', width: `${100 - filterSize}%` }}>
                        <CustomTable
                            footerCollapsed={footerCollapsed}
                            setFooterCollapsed={setFooterCollapsed}
                            setNewSolarType={setNewSolarType}
                            setOverlay={setOverlay}
                        />
                        {/*<Fragment>
                            {universeState?.universe && !preferencesState?.crossSection.length && (
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid container>
                                        <Grid container item justifyContent={'center'}>
                                            <FolderOffIcon style={{ fontSize: '50px' }} />
                                        </Grid>
                                        <Grid container item justifyContent={'center'}>
                                            <Typography>{t('select_row_column')}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            <Grid container justifyContent={'center'} paddingY="10px">
                                {checkEmptyUniverse() && (
                                    <Grid container alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant={'h6'}>{t('edit_datastructure_offer')}</Typography>
                                        <CustomTooltip title={capitalize(t('data_structure'))}>
                                            <IconButton onClick={() => navigate('/edit/project/' + universeState?.universe?.id)}>
                                                <StorageIcon />
                                            </IconButton>
                                        </CustomTooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Fragment>*/}
                    </Box>
                    <Box sx={{ overflowY: 'auto', width: `${filterSize}%` }}>
                        <FiltersSlide
                            deleteFromActiveSelection={deleteFromActiveSelection}
                            filterCollapsed={filterCollapsed}
                            filterHeight={window.innerHeight - footerSize}
                            getGalaxyName={getGalaxyName}
                            setStepsEnabled={setStepsEnabled}
                            stepsEnabled={stepsEnabled}
                            setFilterCollapsed={setFilterCollapsed}
                        />
                    </Box>
                </Grid>
                <Grid container padding={'5px'} sx={{ bgcolor: 'primary.main', height: `${footerSize}px` }}>
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid container item xs={11} md={11} spacing={1}>
                                <Grid container item xs={7} spacing={1}>
                                    <Grid item xs={4}>
                                        {solarSystemCard(0)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {solarSystemCard(1)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {solarSystemCard(2)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    {solarSystemCard(3)}
                                </Grid>
                            </Grid>

                            <Grid item xs={1}>
                                {!footerCollapsed && (
                                    <Fragment>
                                        <Menu
                                            open={Boolean(selectAnchorState)}
                                            anchorEl={selectAnchorState}
                                            onClose={() => setSelectAnchorState(null)}
                                        >
                                            <Grid container item>
                                                <Grid container item justifyContent={'center'}>
                                                    <Typography>{capitalize(t('details_font_size'))}</Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    justifyContent={'center'}
                                                    sx={{ height: '150px', paddingY: '25px', paddingX: '10px' }}
                                                >
                                                    <Slider
                                                        value={preferencesState?.solarSystemCardFontSize}
                                                        max={40}
                                                        min={5}
                                                        onChange={(e, value) => {
                                                            setFontSizeSliderValue(value);
                                                        }}
                                                        onChangeCommitted={(e, value) => {
                                                            preferencesDispatch({
                                                                type: preferencesActions.SET_SOLAR_SYSTEM_CARD_FONT_SIZE,
                                                                payload: value,
                                                            });
                                                            console.log(value);
                                                        }}
                                                        orientation={'vertical'}
                                                        valueLabelDisplay="auto"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Menu>
                                        <Grid container item xs={12} justifyContent={'flex-end'}>
                                            <Grid item>
                                                <CustomTooltip
                                                    children={
                                                        <IconButton onClick={(event) => setSelectAnchorState(event.currentTarget)}>
                                                            <FormatSizeIcon />
                                                        </IconButton>
                                                    }
                                                    title={capitalize(t('details_font_size'))}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            justifyContent={'flex-end'}
                                            xs={12}
                                            className={`white`}
                                            sx={['read'].includes(universeState?.universe?.user_permission) ? { display: 'none' } : {}}
                                        >
                                            <Grid item>
                                                <CustomTooltip
                                                    children={
                                                        <span>
                                                            <IconButton
                                                                disabled={tableState?.activeSelection.length < 2 || !allocationSomewhere()}
                                                                onClick={() => setMultipleEdit(true)}
                                                            >
                                                                <Badge
                                                                    badgeContent={tableState?.activeSelection.length}
                                                                    color={'secondary'}
                                                                    invisible={tableState?.activeSelection.length < 2}
                                                                >
                                                                    <MultipleEditIcon
                                                                        sx={{
                                                                            color:
                                                                                tableState?.activeSelection.length < 2
                                                                                    ? '#bdbdbd'
                                                                                    : undefined,
                                                                        }}
                                                                    />
                                                                </Badge>
                                                            </IconButton>
                                                        </span>
                                                    }
                                                    title={
                                                        !allocationSomewhere()
                                                            ? t('no_allocation_displayed')
                                                            : capitalize(t('editing_multiple_allocations'))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent={'flex-end'}
                                            sx={['read'].includes(universeState?.universe?.user_permission) ? { display: 'none' } : {}}
                                            className={`white`}
                                        >
                                            <Grid item>
                                                <CustomTooltip
                                                    children={
                                                        <span>
                                                            <IconButton
                                                                disabled={tableState?.activeSelection.length < 1 || !allocationSomewhere()}
                                                                onClick={() => setDeleteSolarSystemsState(true)}
                                                            >
                                                                <Badge
                                                                    badgeContent={tableState?.activeSelection.length}
                                                                    color={'secondary'}
                                                                >
                                                                    <DeleteIcon
                                                                        sx={{
                                                                            color:
                                                                                tableState?.activeSelection.length === 0
                                                                                    ? '#bdbdbd'
                                                                                    : undefined,
                                                                        }}
                                                                    />
                                                                </Badge>
                                                            </IconButton>
                                                        </span>
                                                    }
                                                    title={
                                                        !allocationSomewhere()
                                                            ? t('no_allocation_displayed')
                                                            : capitalize(t('delete_selected_allocations'))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        {user.is_superuser && (
                                            <Grid container justifyContent={'flex-end'}>
                                                <CustomTooltip
                                                    children={
                                                        <span>
                                                            <IconButton
                                                                onClick={() => {
                                                                    crateAllocations();
                                                                }}
                                                            >
                                                                <AddCircleIcon
                                                                    sx={{
                                                                        color: '#bdbdbd',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    }
                                                    title={'Create 500 random allocations'}
                                                />
                                            </Grid>
                                        )}
                                    </Fragment>
                                )}
                                <Grid container justifyContent={'flex-end'} className={`white`}>
                                    <Grid item>{shownArrow()}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
