import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Checkbox from '@mui/material/Checkbox';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import axiosInstance from '../../../axiosApi';
import { capitalize, formatDateTime } from '../../../utils';
import CustomTooltip from '../Tooltip/CustomTooltip';
import styles from './CustomTable.module.css';
import { tableActions } from '../../../context/TableContext';
import { universeActions } from '../../../context/UniverseContext';
import usePreferences from '../../../hooks/usePreferences';
import useTable from '../../../hooks/useTable';
import useUniverse from '../../../hooks/useUniverse';
import i18next from 'i18next';

const rowsPerPageOptions = [10, 20, 50];
const columnShown = 20;

export default function CustomTable({ footerCollapsed, setFooterCollapsed, setNewSolarType, setOverlay }) {
    const theme = useTheme();

    const { t } = useTranslation();

    const { preferencesState } = usePreferences();
    const { tableState, tableDispatch } = useTable();
    const { universeState, universeDispatch } = useUniverse();

    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);

    const controllerRef = useRef();

    useEffect(() => {
        controllerRef.current = new AbortController();
        return () => controllerRef.current.abort();
    }, []);

    function handleChangeRowsPerPage(event) {
        tableDispatch({ type: tableActions.SET_PAGE, payload: 0 });
        setRowsPerPage(event.target.value);
    }

    function handleClick(draggableId) {
        let index = tableState.activeSelection.findIndex((x) => x === draggableId);
        let newArray = structuredClone(tableState.activeSelection);
        if (index !== -1) newArray.splice(index, 1);
        else newArray.push(draggableId);
        if (newArray.length === 1 && footerCollapsed) setFooterCollapsed(false);
        tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: newArray });
    }
    const allocationGalaxy = universeState?.universe?.galaxies?.find((x) => x.allocation_galaxy);
    function allocationOnAxis() {
        if (preferencesState?.xAxis?.galaxy === allocationGalaxy?.name || preferencesState?.yAxis?.galaxy === allocationGalaxy?.name)
            return true;
        return false;
    }

    function findThirdSolarSystem(connection, a, b) {
        console.log(connection, a, b);
        if (connection.a.id === a && connection.b.id === b) return connection.c.id;
        if (connection.b.id === a && connection.a.id === b) return connection.c.id;
        if (connection.a.id === a && connection.c.id === b) return connection.b.id;
        if (connection.c.id === a && connection.a.id === b) return connection.b.id;
        if (connection.c.id === a && connection.b.id === b) return connection.a.id;
        if (connection.b.id === a && connection.c.id === b) return connection.a.id;
    }

    async function onDragEnd(event) {
        let tableTemp = structuredClone(tableState?.table);
        let universeTemp = structuredClone(universeState?.universe);
        const allocationId = Number(event.draggableId);
        const sourceCell = event.source.droppableId.split('-');
        const destinationCell = event.destination.droppableId.split('-');

        let sourceItemValue = tableTemp[Number(sourceCell[0])][Number(sourceCell[1])];
        let destinationItemValue = tableTemp[Number(destinationCell[0])][Number(destinationCell[1])];
        const replaceableIndex = sourceItemValue.findIndex((x) => x.allocation_id === allocationId);

        destinationItemValue.push(sourceItemValue[replaceableIndex]);
        sourceItemValue.splice(replaceableIndex, 1);

        let allocationGalaxyIndex = universeTemp.galaxies.findIndex((x) => x.allocation_galaxy);
        let allocationIndex = universeTemp.galaxies[allocationGalaxyIndex].solar_systems.findIndex((x) => x.id === allocationId);
        let allocation = universeTemp.galaxies[allocationGalaxyIndex].solar_systems[allocationIndex];
        let data; //for api call
        if (preferencesState?.xAxis.galaxy === universeState?.universe.galaxies[allocationGalaxyIndex].name) {
            if (sourceCell[1] !== destinationCell[1]) {
                toast.warn(i18next.t('no_allocation_dnd'), { autoClose: 3000 });
                return;
            }
            let newId = tableState?.table[destinationCell[0]][0].id;
            let allocationCopy = structuredClone(allocation);
            if (allocationCopy.connections[0].a.galaxy_name === preferencesState?.yAxis.galaxy) {
                allocationCopy.connections[0].a.id = newId;
            } else if (allocationCopy.connections[0].b.galaxy_name === preferencesState?.yAxis.galaxy) {
                allocationCopy.connections[0].b.id = newId;
            } else allocationCopy.connections[0].c.id = newId;

            data = {
                relatives: [allocationCopy.connections[0].a.id, allocationCopy.connections[0].b.id, allocationCopy.connections[0].c.id],
                allocationId: allocationId,
            };
        } else if (preferencesState?.yAxis.galaxy === universeState?.universe.galaxies[allocationGalaxyIndex].name) {
            if (sourceCell[0] !== destinationCell[0]) {
                toast.warn(i18next.t('no_allocation_dnd'), { autoClose: 3000 });
                return;
            }

            let newId = tableState?.table[0][destinationCell[1]].id;
            let allocationCopy = structuredClone(allocation);
            if (allocationCopy.connections[0].a.galaxy_name === preferencesState?.xAxis.galaxy) {
                allocationCopy.connections[0].a.id = newId;
            } else if (allocationCopy.connections[0].b.galaxy_name === preferencesState?.xAxis.galaxy) {
                allocationCopy.connections[0].b.id = newId;
            } else allocationCopy.connections[0].c.id = newId;

            data = {
                relatives: [allocationCopy.connections[0].a.id, allocationCopy.connections[0].b.id, allocationCopy.connections[0].c.id],
                allocationId: allocationId,
            };
        } else {
            const thirdDimensionId = findThirdSolarSystem(
                allocation.connections[0],
                tableState?.table[0][Number(sourceCell[1])]?.id,
                tableState?.table[Number(sourceCell[0])]?.[0].id
            );
            data = {
                relatives: [
                    tableState?.table[0][Number(destinationCell[1])]?.id,
                    tableState?.table[Number(destinationCell[0])]?.[0].id,
                    thirdDimensionId,
                ],
                allocationId: allocationId,
            };
        }

        try {
            const response = await axiosInstance.post('/api/modify-planet-relatives/' + universeState?.universe?.id, data, {
                signal: controllerRef.current?.signal,
            });
            if (response.status === 200) {
                toast.success('Save successful');
                try {
                    universeTemp.galaxies[allocationGalaxyIndex].solar_systems[allocationIndex] = response.data;
                    universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeTemp });
                    tableDispatch({ type: tableActions.SET_TABLE, payload: tableTemp });
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function filterPlanets(solarSystem, place) {
        if (place === 'cells') {
            let planetNames = preferencesState.crossSection.filter((x) => x.galaxy === solarSystem.galaxy_name).map((y) => y.axis);

            let filteredPlanets = [];
            for (let i = 0; i < solarSystem.planets.length; i++) {
                planetNames.map((x) => {
                    if (solarSystem.planets[i].name === x) {
                        filteredPlanets.push(solarSystem.planets[i]);
                    }
                    return null;
                });
            }

            return filteredPlanets.length
                ? filteredPlanets.map((planet, index) => (
                      <Grid container>
                          <Typography sx={{ fontSize: preferencesState?.fontSize }} key={index}>
                              {formatPlanetValue(planet)}
                          </Typography>
                      </Grid>
                  ))
                : null;
        }
    }

    const formatPlanetValue = (planet) => {
        if (planet.data_type === 'date') return formatDateTime(planet.value);
        if (planet.data_type === 'datetime') return formatDateTime(planet.value, true);
        return planet.value;
    };

    const getStatusColor = (allocation) => {
        let allocation_solar = allocationGalaxy?.solar_systems?.find((x) => x?.id === allocation);

        if (allocation_solar && allocation_solar?.planets?.[0]?.data_type === 'status')
            if (allocation_solar?.planets?.[0]?.value === 'to-do') return 'rgba(255, 160, 0, 0.8)';
        if (allocation_solar?.planets?.[0]?.value === 'in progress') return 'rgba(51, 150, 255, 0.8)';
        if (allocation_solar?.planets?.[0]?.value === 'done') return 'rgba(0, 200, 83, 0.8)';

        return 'white';
    };

    if (
        (!preferencesState.xAxis || !preferencesState.yAxis || !preferencesState?.crossSection?.length) &&
        tableState?.loading?.state === false &&
        universeState?.loading?.state === false
    )
        return (
            <Typography variant="h5" paddingY={'20px'} align={'center'}>
                Choose which columns, rows and cells you want to display
            </Typography>
        );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <TableContainer sx={{ height: '100%', overflowY: 'auto' }}>
                <Table padding={'none'} stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableState?.table?.[0]?.map((column, index) => {
                                if (index === 0)
                                    return (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                background: `linear-gradient(to bottom left, #fff 0%, #fff 50%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
                                                left: 0,
                                                minWidth: 200,
                                                position: 'sticky',
                                                zIndex: 4,
                                                border: '1px solid rgba(200,200,200)',
                                            }}
                                        >
                                            <Grid container justifyContent={'flex-end'}>
                                                <Typography
                                                    sx={{ fontSize: preferencesState?.fontSize }}
                                                >{`${preferencesState?.xAxis?.galaxy}: ${preferencesState?.xAxis?.axis}`}</Typography>
                                            </Grid>
                                            {!['read'].includes(universeState?.universe?.user_permission) && (
                                                <Grid container justifyContent={'space-between'}>
                                                    <CustomTooltip
                                                        children={
                                                            <IconButton
                                                                className="add_new_element_y_btn"
                                                                onClick={() => {
                                                                    let index = universeState.universe.galaxies.findIndex(
                                                                        (x) => x.name === preferencesState.yAxis.galaxy
                                                                    );
                                                                    setNewSolarType(index);
                                                                    setOverlay(true);
                                                                }}
                                                            >
                                                                <AddIcon fontSize={'small'} sx={{ color: 'white' }} />
                                                            </IconButton>
                                                        }
                                                        title={
                                                            <>
                                                                {capitalize(t('add_new'))} {preferencesState?.yAxis?.galaxy}
                                                            </>
                                                        }
                                                    />

                                                    <CustomTooltip
                                                        children={
                                                            <IconButton
                                                                className="add_new_element_x_btn"
                                                                onClick={() => {
                                                                    let index = universeState.universe.galaxies.findIndex(
                                                                        (x) => x.name === preferencesState.xAxis.galaxy
                                                                    );
                                                                    setNewSolarType(index);
                                                                    setOverlay(true);
                                                                }}
                                                            >
                                                                <AddIcon fontSize={'small'} sx={{ color: 'gray' }} />
                                                            </IconButton>
                                                        }
                                                        title={
                                                            <>
                                                                {capitalize(t('add_new'))} {preferencesState?.xAxis?.galaxy}
                                                            </>
                                                        }
                                                    />
                                                </Grid>
                                            )}

                                            <Grid container>
                                                <Typography
                                                    sx={{ color: 'white', fontSize: preferencesState?.fontSize }}
                                                >{`${preferencesState?.yAxis?.galaxy}: ${preferencesState?.yAxis?.axis}`}</Typography>
                                            </Grid>
                                        </TableCell>
                                    );
                                if (index < columnShown * tableState.columnPage + 1 || index > columnShown * (tableState.columnPage + 1))
                                    return null;

                                return (
                                    <TableCell
                                        align={'center'}
                                        key={index}
                                        sx={{
                                            minWidth: preferencesState?.tableCellWidth,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                            border: '1px solid rgba(200,200,200)',
                                        }}
                                    >
                                        {/* first data column on the page which is not the first*/}
                                        {index % columnShown === 1 && tableState.columnPage !== 0 && (
                                            <div style={{ position: 'absolute', left: '0' }}>
                                                <CustomTooltip
                                                    children={
                                                        <ArrowBackIcon
                                                            onClick={() => {
                                                                tableDispatch({
                                                                    type: tableActions.SET_COLUMN_PAGE,
                                                                    payload: tableState.columnPage - 1,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    }
                                                    title={capitalize('Previous columns')}
                                                />
                                            </div>
                                        )}
                                        {/*last column on the page and there is more to be loaded */}
                                        {index % columnShown === 0 && index < tableState?.table[0].length - 1 && (
                                            <div style={{ position: 'absolute', right: '0' }}>
                                                <CustomTooltip
                                                    children={
                                                        <ArrowForwardIcon
                                                            onClick={() => {
                                                                tableDispatch({
                                                                    type: tableActions.SET_COLUMN_PAGE,
                                                                    payload: tableState.columnPage + 1,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    }
                                                    title={capitalize('Next columns')}
                                                />
                                            </div>
                                        )}
                                        <Typography sx={{ fontSize: preferencesState?.fontSize }}>
                                            {formatPlanetValue(column.planets[0])}
                                        </Typography>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableState?.table
                            ?.slice(tableState.page * rowsPerPage, tableState.page * rowsPerPage + rowsPerPage + 1)
                            ?.map((row, rowIndex) => {
                                return rowIndex > 0 ? (
                                    <TableRow hover key={rowIndex}>
                                        {row?.map((column, columnIndex) => {
                                            const tableCellId = `${rowIndex + tableState.page * rowsPerPage}-${columnIndex}`;

                                            if (columnIndex === 0)
                                                return (
                                                    <TableCell
                                                        key={columnIndex}
                                                        sx={{
                                                            background: 'white',
                                                            left: 0,
                                                            padding: 1,
                                                            position: 'sticky',
                                                            zIndex: 3,
                                                            border: '1px solid rgba(200,200,200)',
                                                            fontSize: preferencesState?.fontSize,
                                                        }}
                                                    >
                                                        {formatPlanetValue(column.planets[0])}
                                                    </TableCell>
                                                );
                                            if (
                                                columnIndex < columnShown * tableState.columnPage + 1 ||
                                                columnIndex > columnShown * (tableState.columnPage + 1)
                                            )
                                                return null;
                                            return (
                                                <Droppable droppableId={tableCellId} key={tableCellId}>
                                                    {(provided, snapshot) => (
                                                        <TableCell
                                                            {...provided.droppableProps}
                                                            className={styles.tableCell}
                                                            ref={provided.innerRef}
                                                            sx={{
                                                                border: '1px solid rgba(200,200,200)',
                                                                bgcolor: snapshot.isDraggingOver ? 'secondary.main' : '',
                                                            }}
                                                        >
                                                            {!allocationOnAxis() &&
                                                                !['read'].includes(universeState?.universe?.user_permission) && (
                                                                    <Grid container justifyContent={'center'}>
                                                                        <CustomTooltip
                                                                            children={
                                                                                <IconButton
                                                                                    className={styles.addIcon}
                                                                                    onClick={() => {
                                                                                        setNewSolarType(4);
                                                                                        setOverlay(tableCellId);
                                                                                    }}
                                                                                >
                                                                                    <AddIcon fontSize={'small'} />
                                                                                </IconButton>
                                                                            }
                                                                            title={'Add new allocation'}
                                                                        />
                                                                    </Grid>
                                                                )}

                                                            <List disablePadding>
                                                                {column?.map((listElement, index) => {
                                                                    if (!listElement?.content?.length) return null;
                                                                    const allocationId = listElement.allocation_id;

                                                                    const checkboxSize =
                                                                        preferencesState?.fontSize <= 14 ? 'small' : 'medium';
                                                                    const checkedSelected = Boolean(
                                                                        tableState?.activeSelection?.find(
                                                                            (_draggableId) => _draggableId === allocationId
                                                                        )
                                                                    );
                                                                    return (
                                                                        <Draggable
                                                                            draggableId={String(allocationId)}
                                                                            index={index}
                                                                            key={allocationId}
                                                                            isDragDisabled={['read'].includes(
                                                                                universeState?.universe?.user_permission
                                                                            )}
                                                                        >
                                                                            {(provided) => (
                                                                                <ListItem
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    disablePadding
                                                                                    ref={provided.innerRef}
                                                                                >
                                                                                    <ListItemButton
                                                                                        onClick={() => handleClick(allocationId)}
                                                                                        sx={{
                                                                                            background: getStatusColor(allocationId),
                                                                                            borderRadius: '5px',
                                                                                            margin: '2px',
                                                                                            display: 'block',
                                                                                        }}
                                                                                    >
                                                                                        <Grid container alignItems={'center'}>
                                                                                            <Grid item xs={11}>
                                                                                                {listElement.content.map((x, index) => (
                                                                                                    <ListItemText
                                                                                                        key={index}
                                                                                                        primary={filterPlanets(
                                                                                                            listElement.content[index],
                                                                                                            'cells'
                                                                                                        )}
                                                                                                    />
                                                                                                ))}
                                                                                            </Grid>
                                                                                            <Grid item xs={1}>
                                                                                                <Checkbox
                                                                                                    checked={checkedSelected}
                                                                                                    disableRipple
                                                                                                    edge={'end'}
                                                                                                    size={checkboxSize}
                                                                                                    color={'default'}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItemButton>
                                                                                </ListItem>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                            </List>
                                                            {provided.placeholder}
                                                        </TableCell>
                                                    )}
                                                </Droppable>
                                            );
                                        })}
                                    </TableRow>
                                ) : null;
                            })}
                    </TableBody>
                    <TableFooter sx={{ backgroundColor: 'white', bottom: 0, left: 0, position: 'sticky', zIndex: 4 }}>
                        <TableRow>
                            <TablePagination
                                count={tableState?.table?.length - 1}
                                onPageChange={(event, value) => tableDispatch({ type: tableActions.SET_PAGE, payload: value })}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                page={tableState.page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={rowsPerPageOptions}
                                showFirstButton
                                showLastButton
                                sx={{ '& .MuiTablePagination-spacer': { display: 'none' } }}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </DragDropContext>
    );
}
