import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Select } from '@mui/material';
import Flags from 'country-flag-icons/react/3x2';

import { capitalize } from '../../../utils';

export default function LanguageSelect() {
    const { i18n, t } = useTranslation();

    return (
        <Select fullWidth onChange={(e) => i18n.changeLanguage(e.target.value)} value={i18n.language} variant={'outlined'}>
            {/*<MenuItem value={'hu'}>
                <Grid container alignItems={'center'}>
                    <Flags.HU
                        title="Hungarian"
                        style={{ height: '20px', width: 'auto', display: 'inline-block', marginRight: '10px' }}
                    />
                    {capitalize(i18next.t('hungarian'))}
                </Grid>
            </MenuItem>*/}
            <MenuItem value={'en'}>
                <Grid container alignItems={'center'}>
                    <Flags.GB title={'English'} style={{ height: '20px', width: 'auto', display: 'inline-block', marginRight: '10px' }} />
                    {capitalize(t('english'))}
                </Grid>
            </MenuItem>
        </Select>
    );
}
