import React, { useRef, useState, useEffect } from 'react';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import {
    Button,
    Divider,
    FormControl,
    getTableBodyUtilityClass,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import axiosInstance from '../../axiosApi';
import { CustomDeleteDialog, CustomTooltip, CustomDatePicker } from '../CustomComponents';
import { capitalize, formatDateTime, dateInputFormat, thisYear } from '../../utils';
import styles from './SolarSystemCard.module.css';
import { tableActions } from '../../context/TableContext';
import { universeActions } from '../../context/UniverseContext';
import useTable from '../../hooks/useTable';
import useUniverse from '../../hooks/useUniverse';
import usePreferences from '../../hooks/usePreferences';

let formRef = null;

export default function SolarSystemCard(props) {
    const { tableState, tableDispatch, getTable } = useTable();
    const { getUniverse, universeState, universeDispatch } = useUniverse();
    const { preferencesState } = usePreferences();

    const [solarSystemState, setsolarSystemState] = useState();
    const [beingModified, setBeingModified] = useState(false);
    const [deleteSolarSystemState, setDeleteSolarSystemState] = useState(false);
    const [search, setSearch] = useState({ state: false, value: '' });
    const [tab, setTab] = useState('planet');
    const [galaxyName, setGalaxyName] = useState('');

    const [deleteQuestion, setDeleteQuestion] = useState(i18next.t('delete_allocation_question'));
    const [deleteDescription, setDeleteDescription] = useState(undefined);
    const [selectAnchorState, setSelectAnchorState] = useState(null);

    const solarSystemHistoryRef = useRef(null);
    const manualHistoryInputRef = useRef('');

    useEffect(() => {
        if (tableState?.activeSelection.length) {
            if (!beingModified) {
                const last = tableState?.activeSelection[tableState?.activeSelection.length - 1];
                const allocationGalaxy = universeState?.universe.galaxies.find((x) => x.allocation_galaxy);
                const allocation = allocationGalaxy.solar_systems.find((x) => x.id === last);
                if (allocation) {
                    const connection = allocation.connections[0];

                    /// 3 is allocation dimension
                    switch (props.index) {
                        case 0:
                        case 1:
                        case 2:
                            let solarsystem = getSolarSystemByConnection(universeState?.universe.galaxies[props.index], connection);
                            setsolarSystemState(structuredClone(solarsystem));
                            getSolarSystemHistory(solarsystem?.id);
                            break;
                        case 3:
                            setsolarSystemState(structuredClone(allocation));
                            getSolarSystemHistory(allocation?.id);
                            break;
                        default:
                            throw new Error('SolarSystemCard index error, please provide a valid index');
                    }
                    setGalaxyName(universeState?.universe.galaxies[props.index].name);
                }
            }
        } else {
            setsolarSystemState(null);
            setTab('planet');
        }
    }, [JSON.stringify(tableState?.activeSelection), JSON.stringify(universeState?.universe), beingModified]);

    function tabView() {
        switch (tab) {
            case 'planet':
                return planetView();
            case 'connection':
                return connectionView();
            case 'history':
                return historyView();
            case 'addHistory':
                return addHistoryView();
        }
    }

    function getSolarSystemByConnection(galaxy, connection) {
        for (let i = 0; i < galaxy.solar_systems.length; i++) {
            if (
                galaxy.solar_systems[i].id === connection.a.id ||
                galaxy.solar_systems[i].id === connection.b.id ||
                galaxy.solar_systems[i].id === connection.c.id
            ) {
                return galaxy.solar_systems[i];
            }
        }
        return null;
    }

    function connectionView() {
        return (
            <Grid container>
                <Grid container item xs={12} md={12} justifyContent={'center'} alignItems={'center'}>
                    <Typography fontWeight={'bold'}>{capitalize(i18next.t('connections'))}</Typography>
                </Grid>
                <Grid container item xs={12} md={12} justifyContent={'space-between'} paddingTop={'10px'} alignItems={'center'}>
                    {connectionField(0)}
                </Grid>
                <Grid container item xs={12} md={12} justifyContent={'space-between'} paddingTop={'10px'} alignItems={'center'}>
                    {connectionField(1)}
                </Grid>
                <Grid container item xs={12} md={12} justifyContent={'space-between'} paddingTop={'10px'} alignItems={'center'}>
                    {connectionField(2)}
                </Grid>
            </Grid>
        );
    }

    function planetView() {
        if (!solarSystemState)
            return (
                <React.Fragment>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Typography fontWeight={'bold'}>{capitalize(i18next.t('no_allocations'))}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <CustomTooltip
                            children={
                                <AddIcon
                                    onClick={() => {
                                        props.setNewSolarType(3);
                                        props.setCopiedSystem(false);
                                        props.setOverlay(true);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            title={capitalize(i18next.t('create_new_allocation'))}
                        />
                    </Grid>
                </React.Fragment>
            );
        return (
            <React.Fragment>
                <Grid container item xs={12} md={12} justifyContent={'center'} alignItems={'center'}>
                    <Typography fontWeight={'bold'}>
                        {!beingModified ? capitalize(i18next.t('details')) : capitalize(i18next.t('modify'))}
                    </Typography>
                </Grid>
                {solarSystemState.planets.map((planet, index) => (
                    <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        justifyContent={'space-between'}
                        className="pt-10"
                        alignItems={'center'}
                        key={planet.id}
                        fontSize={preferencesState.solarSystemCardFontSize + 'pt'}
                    >
                        {valueField(planet)}
                    </Grid>
                ))}
            </React.Fragment>
        );
    }

    function updateUniverse(data) {
        let universeCopy = structuredClone(universeState.universe);
        let galaxyIndex = universeCopy.galaxies.findIndex((x) => x.name === data.galaxy_name);
        if (galaxyIndex !== -1) {
            let solarIndex = universeCopy.galaxies[galaxyIndex].solar_systems.findIndex((x) => x.id === data.id);
            if (solarIndex !== -1) {
                universeCopy.galaxies[galaxyIndex].solar_systems[solarIndex] = data;
                universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeCopy });
            } else getUniverse();
        } else getUniverse();
    }

    function handleSave(e = undefined) {
        if (e !== undefined) e.preventDefault();

        if (props.index !== 3) {
            let old_system = { ...solarSystemState };

            let solarSystemPost = { ...solarSystemState };
            let galaxy_name = solarSystemPost.galaxy_name;
            axiosInstance
                .post(`/api/modify-solar-system/${universeState?.universe.id}`, {
                    solarSystem: solarSystemPost,
                    oldSolarSystem: old_system.name,
                })
                .then((response) => {
                    if (response.status === 200) {
                        updateUniverse(response.data);
                        setBeingModified(false);
                        getTable(universeState?.universe.id, false);
                        toast.success(capitalize(i18next.t('save_successful')));
                    }
                    if (response.status === 208) {
                        toast.warn(capitalize(galaxy_name + ' ' + i18next.t('name_already_used')));
                    }
                })
                .catch((error) => {
                    toast.error(
                        <React.Fragment>
                            <Typography align={'center'}>{`${capitalize(i18next.t('error'))}!`}</Typography>
                            <Typography align={'center'}>{`${capitalize(i18next.t('error_code'))} - ${error.response.status}`}</Typography>
                        </React.Fragment>
                    );
                });
        } else {
            axiosInstance
                .post(`/api/modify-allocation/${universeState?.universe.id}`, {
                    allocation: solarSystemState,
                })
                .then((response) => {
                    if (response.status === 200) {
                        setBeingModified(false);
                        updateUniverse(response.data);
                        getTable(universeState?.universe.id, false);
                        toast.success(capitalize(i18next.t('save_successful')));
                    }
                })
                .catch((error) => {
                    toast.error(
                        <React.Fragment>
                            <Typography align={'center'}>{`${capitalize(i18next.t('error'))}!`}</Typography>
                            <Typography align={'center'}>{`${capitalize(i18next.t('error_code'))} - ${
                                error?.response?.status
                            }`}</Typography>
                        </React.Fragment>
                    );
                });
        }
    }

    function deleteSolarSystem() {
        axiosInstance
            .post('/api/delete-solar-system/' + universeState?.universe.id, {
                solar_system_id: solarSystemState.id,
            })
            .then((response) => {
                if (response.status === 200) {
                    tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: [] });
                    getTable();
                    getUniverse();
                }
            });
    }

    function handleClick(e) {
        // if (e.detail === 2) {
        if (!['read'].includes(universeState?.universe.user_permission)) setBeingModified(true);
        // }
    }

    function getOpacity(selected) {
        if (selected) return 1;
        return 0.2;
    }

    function handleStatusClick(planet, newValue) {
        if (!['read'].includes(universeState?.universe.user_permission)) {
            let copy = { ...solarSystemState };
            const index = copy.planets.findIndex((x) => x.id === planet.id);
            let copyPlanet = { ...copy.planets[index] };
            copyPlanet.value = newValue;
            copy.planets[index] = copyPlanet;
            setsolarSystemState(copy);
            if (!beingModified) setBeingModified(true);
        }
    }

    function statusValueField(planet) {
        let todoOpacity = getOpacity(planet.value === 'to-do');
        let inProgressOpacity = getOpacity(planet.value === 'in progress');
        let doneOpacity = getOpacity(planet.value === 'done');
        return (
            <React.Fragment>
                <b>{capitalize(planet.name)}</b>{' '}
                <Grid container justifyContent={'flex-end'} spacing={1}>
                    <Grid item style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <CustomTooltip
                            children={
                                <span
                                    onClick={() => handleStatusClick(planet, 'to-do')}
                                    style={{
                                        background: `rgba(255, 160, 0, ${todoOpacity})`,
                                        borderRadius: '50%',
                                        width: '20px',
                                        height: '20px',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                ></span>
                            }
                            title={<React.Fragment>{i18next.t('todo')}</React.Fragment>}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <CustomTooltip
                            children={
                                <span
                                    onClick={() => handleStatusClick(planet, 'in progress')}
                                    style={{
                                        background: `rgba(51, 150, 255, ${inProgressOpacity})`,
                                        borderRadius: '50%',
                                        width: '20px',
                                        height: '20px',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                ></span>
                            }
                            title={<React.Fragment>{i18next.t('in_progress')}</React.Fragment>}
                        />
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <CustomTooltip
                            children={
                                <span
                                    onClick={() => handleStatusClick(planet, 'done')}
                                    style={{
                                        background: `rgba(0, 200, 83, ${doneOpacity})`,
                                        borderRadius: '50%',
                                        width: '20px',
                                        height: '20px',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                    }}
                                ></span>
                            }
                            title={<React.Fragment>{i18next.t('done')}</React.Fragment>}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    function valueField(planet) {
        if (!beingModified) {
            if (planet.data_type === 'date')
                return (
                    <React.Fragment>
                        <b>{capitalize(planet.name)}</b>{' '}
                        <span style={{ cursor: 'pointer' }} onClick={handleClick}>
                            {formatDateTime(planet.value) ?? '-'}
                        </span>
                    </React.Fragment>
                );
            if (planet.data_type === 'datetime')
                return (
                    <React.Fragment>
                        <b>{capitalize(planet.name)}</b>{' '}
                        <span style={{ cursor: 'pointer' }} onClick={handleClick}>
                            {formatDateTime(planet.value, true) ?? '-'}
                        </span>
                    </React.Fragment>
                );

            if (planet.data_type === 'status') {
                return statusValueField(planet);
            }
            return (
                <React.Fragment>
                    <b>{capitalize(planet.name)}</b>{' '}
                    <span style={{ cursor: 'pointer' }} onClick={handleClick}>
                        {planet.value ? planet.value : ''}
                    </span>
                </React.Fragment>
            );
        }
        let value = solarSystemState.planets.find((x) => x.id === planet.id).value;

        if (['text', 'number'].includes(planet.data_type))
            return (
                <TextField
                    required={planet.is_dimension}
                    type={planet.data_type}
                    value={value ?? ''}
                    label={capitalize(planet.name)}
                    onChange={(e) => {
                        let copy = { ...solarSystemState };
                        const index = copy.planets.findIndex((x) => x.id === planet.id);
                        let copyPlanet = { ...copy.planets[index] };
                        copyPlanet.value = e.target.value;
                        copy.planets[index] = copyPlanet;
                        setsolarSystemState(copy);
                    }}
                />
            );
        else if (planet.data_type === 'date') {
            return (
                <CustomDatePicker
                    planet={planet}
                    propRenderInput={(props) => (
                        <TextField
                            required={planet.is_dimension}
                            {...props}
                            onChange={(e) => {
                                if (e.target.value.length === 5) {
                                    const monthDay = e.target.value.split('/');
                                    value = new Date(`${thisYear()}-${monthDay[1]}-${monthDay[0]}`);
                                } else {
                                    value = e.target.value;
                                }
                            }}
                        />
                    )}
                    propValue={value}
                    onChange={(e) => {
                        if (e == 'Invalid Date') {
                            e = value;
                        }
                        let copy = { ...solarSystemState };
                        const index = copy.planets.findIndex((x) => x.id === planet.id);
                        let copyPlanet = { ...copy.planets[index] };
                        copyPlanet.value = e;
                        copy.planets[index] = copyPlanet;
                        setsolarSystemState(copy);
                    }}
                />
            );
        } else if (planet.data_type === 'datetime')
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        inputFormat={dateInputFormat(true)}
                        renderInput={(props) => <TextField required={planet.is_dimension} {...props} label={capitalize(planet.name)} />}
                        value={value}
                        onChange={(e) => {
                            let copy = { ...solarSystemState };
                            const index = copy.planets.findIndex((x) => x.id === planet.id);
                            let copyPlanet = { ...copy.planets[index] };
                            copyPlanet.value = e;
                            copy.planets[index] = copyPlanet;
                            setsolarSystemState(copy);
                        }}
                    />
                </LocalizationProvider>
            );
        else if (planet.data_type === 'status') return statusValueField(planet);
    }

    function saveConnectionChange(solarSystem) {
        axiosInstance
            .post('/api/modify-planet-relatives/' + universeState?.universe.id, {
                relatives: [solarSystem.connections[0].a.id, solarSystem.connections[0].b.id, solarSystem.connections[0].c.id],
                allocationId: solarSystem.id,
            })
            .then((response) => {
                if (response.status === 200) {
                    let universeCopy = structuredClone(universeState.universe);
                    let allocationGalaxyIndex = universeCopy.galaxies.findIndex((x) => x.allocation_galaxy);
                    let allocationIndex = universeCopy.galaxies[allocationGalaxyIndex].solar_systems.findIndex(
                        (x) => x.id === solarSystem.id
                    );
                    universeCopy.galaxies[allocationGalaxyIndex].solar_systems[allocationIndex] = response.data;
                    universeDispatch({ type: universeActions.SET_UNIVERSE, payload: universeCopy });
                    getTable();
                    toast.success('Save successful');
                }
            })
            .catch((error) => {
                toast.error('error');
            });
    }

    function connectionField(index) {
        //let key = String.fromCharCode(97 + index);
        let copy = structuredClone(solarSystemState);
        const galaxy = universeState?.universe.galaxies[index];

        let solarID = Object.values(solarSystemState.connections[0]).find((x) => x.galaxy_name === galaxy.name)?.id;
        let key = Object.keys(copy.connections[0]).find((key) => copy.connections[0][key].id === solarID);

        let localSolarSystem = galaxy.solar_systems.find((x) => x.id === solarSystemState.connections[0][key].id);
        return (
            <Grid container>
                <FormControl fullWidth required>
                    <InputLabel id="connection_a">{capitalize(galaxy.name)}</InputLabel>{' '}
                    <Select
                        label={capitalize(galaxy.name)}
                        onChange={(e) => {
                            // const index = copy.connections.findIndex((x) => x.id === connection.id);
                            // let copyConnection = { ...copy.connections[index] };
                            // copyConnection.a = e.target.value;
                            // copy.connections[index] = copyConnection;
                            //a == 97

                            copy.connections[0][key] = e.target.value;
                            setsolarSystemState(copy);
                            saveConnectionChange(copy);
                        }}
                        value={localSolarSystem ?? ''}
                    >
                        {galaxy.solar_systems.map((option) => (
                            <MenuItem value={option} key={option.id}>
                                {option.planets[0].value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        );
    }

    function getSolarSystemHistory(sysId) {
        if (!sysId && !solarSystemState) return;
        let ID = sysId || solarSystemState.id;

        axiosInstance.get('/api/get-solarsystem-logs/' + ID).then((response) => {
            if (response) {
                solarSystemHistoryRef.current = response.data;
            }
        });
    }

    function addSolarSystemHistory() {
        if (manualHistoryInputRef.current.length === 0) {
            toast.error(capitalize(i18next.t('empty_input')));
            return;
        }
        axiosInstance.post('/api/add-solarsystem-log/' + solarSystemState.id, { text: manualHistoryInputRef.current }).then((response) => {
            if (response.status === 200) {
                toast.success(capitalize(i18next.t('save_successful')));
                getSolarSystemHistory();
            } else {
                toast.error(capitalize(i18next.t('error')));
            }
        });

        manualHistoryInputRef.current = '';
    }

    function getUserName(id) {
        if (!Boolean(universeState?.universeUsers?.length) || !id) {
            return;
        }
        let tmp = universeState?.universeUsers?.find((x) => x.user_id === id);
        if (tmp) {
            return tmp.profile.email;
        }
        return 'Unkown';
    }

    function historyCondition(row) {
        if (!search.value) return true;
        return (
            getUserName(row.user).toLowerCase().includes(search.value.toLowerCase()) ||
            formatDateTime(row.time, true).toString().toLowerCase().includes(search.value.toLowerCase()) ||
            row.changed_field.toLowerCase().includes(search.value.toLowerCase()) ||
            row.old_data.toLowerCase().includes(search.value.toLowerCase()) ||
            row.new_data.toLowerCase().includes(search.value.toLowerCase())
        );
    }

    function historyView(row) {
        let history = solarSystemHistoryRef.current;
        if (history === null) return;
        return (
            <React.Fragment>
                {!search.state && (
                    <Grid container alignItems={'center'}>
                        <Grid item md={4} xs={4}>
                            <CustomTooltip
                                children={
                                    <SearchIcon
                                        onClick={() => {
                                            setSearch({ state: true, value: '' });
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                }
                                title={'Search'}
                            />
                        </Grid>
                        <Grid container item md={4} xs={4} justifyContent={'center'}>
                            <Typography fontWeight={'bold'}>{capitalize(i18next.t('history'))}</Typography>
                        </Grid>
                        <Grid container item md={4} xs={4} justifyContent="flex-end">
                            {!['read'].includes(universeState?.universe.user_permission) && (
                                <CustomTooltip
                                    children={
                                        <AddIcon
                                            onClick={() => {
                                                setTab('addHistory');
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    }
                                    title={capitalize(i18next.t('history_note'))}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
                {search.state && (
                    <Grid container alignItems={'center'}>
                        <Grid item md={2} xs={2}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <CustomTooltip
                                    children={
                                        <ArrowBackIcon
                                            onClick={() => {
                                                setSearch({ state: false, value: '' });
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    }
                                    title={capitalize('back')}
                                />
                            </div>
                        </Grid>
                        <Grid container item md={10} xs={10} justifyContent="center">
                            <TextField
                                variant={'standard'}
                                value={search.value}
                                label={'Search'}
                                style={{ marginTop: 0 }}
                                onChange={(e) => setSearch({ ...search, value: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                )}

                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{capitalize(i18next.t('user'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('date'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('type'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('old_data'))}</TableCell>
                                <TableCell>{capitalize(i18next.t('new_data'))}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map(
                                (row, index) =>
                                    historyCondition(row) && (
                                        <TableRow key={'row_' + index}>
                                            <TableCell>{getUserName(row.user)}</TableCell>
                                            <TableCell>{formatDateTime(row.time, true) ?? '-'}</TableCell>
                                            <TableCell>{row.changed_field}</TableCell>
                                            <TableCell>{row.old_data}</TableCell>
                                            <TableCell>{row.new_data}</TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }

    function addHistoryView() {
        return (
            <Grid container paddingX={'10px'}>
                <Grid container alignItems={'center'} justifyContent="center">
                    <Grid container item md={12} xs={12} justifyContent={'center'} paddingY={'10px'}>
                        <Typography fontWeight={'bold'}>{capitalize(i18next.t('history_note'))}</Typography>
                    </Grid>

                    <Grid container justifyContent={'center'} paddingY={'10px'}>
                        <TextField
                            required
                            onChange={(e) => (manualHistoryInputRef.current = e.target.value)}
                            label={capitalize(i18next.t('new_note'))}
                            defaultValue={''}
                            type={'text'}
                        />
                    </Grid>

                    <Grid container spacing={1} justifyContent={'center'} paddingY={'20px'}>
                        <Grid item md={6} xs={6}>
                            <Button variant="contained" color="info" fullWidth onClick={() => setTab('history')}>
                                {i18next.t('close')}
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                type={'submit'}
                                onClick={() => {
                                    addSolarSystemHistory();
                                    setTab('history');
                                }}
                            >
                                {i18next.t('save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function getDeleteQuestion() {
        let tmp =
            'Are you sure you want to delete ' +
            solarSystemState.planets[0].value +
            ' from ' +
            capitalize(solarSystemState.galaxy_name) +
            '?';
        setDeleteQuestion(tmp);
        if (props.index !== 3) {
            tmp = 'It will remove all the allocations of ';
            tmp += solarSystemState.planets[0].value;
        }

        setDeleteDescription(tmp);
    }

    function onMenuItemClick() {
        setSelectAnchorState(null);
    }

    function deleteAllocation() {
        axiosInstance
            .post('/api/delete-solar-system/' + universeState?.universe.id, {
                solar_system_id: solarSystemState.id,
            })
            .then((response) => {
                if (response.status === 200) {
                    // updating the table
                    let columnIndex = tableState.table[0].findIndex(
                        (x) =>
                            x.id === solarSystemState.connections[0].a.id ||
                            x.id === solarSystemState.connections[0].b.id ||
                            x.id === solarSystemState.connections[0].c.id
                    );
                    let rowIndex = tableState.table.findIndex(
                        (row) =>
                            row[0].id === solarSystemState.connections[0].a.id ||
                            row[0].id === solarSystemState.connections[0].b.id ||
                            row[0].id === solarSystemState.connections[0].c.id
                    );
                    let indexInCell = tableState.table[rowIndex][columnIndex].findIndex((x) => x.allocation_id === solarSystemState.id);
                    if (indexInCell !== -1 && indexInCell !== undefined) {
                        let tableCopy = structuredClone(tableState.table);
                        tableCopy?.[rowIndex]?.[columnIndex]?.splice(indexInCell, 1);
                        tableDispatch({ type: tableActions.SET_TABLE, payload: tableCopy });
                    }
                    //something went wrong on frontend, but there is a slower solution to the problem
                    else {
                        console.log('something went wrong');
                        getTable();
                    }

                    //updating the universe
                    let universeCopy = structuredClone(universeState.universe);
                    const allocationGalaxyIndex = universeCopy.galaxies.findIndex((x) => x.allocation_galaxy);
                    if (allocationGalaxyIndex !== -1) {
                        let allocationIndex = universeCopy?.galaxies[allocationGalaxyIndex]?.solar_systems?.findIndex(
                            (x) => x.id === solarSystemState.id
                        );
                        if (allocationIndex !== -1 && allocationIndex !== undefined) {
                            universeCopy.galaxies[allocationGalaxyIndex].solar_systems.splice(allocationIndex, 1);
                        } else {
                            console.log('something went wrong');
                            getUniverse();
                        }
                    }
                    //something went wrong on frontend, but there is a slower solution to the problem
                    else {
                        console.log('something went wrong');
                        getUniverse();
                    }

                    // update active selection
                    let activeSelectionIndex = tableState.activeSelection.findIndex((x) => x === solarSystemState.id);
                    if (activeSelectionIndex !== -1) {
                        let activeSelectionCopy = [...tableState.activeSelection];
                        activeSelectionCopy.splice(activeSelectionIndex, 1);
                        tableDispatch({ type: tableActions.SET_ACTIVE_SELECTION, payload: activeSelectionCopy });
                    }

                    toast.success(capitalize(i18next.t('allocation_deleted_successfully')));
                    // active selection update
                }
            });
    }
    function checkAddIconShowable() {
        if (props.index !== 3) return true;
        return (
            Boolean(universeState?.universe.galaxies[0].solar_systems.length) &&
            Boolean(universeState?.universe.galaxies[1].solar_systems.length) &&
            Boolean(universeState?.universe.galaxies[2].solar_systems.length)
        );
    }
    if (props.footerCollapsed) return '';

    return (
        <React.Fragment>
            <CustomDeleteDialog
                openState={deleteSolarSystemState}
                setOpenState={setDeleteSolarSystemState}
                question={deleteQuestion}
                deleteFunction={props.index !== 3 ? deleteSolarSystem : deleteAllocation}
                optionalDescription={deleteDescription}
            />
            <form style={{ width: '100%', height: '100%', position: 'relative' }} onSubmit={(e) => handleSave(e)}>
                <input ref={(input) => (formRef = input)} type="submit" hidden />

                <Paper className={styles.cardHeight}>
                    <Grid container className={'p-5'}>
                        <Grid container item xs={12} md={12} className={styles.cardHeaderHeight}>
                            <Grid container item xs={6} md={6} alignItems={'center'}>
                                <React.Fragment>
                                    <Grid container alignItems={'center'}>
                                        <Typography fontWeight={'bold'} noWrap>
                                            {capitalize(galaxyName)}
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            </Grid>
                            <Grid container item xs={6} md={6} justifyContent={'flex-end'} alignItems={'center'}>
                                {beingModified ? (
                                    <React.Fragment>
                                        {!props.footerCollapsed && (
                                            <React.Fragment>
                                                <CustomTooltip
                                                    children={
                                                        <ArrowBackIcon
                                                            onClick={() => {
                                                                setBeingModified(false);
                                                            }}
                                                            style={{ marginRight: '10px', cursor: 'pointer' }}
                                                        />
                                                    }
                                                    title={capitalize(i18next.t('back'))}
                                                />
                                                <CustomTooltip
                                                    children={<SaveIcon onClick={() => formRef.click()} className={'pressable'} />}
                                                    title={capitalize(i18next.t('save'))}
                                                />
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {!props.footerCollapsed && (
                                            <React.Fragment>
                                                {solarSystemState && solarSystemState.planets && (
                                                    <CustomTooltip
                                                        children={
                                                            <MoreVertIcon
                                                                onClick={(event) => {
                                                                    setSelectAnchorState(event.currentTarget);
                                                                }}
                                                                className={'pressable'}
                                                            />
                                                        }
                                                        title={capitalize(i18next.t('options'))}
                                                    />
                                                )}
                                                <Menu
                                                    open={Boolean(selectAnchorState)}
                                                    anchorEl={selectAnchorState}
                                                    onClose={() => setSelectAnchorState(null)}
                                                >
                                                    {tab !== 'planet' && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                onMenuItemClick();
                                                                setTab('planet');
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <AssignmentIcon
                                                                    style={{
                                                                        marginRight: '10px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText>{capitalize(i18next.t('details'))}</ListItemText>
                                                        </MenuItem>
                                                    )}
                                                    {!['read'].includes(universeState?.universe.user_permission) &&
                                                        tab !== 'connection' && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    onMenuItemClick();
                                                                    setBeingModified(true);
                                                                    setTab('planet');
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <EditIcon />
                                                                </ListItemIcon>
                                                                <ListItemText>{capitalize(i18next.t('modify'))}</ListItemText>
                                                            </MenuItem>
                                                        )}
                                                    {/*!['read'].includes(universe.universe.user_permission) &&
                                                        cardtype === 'allocationcard' && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    onMenuItemClick();
                                                                    props.setOverlay(tableState?.activeSelection);
                                                                    props.setNewSolarType('allocation');
                                                                    let copied = { ...solarSystemState };
                                                                    props.setCopiedSystem(copied);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <ContentCopyIcon />
                                                                </ListItemIcon>
                                                                <ListItemText>
                                                                    {capitalize(i18next.t('copy'))}
                                                                </ListItemText>
                                                            </MenuItem>
                                                            )*/}
                                                    <MenuItem
                                                        onClick={() => {
                                                            onMenuItemClick();
                                                            setTab('history');
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <HistoryIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>{capitalize(i18next.t('history'))}</ListItemText>
                                                    </MenuItem>
                                                    {!['read'].includes(universeState?.universe.user_permission) && props.index === 3 && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                onMenuItemClick();
                                                                setTab('connection');
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <CompareArrowsIcon />
                                                            </ListItemIcon>
                                                            <ListItemText>{capitalize(i18next.t('connections'))}</ListItemText>
                                                        </MenuItem>
                                                    )}
                                                    {!['read'].includes(universeState?.universe.user_permission) &&
                                                        checkAddIconShowable() && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    onMenuItemClick();
                                                                    props.addFunction();
                                                                    props.setCopiedSystem(solarSystemState);
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <AddIcon />
                                                                </ListItemIcon>
                                                                <ListItemText>{capitalize(i18next.t('new_element'))}</ListItemText>
                                                            </MenuItem>
                                                        )}
                                                    {!['read'].includes(universeState?.universe.user_permission) && (
                                                        <MenuItem
                                                            onClick={() => {
                                                                onMenuItemClick();
                                                                if (props.index !== 3) {
                                                                    getDeleteQuestion();
                                                                } else {
                                                                    setDeleteQuestion(i18next.t('delete_allocation_question'));
                                                                    setDeleteDescription(undefined);
                                                                }
                                                                setDeleteSolarSystemState(true);
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                            <ListItemText>{capitalize(i18next.t('delete'))}</ListItemText>
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} className={'pt-5 pb-5'}>
                            <Divider />
                        </Grid>

                        <Grid container className={styles.cardTextContainer}>
                            {tabView()}
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </React.Fragment>
    );
}
