import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosApi';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useState(Boolean(localStorage.getItem('access_token')));
    const [user, setUser] = useState(null);

    useEffect(getUser, []);

    useEffect(() => (loggedIn ? getUser() : navigate('/')), [loggedIn]);

    async function getUser() {
        try {
            const response = await axiosInstance.get('/api/get-user');
            setUser(response?.data);
        } catch (error) {
            console.log(error);
        }
    }

    function logOut() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setLoggedIn(false);
        navigate('/');
    }

    return <AuthContext.Provider value={{ logOut, setLoggedIn, setUser, user }}>{children}</AuthContext.Provider>;
}

export default AuthContext;
